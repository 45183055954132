import React from "react";

// Header image
import WorkImg from "../../../../assets/img/banner-img-work-v2.png";

// Case Study images
// PROPERTY PEOPLE
import PropertyPeople from "../../../../assets/img/work-property-people.jpg";
import PropertyPeopleTiny from "../../../../assets/img/work-property-people-tiny.jpg";
import PropertyPeopleHome from "../../../../assets/img/our-work/property-people/property-people-home.jpg";
import PropertyPeopleMobiles from "../../../../assets/img/our-work/property-people/property-people-mobiles.jpg";
import PropertyPeopleHome2 from "../../../../assets/img/our-work/property-people/property-people-home-2.jpg";
import PropertyPeopleHome2Tiny from "../../../../assets/img/our-work/property-people/property-people-home-2.jpg";

// CLEARBIT
import ClearbitHome from "../../../../assets/img/our-work/clearbit/clearbit-home.jpg";
import ClearbitHomeTiny from "../../../../assets/img/our-work/clearbit/clearbit-home-tiny.jpg";
import ClearbitMobiles1 from "../../../../assets/img/our-work/clearbit/clearbit-mobiles-1.jpg";
import ClearbitMobiles1Tiny from "../../../../assets/img/our-work/clearbit/clearbit-mobiles-1-tiny.jpg";
import ClearbitMobiles2 from "../../../../assets/img/our-work/clearbit/clearbit-mobiles-2.jpg";
import ClearbitMobiles2Tiny from "../../../../assets/img/our-work/clearbit/clearbit-mobiles-2-tiny.jpg";

// YELLOW SIGNS
import YellowSignsHome from "../../../../assets/img/our-work/yellow-signs/yellow-signs-home.jpg";
import YellowSignsHomeTiny from "../../../../assets/img/our-work/yellow-signs/yellow-signs-home-tiny.jpg";
import YellowSignsMobiles1 from "../../../../assets/img/our-work/yellow-signs/yellow-signs-mobiles-1.jpg";
import YellowSignsMobiles1Tiny from "../../../../assets/img/our-work/yellow-signs/yellow-signs-mobiles-1-tiny.jpg";
import YellowSignsMobiles2 from "../../../../assets/img/our-work/yellow-signs/yellow-signs-mobiles-2.jpg";
import YellowSignsMobiles2Tiny from "../../../../assets/img/our-work/yellow-signs/yellow-signs-mobiles-2-tiny.jpg";

// CITRON
import CitronHome from "../../../../assets/img/our-work/citron/citron-home.jpg";
import CitronHomeTiny from "../../../../assets/img/our-work/citron/citron-home-tiny.jpg";
import CitronMobiles1 from "../../../../assets/img/our-work/citron/citron-mobiles-1.jpg";
import CitronMobiles1Tiny from "../../../../assets/img/our-work/citron/citron-mobiles-1-tiny.jpg";
import CitronHome2 from "../../../../assets/img/our-work/citron/citron-home-2.jpg";
import CitronHome2Tiny from "../../../../assets/img/our-work/citron/citron-home-2-tiny.jpg";

// LOBSTER
import LobsterHome from "../../../../assets/img/our-work/lobster/lobster-home.jpg";
import LobsterHomeTiny from "../../../../assets/img/our-work/lobster/lobster-home-tiny.jpg";
import LobsterMobiles from "../../../../assets/img/our-work/lobster/lobster-mobiles.jpg";
import LobsterMobilesTiny from "../../../../assets/img/our-work/lobster/lobster-mobiles-tiny.jpg";
import LobsterHome2 from "../../../../assets/img/our-work/lobster/lobster-home-2.jpg";
import LobsterHome2Tiny from "../../../../assets/img/our-work/lobster/lobster-home-2-tiny.jpg";

// PLAYBOOK
import PlaybookHome from "../../../../assets/img/our-work/playbook/playbook-home.jpg";
import PlaybookHomeTiny from "../../../../assets/img/our-work/playbook/playbook-home-tiny.jpg";
import PlaybookMobiles from "../../../../assets/img/our-work/playbook/playbook-mobiles.jpg";
import PlaybookMobilesTiny from "../../../../assets/img/our-work/playbook/playbook-mobiles-tiny.jpg";
import PlaybookHome2 from "../../../../assets/img/our-work/playbook/playbook-home-2.jpg";
import PlaybookHome2Tiny from "../../../../assets/img/our-work/playbook/playbook-home-2-tiny.jpg";

// POCKET CHANGE
import PocketChangeHome from "../../../../assets/img/our-work/pocket-change/pocket-change-home.jpg";
import PocketChangeHomeTiny from "../../../../assets/img/our-work/pocket-change/pocket-change-home-tiny.jpg";
import PocketChangeMobiles from "../../../../assets/img/our-work/pocket-change/pocket-change-mobiles.jpg";
import PocketChangeMobilesTiny from "../../../../assets/img/our-work/pocket-change/pocket-change-mobiles-tiny.jpg";
import PocketChangeHome2 from "../../../../assets/img/our-work/pocket-change/pocket-change-home-2.jpg";
import PocketChangeHome2Tiny from "../../../../assets/img/our-work/pocket-change/pocket-change-home-2-tiny.jpg";

// ANTIC
import Antic from "../../../../assets/img/our-work/antic/work-antic.jpg";
import AnticTiny from "../../../../assets/img/our-work/antic/work-antic-tiny.jpg";
import AnticHome from "../../../../assets/img/our-work/antic/antic-home.jpg";
import AnticHomeTiny from "../../../../assets/img/our-work/antic/antic-home-tiny.jpg";
import AnticMobiles from "../../../../assets/img/our-work/antic/antic-mobiles.jpg";
import AnticMobilesTiny from "../../../../assets/img/our-work/antic/antic-mobiles-tiny.jpg";
import AnticHome2 from "../../../../assets/img/our-work/antic/antic-home-2.jpg";
import AnticHome2Tiny from "../../../../assets/img/our-work/antic/antic-home-2-tiny.jpg";

// SUMIMOTO
import Sumimoto from "../../../../assets/img/our-work/sumimoto/work-sumimoto.jpg";
import SumimotoTiny from "../../../../assets/img/our-work/sumimoto/work-sumimoto-tiny.jpg";
import SumimotoHome from "../../../../assets/img/our-work/sumimoto/sumimoto-home.jpg";
import SumimotoHomeTiny from "../../../../assets/img/our-work/sumimoto/sumimoto-home-tiny.jpg";
import SumimotoMobiles from "../../../../assets/img/our-work/sumimoto/sumimoto-mobiles.jpg";
import SumimotoMobilesTiny from "../../../../assets/img/our-work/sumimoto/sumimoto-mobiles-tiny.jpg";
import SumimotoHome2 from "../../../../assets/img/our-work/sumimoto/sumimoto-home-2.jpg";
import SumimotoHome2Tiny from "../../../../assets/img/our-work/sumimoto/sumimoto-home-2-tiny.jpg";

// SUMIMOTO
import Bruvi from "../../../../assets/img/our-work/bruvi/work-bruvi.jpg";
import BruviTiny from "../../../../assets/img/our-work/bruvi/work-bruvi-tiny.jpg";
import BruviHome from "../../../../assets/img/our-work/bruvi/bruvi-home.jpg";
import BruviHomeTiny from "../../../../assets/img/our-work/bruvi/bruvi-home-tiny.jpg";
import BruviMobiles from "../../../../assets/img/our-work/bruvi/bruvi-mobiles.jpg";
import BruviMobilesTiny from "../../../../assets/img/our-work/bruvi/bruvi-mobiles-tiny.jpg";
import BruviHome2 from "../../../../assets/img/our-work/bruvi/bruvi-home-2.jpg";
import BruviHome2Tiny from "../../../../assets/img/our-work/bruvi/bruvi-home-2-tiny.jpg";

// THE GFB
import TheGFB from "../../../../assets/img/our-work/thegfb/work-thegfb.jpg";
import TheGFBTiny from "../../../../assets/img/our-work/thegfb/work-thegfb-tiny.jpg";
import TheGFBHome from "../../../../assets/img/our-work/thegfb/thegfb-home.jpg";
import TheGFBHomeTiny from "../../../../assets/img/our-work/thegfb/thegfb-home-tiny.jpg";
import TheGFBMobiles from "../../../../assets/img/our-work/thegfb/thegfb-mobiles.jpg";
import TheGFBMobilesTiny from "../../../../assets/img/our-work/thegfb/thegfb-mobiles-tiny.jpg";
import TheGFBHome2 from "../../../../assets/img/our-work/thegfb/thegfb-home-2.jpg";
import TheGFBHome2Tiny from "../../../../assets/img/our-work/thegfb/thegfb-home-2-tiny.jpg";

// MORPHEE
import Morphee from "../../../../assets/img/our-work/morphee/work-morphee.jpg";
import MorpheeTiny from "../../../../assets/img/our-work/morphee/work-morphee-tiny.jpg";
import MorpheeHome from "../../../../assets/img/our-work/morphee/morphee-home.jpg";
import MorpheeHomeTiny from "../../../../assets/img/our-work/morphee/morphee-home-tiny.jpg";
import MorpheeMobiles from "../../../../assets/img/our-work/morphee/morphee-mobiles.jpg";
import MorpheeMobilesTiny from "../../../../assets/img/our-work/morphee/morphee-mobiles-tiny.jpg";
import MorpheeHome2 from "../../../../assets/img/our-work/morphee/morphee-home-2.jpg";
import MorpheeHome2Tiny from "../../../../assets/img/our-work/morphee/morphee-home-2-tiny.jpg";

// FLAVOURLY
import Flavourly from "../../../../assets/img/our-work/flavourly/work-flavourly.jpg";
import FlavourlyTiny from "../../../../assets/img/our-work/flavourly/work-flavourly-tiny.jpg";
import FlavourlyHome from "../../../../assets/img/our-work/flavourly/flavourly-home.jpg";
import FlavourlyHomeTiny from "../../../../assets/img/our-work/flavourly/flavourly-home-tiny.jpg";
import FlavourlyMobiles from "../../../../assets/img/our-work/flavourly/flavourly-mobiles.jpg";
import FlavourlyMobilesTiny from "../../../../assets/img/our-work/flavourly/flavourly-mobiles-tiny.jpg";
import FlavourlyHome2 from "../../../../assets/img/our-work/flavourly/flavourly-home-2.jpg";
import FlavourlyHome2Tiny from "../../../../assets/img/our-work/flavourly/flavourly-home-2-tiny.jpg";

// PROPERTY PEOPLE

// Get images for Our Work section
import PocketChange from "../../../../assets/img/work-pocket-change.jpg";
import YellowSigns from "../../../../assets/img/work-yellow-signs.jpg";
import Mifold from "../../../../assets/img/work-mifold.jpg";
import Whitestep from "../../../../assets/img/work-whitestep.jpg";
import Clearbit from "../../../../assets/img/work-clearbit.jpg";
import BeCurious from "../../../../assets/img/work-be-curious.jpg";
import Andersen from "../../../../assets/img/work-andersen.jpg";
import StJames from "../../../../assets/img/work-stjames.jpg";
import BoomerangBooth from "../../../../assets/img/work-boomerang.jpg";
import Playbook from "../../../../assets/img/work-playbook.jpg";
import SimplyChocolate from "../../../../assets/img/work-chocolate.jpg";
import Lobster from "../../../../assets/img/work-lobster.jpg";
import Harrys from "../../../../assets/img/work-harrys.jpg";
import Clearspring from "../../../../assets/img/work-clearspring.jpg";
import Citron from "../../../../assets/img/our-work/citron/citron-thumb.jpg";

// Add a tiny image for each to enable progressive loading
import PocketChangeTiny from "../../../../assets/img/work-pocket-change-tiny.jpg";
import YellowSignsTiny from "../../../../assets/img/work-yellow-signs-tiny.jpg";
import MifoldTiny from "../../../../assets/img/work-mifold-tiny.jpg";
import WhitestepTiny from "../../../../assets/img/work-whitestep-tiny.jpg";
import ClearbitTiny from "../../../../assets/img/work-clearbit-tiny.jpg";
import BeCuriousTiny from "../../../../assets/img/work-be-curious-tiny.jpg";
import AndersenTiny from "../../../../assets/img/work-andersen-tiny.jpg";
import StJamesTiny from "../../../../assets/img/work-stjames-tiny.jpg";
import BoomerangBoothTiny from "../../../../assets/img/work-boomerang-tiny.jpg";
import PlaybookTiny from "../../../../assets/img/work-playbook-tiny.jpg";
import SimplyChocolateTiny from "../../../../assets/img/work-chocolate-tiny.jpg";
import LobsterTiny from "../../../../assets/img/work-lobster-tiny.jpg";
import HarrysTiny from "../../../../assets/img/work-harrys-tiny.jpg";
import ClearspringTiny from "../../../../assets/img/work-clearspring-tiny.jpg";

// Get images for Brands section
import { ReactComponent as Disney } from "../../../../assets/svg/brand-disney.svg";
import { ReactComponent as Starbucks } from "../../../../assets/svg/brand-starbucks.svg";
import { ReactComponent as Toshiba } from "../../../../assets/svg/brand-toshiba.svg";
import { ReactComponent as Johnson } from "../../../../assets/svg/brand-johnson.svg";
import { ReactComponent as Rakuten } from "../../../../assets/svg/brand-rakuten.svg";

export const our_work_en = {
	seo: {
		title: "Our Work | New Window Development",
		description:
			"Explore our work and get an instant price estimate for your new web development project today.",
	},
	header_img: WorkImg,
	title: "Our Work",
	subtitle:
		"Our team is always ready to challenge themselves and work with you to create something new. Below are a few examples of the work we have done and cool brands we have engaged with.",
	// subtitle:
	// 	"If you hire me you will get the best of both worlds - UX/UI experience and front-end development. My skills have been developed for more than 10 years and I can guarantee you successful results. I can work as part of a team, lead a team or work on my own to achieve your business goals. So, shall we begin today? Let's go!",
	gallery: [
		{
			title: "clearbit.com",
			text: "UX/UI Design, Web Development",
			link: "https://clearbit.com",
			img: Clearbit,
			imgTiny: ClearbitTiny,
			caseStudy: {
				title: "Marketing Automation Frontend App",
				subtitle: "UX/UI Design, Front-end Development",
				textBlock1: {
					title: "Project Overview",
					text: "Clearbit is one of the market leaders in B2B marketing automation. The company tasked our developers with creating an effective front-end site that would please visitors and drive conversions.",
				},
				textBlock2: {
					title: "Our Role",
					text: "UX/UI and front-end development with a focus on increasing soft and hard conversion goals. Our team was working in close contact with Clearbit's stakeholders to ensure everyone was happy with the result.",
				},
				textBlock3: {
					title: "The Problem",
					text: "Although Clearbit is one of the leaders in their niche, they still need to explain to the potential website visitor the benefits of their B2B marketing platform clearly. First time visitors who are only at a discovery stage weren't always completely sure about how the company can help them achieve their goals and what exactly Clearbit does.",
				},
				textBlock4: {
					title: "The Solution",
					text: "We implemented a full UX/UI design process from wireframe creation and user testing to fully designed prototypes, ensuring that the website would explain well about Clearbit’s offered services. On top of it we also implemented processes of continuous testing and improvement in order to not stand still and adjust to the latest market trends and topics on a regular basis.",
				},
				textBlock5: {
					title: "The Result",
					text: "The website constantly achieves high conversion rates that work in tandem with their successful content marketing strategy of educating their potential leads about offered services. Also, the implemented soft and hard conversion funnel is delivering expected results and the design is being adjusted regularly in order to never stay still and keep improving based on the usage data.",
				},
				heroImage1: ClearbitHome,
				heroImage1Tiny: ClearbitHomeTiny,
				heroImage2: ClearbitMobiles1,
				heroImage2Tiny: ClearbitMobiles1Tiny,
				heroImage3: ClearbitMobiles2,
				heroImage3Tiny: ClearbitMobiles2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
		{
			title: "yellowsigns.co.uk",
			text: "Web Development",
			link: "https://yellowsigns.co.uk",
			img: YellowSigns,
			imgTiny: YellowSignsTiny,
			caseStudy: {
				title: "Respectable Signage Provide Website",
				subtitle: "UX/UI Design, Fullstack Development",
				textBlock1: {
					title: "Project Overview",
					text: "A respectable sign maker based in London asked us to create a new website focused on speed and snappy user experience. Their main goal was to appear modern and forward looking but cut out anything that would make the site appear slow.",
				},
				textBlock2: {
					title: "Our Role",
					text: "We were tasked with everything from frontend design and onsite SEO to coding and deployment on AWS servers. Our team was in close contact with the owners of Yellow Signs to ensure their vision is implemented exactly as required.",
				},
				textBlock3: {
					title: "The Problem",
					text: "The loading of any site requires connecting to a server and getting the data whenever it is requested. The data loading will always take some time, even if it’s milliseconds, it’s still noticeable during the page load. We had to come up with a solution that would prioritise speed, but still keeping in mind an attractive design that would suit the website’s purpose of presenting Yellow Signs' beautiful pictures of past work.",
				},
				textBlock4: {
					title: "The Solution",
					text: "Since the website is mainly static and regular updates happen only at the blog area, we decided to skip the database connection entirely and go for a serverless React JS approach without a database. The only exception is the blog section that is regularly updated by the client using a headless Wordpress backend. The headless Wordpress backend provides the client with a familiar tool to write their case studies making a win-win solution for Yellow Signs - fast site in React JS and the familiarity with Wordpress editing tools.",
				},
				textBlock5: {
					title: "The Result",
					text: "Pages load instantly without any lag, even if the internet connection speed is not the fastest. Only thing that does take time to load are images due to their natural file weight. However, by using some clever techniques and deploying this website on AWS server as a serverless React app, the loading speeds were reduced to minimum. Client was very happy with the results and has requested development of other sites using the same tech stack.",
				},
				heroImage1: YellowSignsHome,
				heroImage1Tiny: YellowSignsHomeTiny,
				heroImage2: YellowSignsMobiles1,
				heroImage2Tiny: YellowSignsMobiles1Tiny,
				heroImage3: YellowSignsMobiles2,
				heroImage3Tiny: YellowSignsMobiles2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
		// {
		// 	title: "stjamesschauffeurs.com",
		// 	text: "UX/UI Design, Web development",
		// 	link: "https://stjamesschauffeurs.com/",
		// 	img: StJames,
		// 	imgTiny: StJamesTiny,
		// },
		// {
		// 	title: "becurious.uk.com",
		// 	text: "UX/UI Design",
		// 	link: "https://www.becurious.uk.com/",
		// 	img: BeCurious,
		// 	imgTiny: BeCuriousTiny,
		// },
		{
			title: "bruvi.com",
			text: "UX/UI Design, Ecommerce Development",
			link: "https://www.bruvi.com/",
			img: Bruvi,
			imgTiny: BruviTiny,
			caseStudy: {
				title: "A Very Special Coffee Brewer",
				subtitle: "UX/UI Design, Ecommerce Development",
				textBlock1: {
					title: "Project Overview",
					text: "The sustainable coffee brewer Bruvi was looking for an e-commerce site that would not only sell but impress visitors. We worked closely with their team to create one of the best e-commerce buying experiences we’ve created to date.",
				},
				textBlock2: {
					title: "Our Role",
					text: "We were tasked with UX/UI design, extensive user testing and Shopify web development. Our team worked closely with Bruvi copywriters to deliver the fully finished website.",
				},
				textBlock3: {
					title: "The Problem",
					text: "Bruvi didn’t want only to sell, but they wanted to create an experience like no other. The website needed to feel smooth and warm like an aromatic morning coffee. Nevertheless, the company goal of selling as many coffee brewers and pods and achieving high conversion rates was one of the top requirements. Also, Bruvi wanted to be unique - therefore, existing Shopify themes were out of question, we had to create our own from scratch.",
				},
				textBlock4: {
					title: "The Solution",
					text: "We started with UX/UI design creation by testing various wireframes and prototypes with real users of the Bruvi coffee. The user feedback and comments lead us to a beautiful and smooth design experience that we translated to a working Shopify website. Bruvi also had a lot of design assets to work with allowing us to really create an outstanding atmosphere for their products, including a 360 degree 3D model of their brewer.",
				},
				textBlock5: {
					title: "The Result",
					text: "An e-commerce website like no other. Smooth section transitions and beautiful scroll effects, but not too much, giving the right amount of balance between function, speed and warmth. The goal of sales and conversions was also achieved - Bruvi enjoys well above average conversion rates and have been nominated to various e-commerce awards for their perfect blend of style and functionality.",
				},
				heroImage1: BruviHome,
				heroImage1Tiny: BruviHomeTiny,
				heroImage2: BruviMobiles,
				heroImage2Tiny: BruviMobilesTiny,
				heroImage3: BruviHome2,
				heroImage3Tiny: BruviHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},

		// {
		// 	title: "mifold.co.uk",
		// 	text: "UX/UI, Frontend development, e-commerce",
		// 	link: "https://mifold.co.uk/",
		// 	img: Mifold,
		// imgTiny: MifoldTiny,
		// },
		{
			title: "citron.ae",
			text: "Shopify Development, UX/UI Design",
			link: "https://citron.ae/",
			img: Citron,
			imgTiny: Citron,
			caseStudy: {
				title: "Shopify Development for an International Brand",
				subtitle: "Shopify Development, App Development, UX/UI Design",
				textBlock1: {
					title: "Project Overview",
					text: "A market-leading lifestyle brand was in need of creating a robust and scalable ecommerce website solution. Our team helped them design and develop a Shopify store that now enjoys a more than 60% increase in sales conversions internationally.",
				},
				textBlock2: {
					title: "Our Role",
					text: "We were tasked with revamping their existing store and improving the overall UX experience from the landing page to the final checkout. In addition, Citron’s international presence in 35 countries and counting meant that our developed ecommerce solution needed be built for scale and automation.",
				},
				textBlock3: {
					title: "The Problem",
					text: "Citron’s existing store was clunky and had too many opportunities for user drop-offs leading to incomplete sales. Cart abandonment was high and their personalisation feature didn’t provide consistent experience leading to complaints from clients. Also, since their existing store had a high volume of traffic they couldn’t afford any downtime between switching from the existing store to a newly redesigned one.",
				},
				textBlock4: {
					title: "The Solution",
					text: "We completely redesigned the whole UX experience of their store to provide a clean and straightforward purchasing funnel. Their products contained rich media delighting visitors with beautiful presentations of products, while keeping in mind an easy checkout flow once the user had made up their mind. In order to ensure the store was fast and the theme scalable, 14 out of 16 additional features were developed in-house, meaning that duplicating the theme to a different regions would be easy, the feature would work faster than Shopify’s apps and, of course, no additional monthly fees.",
				},
				textBlock5: {
					title: "The Result",
					text: "Citron was delighted with the result. By using Shopify as the ecommerce provider of choice, switching to the new design took only seconds, ensuring there was no downtime whatsoever. Conversions increased and the theme was easy to duplicate for use by other distributors in their respective countries should they choose to operate their own Citron store.",
				},
				heroImage1: CitronHome,
				heroImage1Tiny: CitronHomeTiny,
				heroImage2: CitronMobiles1,
				heroImage2Tiny: CitronMobiles1Tiny,
				heroImage3: CitronHome2,
				heroImage3Tiny: CitronHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
		{
			title: "playbook.com",
			text: "UX/UI, Web Development",
			link: "https://www.playbook.com/",
			img: Playbook,
			imgTiny: PlaybookTiny,
			caseStudy: {
				title: "Cloud Storage That Saves Money",
				subtitle: "UX/UI Design, User Testing, Fullstack Development",
				textBlock1: {
					title: "Project Overview",
					text: "Playbook is a cloud storage provider for creatives all around the world. The company needed a presentational site that would explain well about all their services and drive more sales. We not only delivered but also highlighted various areas for improvement in their service based on user testing.",
				},
				textBlock2: {
					title: "Our Role",
					text: "Our role was to develop a user experience that naturally encourages creatives to create a free account and test out Playbook’s cloud storage service. Once the UX is created we were tasked with the development of the site in collaboration with their content writers, SEOs and creatives.",
				},
				textBlock3: {
					title: "The Problem",
					text: "There are many cloud storage providers out there, so why Playbook? We had to find a way to inform users of the advantages of Playbook's cloud storage platform. The obvious competition, for example Adobe Creative Cloud, Dropbox and Google Drive offerend plenty of great features already. Due to the large team of decision makers which consisted of a sizable Playbook’s own creative team and ourselves, an efficient way to find the most fitting UX and design was necessary to keep everyone jolly.",
				},
				textBlock4: {
					title: "The Solution",
					text: "We decided that the main features of the UX design should be decided by users themeselves. Plenty of excellent ideas for various design elements and user experiences were proposed and each one of them was tested against a pool of users who then became a barometer of what decides what just looks good and what actually drives user action which was the main goal of the site. Once the UX and design prototypes were fully created and tested by users, our team proceeded onto developing the site itself.",
				},
				textBlock5: {
					title: "The Result",
					text: "Due to extensive user testing, we were very confident that the new site will perform and boy it did! Actual users returned even better soft and hard conversions. Playbook were satisfied with the results and we now work with them to constantly test and improve the site to drive the maximum amount of conversions, while keeping the jolly atmosphere of the site.",
				},
				heroImage1: PlaybookHome,
				heroImage1Tiny: PlaybookHomeTiny,
				heroImage2: PlaybookMobiles,
				heroImage2Tiny: PlaybookMobilesTiny,
				heroImage3: PlaybookHome2,
				heroImage3Tiny: PlaybookHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
		{
			title: "pocket-change.jp",
			text: "UX/UI Design, Web Development",
			link: "https://pay.pocket-change.jp/",
			img: PocketChange,
			imgTiny: PocketChangeTiny,
			caseStudy: {
				title: "Website in Japanese? No problem!",
				subtitle: "UX/UI Design, Web Development",
				textBlock1: {
					title: "Project Overview",
					text: "The Japanese tech startup was in need to develop a front-end site quickly. The new site would not only need appeal to their own market but also internationally, where they are aiming to expand. The result was a sleek presentation of their services to various target markets and an excellent conversion rate.",
				},
				textBlock2: {
					title: "Our Role",
					text: "We were working closely together with Pocket Change’s Japanese team to ensure their brand’s message was delivered through an easy to understand UX/UI design. We were then tasked to develop the created design and deploy it to their local servers.",
				},
				textBlock3: {
					title: "The Problem",
					text: "Our agency has international clients all over the world, however, this was the first time we were directly working together with a Japanese client. The 9 hours time difference surely was going to be a challenge. Also, one of the sites they required was in Japanese, meaning our in-house team was not able to fully ensure that the text content would be well written for the end-user.",
				},
				textBlock4: {
					title: "The Solution",
					text: "To ensure smooth communication and responsiveness from our side, we allocated a dedicated designer (and later a web developer too) who, for the duration of the project, started work early at 4AM UK time and finished early. We picked personnel who actually enjoyed it and wanted to continue it afterwards too :) To overcome the Japanese language deficiency within our team, we hired a professional Japanese copywriter fully proficient in English too.",
				},
				textBlock5: {
					title: "The Result",
					text: "A very satisfied customer who has asked us to work on additional projects. We now have a dedicated team of two people who are managing Pocket Change’s account and continue delivering exceptional service to the Japanese startup. ありがとうございます！",
				},
				heroImage1: PocketChangeHome,
				heroImage1Tiny: PocketChangeHomeTiny,
				heroImage2: PocketChangeMobiles,
				heroImage2Tiny: PocketChangeMobilesTiny,
				heroImage3: PocketChangeHome2,
				heroImage3Tiny: PocketChangeHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},

		// {
		// 	title: "whitestep.co.uk",
		// 	text: "Frontend design & development, Shopify e-commerce",
		// 	link: "https://mifold.co.uk/",
		// 	img: Whitestep,
		// imgTiny: WhitestepTiny,
		// },
		// {
		// 	title: "boomerangbooth.co.uk",
		// 	text: "Digital Marketing, UX/UI, Frontend & backend development",
		// 	link: "https://www.boomerangbooth.co.uk/",
		// 	img: BoomerangBooth,
		// 	imgTiny: BoomerangBoothTiny,
		// },
		// {
		// 	title: "sumishowcase.com",
		// 	text: "UX/UI Design, Web Development",
		// 	link: "https://www.sumishowcase.com/",
		// 	img: Sumimoto,
		// 	imgTiny: SumimotoTiny,
		// 	caseStudy: {
		// 		title: "Online Brochures With a Twist",
		// 		subtitle: "UX/UI Design, Web Development",
		// 		textBlock1: {
		// 			title: "Project Overview",
		// 			text: "Sumitomo, a global power transmission solutions provider, was in need of digital brochures for a range of their products. With Sumitomo’s CAD drawings at our disposal, we were able to create brochures with stunning 3D effects and explainers that didn’t miss a beat.",
		// 		},
		// 		textBlock2: {
		// 			title: "Our Role",
		// 			text: "We were tasked with taking all Sumitomo’s technical drawings, instructions and brochures for print and turning them into a digital experience. Not only were we responsible for design and web development but also creating 3D animations to create stunning effects.",
		// 		},
		// 		textBlock3: {
		// 			title: "The Problem",
		// 			text: "There was a lot of information that had to be presented. For each product we were provided with 3D CAD drawings up to the last element of each product and a vast amount of technical details. Each digital brochure needed to look good as well as serve the purpose of informing the user about all the technicalities that would be easy to digest.",
		// 		},
		// 		textBlock4: {
		// 			title: "The Solution",
		// 			text: "Our design team worked closely with Sumimoto’s team to understand the details of each product. Through limited user testing we found the best layouts for their target market that would keep the interest of the product from the beginning until the end. Going further, we utilised the provided product 3D models and created a cool intro animation for each product.",
		// 		},
		// 		textBlock5: {
		// 			title: "The Result",
		// 			text: "A website that drives interest and informs about each product not only in an interactive but also straightforward way, which is important for the technically minded target market. Sumimoto was pleased with the brochures and impressed with the results.",
		// 		},
		// 		heroImage1: SumimotoHome,
		// 		heroImage1Tiny: SumimotoHomeTiny,
		// 		heroImage2: SumimotoMobiles,
		// 		heroImage2Tiny: SumimotoMobilesTiny,
		// 		heroImage3: SumimotoHome2,
		// 		heroImage3Tiny: SumimotoHome2Tiny,
		// 		images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
		// 	},
		// },

		{
			title: "morphee.co.uk",
			text: "Shopify Development, UX/UI Design",
			link: "https://www.morphee.co.uk/",
			img: Morphee,
			imgTiny: MorpheeTiny,
			caseStudy: {
				title: "Smooth and delighting experience",
				subtitle: "UX/UI, Ecommerce",
				textBlock1: {
					title: "Project Overview",
					text: "The champion in providing a better night’s sleep needed our help in making their UX/UI prototypes a reality. Our team developed an e-commerce store based on Morphée’s designs and created an experience that keeps on delighting their existing and potential customers.",
				},
				textBlock2: {
					title: "Our Role",
					text: "Develop an ecommerce site on Shopify Plus platform following the designs and feature requests by Morphée UK. Our team worked closely with Morphée UK's marketing department to ensure every feature is working perfectly well no matter of the device used.",
				},
				textBlock3: {
					title: "The Problem",
					text: "Morphée’s main product lineup consists of devices that play audio helping people sleep better. There are no audio controls in Shopify themes by default and only a handful of apps offer something that remotely satisfies the features that Morphée UK was after. Everything also needed to work well together and a smooth experience was required across all device and browser types.",
				},
				textBlock4: {
					title: "The Solution",
					text: "On top of the things we do best - bringing beautiful UX/UI’s to life - our developer team also ensured that the audio was handled properly and no external Shopify apps or strange looking audio players were used to satisfy all Morphée’s requirements. We tested everything across a wide range of devices to ensure all features worked well not only on latest laptops and mobiles but also a wide range of mobile and desktop browsers.",
				},
				textBlock5: {
					title: "The Result",
					text: "A wonderful e-commerce user experience which blends smooth design and audio experience while ensuring that the site leads users to a happy purchase. Morphée UK now enjoys above average conversion rates and the content and features are regularly updated to never stand still in the ever changing market.",
				},
				heroImage1: MorpheeHome,
				heroImage1Tiny: MorpheeHomeTiny,
				heroImage2: MorpheeMobiles,
				heroImage2Tiny: MorpheeMobilesTiny,
				heroImage3: MorpheeHome2,
				heroImage3Tiny: MorpheeHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
		{
			title: "property-people.co.uk",
			text: "UX/UI Design, Web development",
			link: "https://property-people.co.uk/",
			img: PropertyPeople,
			imgTiny: PropertyPeopleTiny,
			caseStudy: {
				title: "Real Estate Web App",
				subtitle: "UX/UI Design, Fullstack Development",
				textBlock1: {
					title: "Project Overview",
					text: "A real estate agent, based in London, tasked us to modernise their property search website. Their goal was to make the new site attractive, easy to use and most importantly increase conversion rates from the previous site through UX/UI design and site speed.",
				},
				textBlock2: {
					title: "Our Role",
					text: "For this project we assumed resposibility for all the work involved. From UX/UI design and frontend coding in ReactJS to backend coding in NodeJS and setting up MongoDB Atlas for this project. We had to cooperate with Property People's copywriters to ensure the messages were on brand and targeted for their target market.",
				},
				textBlock3: {
					title: "The Problem",
					text: "Property People desired a very similar visual experience to their previous site, but they wanted it faster, better and more effective when it comes to conversion rates. When it came to technicalities, their existing website was made with Laravel framework using PHP but he wanted to move away completely and start from scratch. Also, their website's database already had around 1500 active properties with myriad of options, making it important to devise how to transfer all the existing data to the new website efficiently.",
				},
				textBlock4: {
					title: "The Solution",
					text: "A new prototype was tested amongst 50 users with an overwhelming positive reactions. We created the website using MERN stack (MongoDB, ExpressJS, ReactJS, NodeJS) meaning the site was very fast and efficient. It was deployed on AWS EC2 server with the database living in the cloud (MongoDB Atlas). Once the frontend and backend was created, we exported previous MySQL database data, transformed it with a script and imported into MongoDB for use in the new site.",
				},
				textBlock5: {
					title: "The Result",
					text: "The main goal was achieved - website now delivered more lead conversions than the previous version of the site. On top of it, the admin backend of the new site now was much more easier to use, had many more options to choose from for the properties and was generally a pleasure to use. Needless to say that the client was extremely satisfied with the results.",
				},
				heroImage1: PropertyPeopleHome,
				heroImage1Tiny: PropertyPeopleTiny,
				heroImage2: PropertyPeopleMobiles,
				heroImage2Tiny: PropertyPeopleTiny,
				heroImage3: PropertyPeopleHome2,
				heroImage3Tiny: PropertyPeopleHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
		// {
		// 	title: "andersen-ev.com",
		// 	text: "Digital Marketing, UX/UI",
		// 	link: "https://andersen-ev.com/",
		// 	img: Andersen,
		// 	imgTiny: AndersenTiny,
		// },
		{
			title: "flavourly.com",
			text: "Custom Ecommerce Development",
			link: "https://www.flavourly.com/",
			img: Flavourly,
			imgTiny: FlavourlyTiny,
			caseStudy: {
				title: "Excellent custom experience",
				subtitle: "Custom Ecommerce Development",
				textBlock1: {
					title: "Project Overview",
					text: "Flavourly, a Scottish craft beer distributor, was in a hunt to create an ecommerce site that would offer beer cases for purchase as well as subscription based beer delivery service. We developed a custom ecommerce site to fulfil all Flavourly’s requirements and deliver a super fast browsing experience.",
				},
				textBlock2: {
					title: "Our Role",
					text: "We were tasked with developing an ecommerce site aimed at speed and conversions. Our ecommerce and MERN stack specialists got involved to deliver a snappy and completely custom ecommerce experience.",
				},
				textBlock3: {
					title: "The Problem",
					text: "Initially, Flavourly wanted to use a Shopify backend for their site. However, Shopify didn’t have all the features they required at the time, such as a seamless experience for ordering monthly beer case subscriptions on top of the reugular single product purchases. Custom checkout experience was only available to Shopify Plus subscribers starting from $2000/mo, which Flavourly couldn’t justify.",
				},
				textBlock4: {
					title: "The Solution",
					text: "We decided to go for a custom solution that would satisfy all Flavourly’s needs. We developed a frontend and backend to match exactly what Flavourly required. The initial cost was higher than if we would have used Shopify, where the admin and payments are already created, but Flavourly recovered the initial development costs in less than a year and got themselves an outstanding and unique ecommerce storefront.",
				},
				textBlock5: {
					title: "The Result",
					text: "A website that has no boundaries of what is achievable and what’s not. A custom frontend and custom backend means that Flavourly can have any feature they can imagine. And, since the frontend site is built using ReactJS framework, it is much faster than Shopify and delivers exactly the experience Flavourly desires.",
				},
				heroImage1: FlavourlyHome,
				heroImage1Tiny: FlavourlyHomeTiny,
				heroImage2: FlavourlyMobiles,
				heroImage2Tiny: FlavourlyMobilesTiny,
				heroImage3: FlavourlyHome2,
				heroImage3Tiny: FlavourlyHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
		{
			title: "lobster.es",
			text: "UX/UI Design, Ecommerce Web Development",
			link: "https://www.lobster.es/",
			img: Lobster,
			imgTiny: LobsterTiny,
			caseStudy: {
				title: "SIM Subscription Site with Integrated Ecommerce",
				subtitle: "UX/UI Design, Web Development",
				textBlock1: {
					title: "Project Overview",
					text: "The Spanish SIM card provider needed a presentational website that would be focused on outstanding technical SEO implementation and emphasising their unique selling point to English speaking visitors or expats in Spain. We delivered a site that, in tandem with their SEO team, achieved top rank in Google for target keywords and increased conversions 3-fold when compared to the previous site version.",
				},
				textBlock2: {
					title: "Our Role",
					text: "Design and code a website that would achieve three main goals - be SEO friendly, UX friendly and increase the sales of their SIM card plans. We provided UX/UI design, content writing and ecommerce web development services.",
				},
				textBlock3: {
					title: "The Problem",
					text: "The existing Lobster’s site didn’t seem to entice visitors to select and purchase new SIM plans. Also, although the off-site SEO was pretty good, the website itself was too outdated and didn’t convert very well with many lost sales as a result. On top of it, mobile responsiveness seemed like an afterthought rather than mobile first approach, which this site should certainly be.",
				},
				textBlock4: {
					title: "The Solution",
					text: "We started the website design completely from scratch by creating desktop and mobile wireframes and testing them until an ideal solution was found. Then we created a prototype design and involved extensive user testing that revealed many opportunities to improve conversion rates and UX. Next, our content writers came up with a friendly language that fit Lobster’s target audience and, lastly, all was SEO checked and coded by our developer team.",
				},
				textBlock5: {
					title: "The Result",
					text: "A site that not only converts but also speaks the language of the target audience and ranks at the top of Google rankings for Lobster's desired keywords. In fact, compared to the previous version of the site, the new version returned a 3-fold increase in conversion rates and combined with an excellent SEO strategy justified the site development fees in a very short time period.",
				},
				heroImage1: LobsterHome,
				heroImage1Tiny: LobsterHomeTiny,
				heroImage2: LobsterMobiles,
				heroImage2Tiny: LobsterMobilesTiny,
				heroImage3: LobsterHome2,
				heroImage3Tiny: LobsterHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
		{
			title: "antic.xyz",
			text: "UX/UI Design",
			link: "https://www.antic.xyz/",
			img: Antic,
			imgTiny: AnticTiny,
			caseStudy: {
				title: "The perfect collab",
				subtitle: "UX/UI Design",
				textBlock1: {
					title: "Project Overview",
					text: "Antic wanted to create a UX experience that would make their brand stand out, look sleek but at the same time be informative and drive conversions. Our design team worked closely with Antic's developers to deliver an outstanding user experience.",
				},
				textBlock2: {
					title: "Our Role",
					text: "We were tasked with creating a UX experience for their website that would match Antic’s brand guidelines. We would then need to work closely with their in-house developers to realise the idea and make it look and perform perfectly no matter what device it was viewed from.",
				},
				textBlock3: {
					title: "The Problem",
					text: "Antic had an in-house development team they wanted to use for programming and use our UX/UI design service only. We are used to creating experiences that we know we can achieve ourselves. Working with a new team that we haven’t had experience working with was a good challenge for our designers. Given the deadlines, we would know exactly how long does it take to achieve a certain feature, however, we were not aware how long would it take for an external team and how far we can push the design boundaries when working with Antic's developers.",
				},
				textBlock4: {
					title: "The Solution",
					text: "We weren’t interested in just dropping a completed prototype to Antic’s web development team and say - just do it. We were in constant touch with their team to understand their skills and timeframes for completing various features. We aimed to create an experience that everyone would be happy with from branding, design and development perspective alike. Eventually we saw the project from the very beginning right until the development was done and published.",
				},
				textBlock5: {
					title: "The Result",
					text: "As a result Antic's website was liked by everyone internally and very well received by the public. On top of it, our design team gained an invaluable experience in working with external development teams and created some new guidelines for future projects of similar nature.",
				},
				heroImage1: AnticHome,
				heroImage1Tiny: AnticHomeTiny,
				heroImage2: AnticMobiles,
				heroImage2Tiny: AnticMobilesTiny,
				heroImage3: AnticHome2,
				heroImage3Tiny: AnticHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
		{
			title: "theglutenfreebar.com",
			text: "Shopify Development, CRO",
			link: "https://theglutenfreebar.com/",
			img: TheGFB,
			imgTiny: TheGFBTiny,
			caseStudy: {
				title: "Straightforward and excellent experience",
				subtitle: "Shopify Development, CRO",
				textBlock1: {
					title: "Project Overview",
					text: "The gluten-free brand GFB sells their products through some of the largest American retailers. Nevertheless, they also wanted to create their own ecommerce site where they would be able to sell directly to the public. We created a custom Shopify theme for GFB. The theme now enjoys high conversion rates and a superb speed score.",
				},
				textBlock2: {
					title: "Our Role",
					text: "We were tasked with the design and development of an ecommerce store on a Shopify platform. Our team had to ensure that our developed theme is super fast as well as optimise it for SEO wherever possible for the best results in search engines.",
				},
				textBlock3: {
					title: "The Problem",
					text: "GFB didn’t like any themes that were available for purchase at Shopify's Theme Store, so they wanted to develop their own unique theme while keeping all the necessary features. The problem was that many features they required were not really that complicated and already available if a default Dawn theme was used.",
				},
				textBlock4: {
					title: "The Solution",
					text: "Instead of starting a new theme completely from scratch, we took Shopify’s own free Dawn theme, stripped it to its bare bones and re-built our own theme on top of it. This ensured that GFB wouldn’t need to pay extra for some features that would be quite time consuming to develop from scratch. Since all the design was stripped away, we could rebuild the theme and layout to deliver the unique experience GFB desired.",
				},
				textBlock5: {
					title: "The Result",
					text: "A busy store that went on to attract many loyal and new customers. Conversion rates were above market average and on-site SEO traffic was delivering on its targeted keywords. A very satisfied customer.",
				},
				heroImage1: TheGFBHome,
				heroImage1Tiny: TheGFBHomeTiny,
				heroImage2: TheGFBMobiles,
				heroImage2Tiny: TheGFBMobilesTiny,
				heroImage3: TheGFBHome2,
				heroImage3Tiny: TheGFBHome2Tiny,
				images: [PropertyPeople, PropertyPeople, PropertyPeople, PropertyPeople],
			},
		},
	],
	brands_title: "Notable brands we have provided services for",
	brands_subtitle:
		"Apart from building websites from zero to hero, our experts have also assisted prestigious worldwide brands and their subsidaries with our consultancy and support services.",
	brands: [
		{
			name: "Starbucks",
			logo: <Starbucks />,
			text: "Web development for one of their coffee brands",
		},
		{
			name: "Walt Disney Company (Hammersmith)",
			logo: <Disney />,
			text: "Express JS backend development",
		},
		{
			name: "Johnson & Johnson",
			logo: <Johnson />,
			text: "Internal dashboard development using MERN stack",
		},
		{
			name: "Toshiba",
			logo: <Toshiba />,
			text: "Web development for one of their coffee brands",
		},
		{
			name: "Rakuten",
			logo: <Rakuten />,
			text: "Express JS backend development",
		},
	],
};
