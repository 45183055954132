// Dependencies
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { openPopupWidget, CalendlyEventListener } from "react-calendly";
import { Helmet } from "react-helmet";

// Components
import Nav from "../components/fragments/Nav";
import PageBanner from "../components/fragments/PageBanner";
import ContactFormC from "../components/fragments/ContactFormC";

// Styles
import { device } from "../components/styles/Layout";

// Icons
import { ReactComponent as EmailIcon } from "../assets/svg/email_black_24dp.svg";
import { ReactComponent as PhoneIcon } from "../assets/svg/phone_black_24dp.svg";
import { ReactComponent as AddressIcon } from "../assets/svg/home_black_24dp.svg";

// Text content, header image & Calendly data
import { contact_en } from "../data/page_content/en/pages/contact_en";
import { company_data_en } from "../data/page_content/en/company_data/company_data_en";
import { calendly_link_en } from "../data/page_content/en/company_data/calendly_link_en";

const Img = styled.img`
	width: 100%;
	margin: -5rem 0 0 3rem;
	mix-blend-mode: luminosity;
	@media ${device.md} {
		width: 60%;
		margin: 0 0 0 8rem;
	}
`;

const AddressWrap = styled.div`
	display: flex;
	margin-top: 2rem;
	width: 130%;
	& svg {
		position: absolute;
		margin: 0.3rem 0 0 -2.25rem;
		fill: ${(props) => props.theme.colours.primary};
	}
	@media ${device.lg} {
		display: block;
	}
`;

const Tel = styled.div`
	margin-left: 2.25rem;
`;
const Email = styled.div`
	margin-left: 2.25rem;
`;

const EmailAndPhone = styled.div`
	flex: 1;
`;

const Address = styled.div`
	flex: 1;
	@media ${device.md} {
		padding-top: 2rem;
	}
`;

const CalendlyBtn = styled.span`
	color: ${(props) => props.theme.colours.primary};
	text-decoration: underline;
	text-decoration-thickness: 1px;
	cursor: pointer;
	transition: ${(props) => props.theme.effects.transition};
	&:hover {
		color: ${(props) => props.theme.colours.secondaryDkB};
		transition: ${(props) => props.theme.effects.transition};
	}
`;

export default function Contact() {

	// Monitor when Calendly form has been submitted
	const [isScheduled, setIsScheduled] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleEventScheduled = () => {
		setIsScheduled(true);
	};

	return (
		<>
			<Helmet>
				<title>{contact_en.seo.title}</title>
				<meta name="description" content={contact_en.seo.description} />
			</Helmet>
			<Nav type="dark" />
			<PageBanner
				title={contact_en.title}
				img={<Img src={contact_en.header_img} />}
				height="44rem"
				mobileTopPadding="21rem"
				imagePos="behind"
				marginTop="-6rem"
			>
				<span>{contact_en.subtitle_start}</span>
				<CalendlyBtn 
					onClick={() => openPopupWidget({ url: calendly_link_en })}
					id="btn_schedule_a_video_call" // used to identify the button for GTM
					className={isScheduled ? "calendly_event_scheduled" : null}
				>
					{contact_en.subtitle_link_text}
				</CalendlyBtn>
				<CalendlyEventListener onEventScheduled={handleEventScheduled} />

				<span> {contact_en.subtitle_end}</span>
				<AddressWrap>
					<EmailAndPhone>
						<Email>
							<EmailIcon />
							{company_data_en.email}
						</Email>
						<Tel>
							<PhoneIcon />
							{company_data_en.tel_local}
						</Tel>
					</EmailAndPhone>

					<Address>
						<AddressIcon />
						<p>{company_data_en.address_line_1}</p>
						<p>{company_data_en.address_line_2}</p>
					</Address>
				</AddressWrap>
			</PageBanner>
			<ContactFormC />
		</>
	);
}
