import React from "react";
import ReactDOM from "react-dom";
import CookiesProvider from "react-cookie";

import App from "./App";
import "./css/reset.min.css";
import "./css/index.css";

import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

ReactDOM.render(
	<React.StrictMode>
		{/* <CookiesProvider> */}
		<App />
		{/* </CookiesProvider> */}
	</React.StrictMode>,
	document.getElementById("root")
);
