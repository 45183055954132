// Dependencies
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Components
import { Container, device } from "../styles/Layout";
import { TopNav } from "../../data/NavData";

// Styles
import Button from "../styles/Button";

// Icons
import { ReactComponent as LogoWhite } from "../../assets/svg/nwd-logo-white.svg";
import { ReactComponent as LogoDark } from "../../assets/svg/nwd-logo-dark.svg";
import { ReactComponent as PriceIcon } from "../../assets/svg/price-estimate.svg";

const DesktopNavWrapper = styled.nav`
	display: flex;
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	height: 6rem;
	color: white;
	z-index: 12;
	filter: ${(props) =>
		props.type === "dark" ? "none" : "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2))"};

	/* position: fixed;
	backdrop-filter: blur(20px) brightness(80%);
	height: 4rem; */
`;

const NavContainer = styled(Container)`
	display: flex;
	position: relative;
	align-items: center;
`;

const LeftCol = styled.div`
	flex: 1;
`;
const MiddleCol = styled.div`
	flex: 5;
	text-align: center;
	display: flex;
	justify-content: center;
	& a {
		text-decoration: none;
		color: ${(props) => (props.type === "dark" ? props.theme.colours.black : "white")};
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
		padding: 0 1.5rem;
	}
	& a:hover {
		color: white;
	}
	@media ${device.lg} {
		display: none;
	}
`;
const RightCol = styled.div`
	flex: 1;
	text-align: right;
	margin-top: -0.5rem;
	@media ${device.lg} {
		display: none;
	}
`;

const LinkWrap = styled.div`
	position: relative;
	width: 8rem;
	margin: 0 0.25rem;
	transition: 0.4s;
`;

const Backdrop = styled.div`
	position: absolute;
	height: 2rem;
	width: 100%;
	transform: ${(props) =>
		!props.currentPage ? "skewX(-20deg) translateX(-5%)" : "skewX(-20deg) translateX(0)"};
	margin-top: -0.65rem;
	z-index: 0;
	transition: ${(props) => !props.currentPage && "all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)"};
	background: ${(props) => props.color};

	opacity: ${(props) => (props.currentPage ? ".9" : "0")};

	cursor: pointer;
	&:hover {
		opacity: 0.9;
		transform: ${(props) => !props.currentPage && "skewX(-20deg) translateX(0%)"};
		transition: ${(props) =>
			!props.currentPage && "all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)"};
	}
`;

const MenuItem = styled(Link)``;

const LinkText = styled.div`
	position: absolute;
	color: ${(props) => props.currentPage && "white"};
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	z-index: 1;
	pointer-events: none;
`;

const PriceEstimateIcon = styled(PriceIcon)`
	width: 1.5rem;
	margin-top: 0 !important;
`;

export default function Nav({ type }) {
	// Create state container for current page uri
	const [currentPageUri, setCurrentPageUri] = useState("/");

	useEffect(() => {
		// Read the current page uri to highlight the corresponding/active menu item
		setCurrentPageUri(window.location.pathname);
	}, [currentPageUri]);

	return (
		<>
			<DesktopNavWrapper type={type}>
				<NavContainer>
					<LeftCol>
						<Link to="/">{type === "dark" ? <LogoDark /> : <LogoWhite />}</Link>
					</LeftCol>

					<MiddleCol type={type}>
						{TopNav.map((item, index) => (
							<LinkWrap key={index}>
								<MenuItem to={item.link}>
									<LinkText currentPage={item.link === currentPageUri ? true : false}>
										{item.title}
									</LinkText>
									<Backdrop
										currentPage={item.link === currentPageUri ? true : false}
										color={item.color}
									/>
								</MenuItem>
							</LinkWrap>
						))}
					</MiddleCol>

					<RightCol>
						{type === "dark" ? (
							// Show dark button when the nav background is light
							<Button
								text="Get a price estimate"
								bkg="dark"
								border="dark"
								link="/get-estimate"
								small="true"
								icon={<PriceEstimateIcon />}
								id="btn_get_a_price_estimate" // used to identify the button for GTM
							/>
						) : (
							// Show light button when the background is dark
							<Button
								text="Get a price estimate"
								bkg="dark"
								border="dark"
								link="/get-estimate"
								small="true"
								icon={<PriceEstimateIcon />}
								id="btn_get_a_price_estimate" // used to identify the button for GTM
							/>
						)}
					</RightCol>
				</NavContainer>
			</DesktopNavWrapper>
		</>
	);
}
