export default function contact_form_c_template(name, email, tel, currentWebsite, tellUsMore) {
	// Email to form submitter (CUSTOMER/USER)
	const emailToSubmitter = {
		fromEmail: "info@nwdevelopment.co.uk",
		toEmail: email,
		subject: "Thank you for getting in touch with us",
		body: `
		<style>
			p {
				font-size:16px; 
				font-family: "Open Sans", sans-serif;
			}
			a {
				display:block;
				font-size:16px; 
				font-family: "Open Sans", sans-serif;
			}
		</style>
		<p>Hello ${name},</p>
		<p>Thank you for your interest and submitting a contact form at our website.</p>
		<p>We aim to get back to you within the next 24 hours.</p>
		<p>Kind regards,</p>
		<p>
			New Window Development Team
			<a href="https://nwdevelopment.co.uk">https://nwdevelopment.co.uk</a>
		</p>
		
		
		`,
	};

	// Email to admin
	const emailToRecipient = {
		fromEmail: "info@nwdevelopment.co.uk",
		toEmail: "info@nwdevelopment.co.uk",
		subject: `${name} has submitted a contact form at NWD`,
		body: `
		<p>Name: ${name}</p>
		<p>Email: ${email}</p>
		<p>Tel: ${tel}</p>
		<p>Current Website: ${currentWebsite}</p>
		<p>Tell us more: ${tellUsMore}</p>
		`,
	};

	const successMsg = `Thank you! Your message has been sent.<br>We are going to get back to you within the next 24 hours.`;

	const errorMsg = `Sorry, something went wrong.<br>Please try again later or send us an email directly to info@nwdevelopment.co.uk`;

	const data = {
		emailToSubmitter,
		emailToRecipient,
		successMsg,
		errorMsg,
	};

	return data;
}
