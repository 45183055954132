// Dependencies
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import WAVES from "vanta/dist/vanta.waves.min";
import NET from "vanta/dist/vanta.net.min";
import { Link } from "react-router-dom";
import { openPopupWidget, CalendlyEventListener } from "react-calendly";

// Styles
import { Container, device } from "../styles/Layout";

// Icons
import { ReactComponent as Email } from "../../assets/svg/email_black_24dp.svg";
import { ReactComponent as Phone } from "../../assets/svg/phone_black_24dp.svg";
import { ReactComponent as LogoWhite } from "../../assets/svg/nwd-logo-white.svg";
// import FooterVideo from "../../assets/video/programmer-v3-1.mp4"; // Francis
import FooterVideo from "../../assets/video/programmer-v2.mp4"; // Stock image

// Images
import TrustpilotBadge from "../../assets/img/trustpilot-badge.png";
import ShopifyExpertsLogo from "../../assets/img/shopify-experts-logo.png";

// Content
import { FooterNav } from "../../data/NavData";
import { footer_en } from "../../data/page_content/en/components/footer_en";
import { calendly_link_en } from "../../data/page_content/en/company_data/calendly_link_en";

const Wrapper = styled.footer``;

const TopWrap = styled.div`
	height: 25rem;
	background: ${(props) => props.theme.colours.gradientA};
`;

const WavesBackground = styled.div`
	height: 100%;
	mix-blend-mode: ${(props) =>
		props.safari
			? "overlay"
			: "luminosity"}; // Safari doesn't seem to support luminosity very well, so use overlay instead which looks OK, but not as good as luminosity
`;

const ContainerAbsolute = styled(Container)`
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	padding-top: 4rem;
	transform: translate(-50%, 50%);
	left: 50%;
	z-index: 1;
	@media ${device.xl} {
		padding-top: 3rem;
	}

	@media ${device.md} {
		padding-top: 2rem;
	}
	@media ${device.sm} {
		padding-top: 0;
		margin-top: -2rem;
	}
`;

const MiddleWrap = styled.div`
	display: flex;
	align-items: center;
	background: ${(props) => props.theme.colours.black};
`;

const LowerWrap = styled.div`
	background: ${(props) => props.theme.colours.veryblack};
`;

const CallOut = styled.h1`
	color: ${(props) => props.theme.colours.offwhite};
	padding: 0.5rem 0;
	& span {
		font-weight: 500;
	}

	@media ${device.xl} {
		text-align: center;
	}
	@media ${device.md} {
		display: ${(props) => (props.noMob ? "none" : "block")};
		margin-top: 14%;
		line-height: 1.3;
	}
	@media ${device.sm} {
		font-size: 1.75rem;
	}
`;

const MiddleFlexbox = styled.div`
	display: flex;
	column-gap: 3rem;
	padding: 3rem 0;
	height: 15rem;
	@media ${device.lg} {
		height: auto;
	}
	@media ${device.md} {
		display: block;
	}
`;

const LogoWrap = styled.div`
	flex: 1;
	@media ${device.xl} {
		text-align: center;
		margin-bottom: 2rem;
	}
`;

const Logo = styled(LogoWhite)`
	padding-top: 0.5rem;
	@media ${device.xl} {
		padding-bottom: 3rem;
	}
`;

const Address = styled.div`
	flex: 1;
	& p {
		font-size: 16px;
		color: ${(props) => props.theme.colours.offwhite};
	}
	& b {
		font-weight: 600;
	}
	@media ${device.md} {
		padding-left: 30%;
	}
	@media ${device.sm} {
		padding-left: 16%;
	}
`;

const Links = styled.div`
	flex: 1;
	& p {
		font-size: 16px;
		color: ${(props) => props.theme.colours.offwhite};
	}
	@media ${device.md} {
		padding-top: 1rem;
		padding-left: 30%;
	}
	@media ${device.sm} {
		padding-left: 16%;
	}
`;

const CalendlyLink = styled.p`
	cursor: pointer;
`;

const VideoFlex = styled.div`
	flex: 1;
	@media ${device.xl} {
		flex: 0;
	}
`;

const NavWrapper = styled.ul``;
const NavItem = styled.li`
	font-size: 1rem;
	transition: ${(props) => props.theme.effects.transition};
	& a {
		color: ${(props) => props.theme.colours.offwhite};
		text-decoration: none;
		letter-spacing: 0.5px;
		line-height: 1.75rem;
	}
	&:hover {
		transform: translateX(5px);
		transition: ${(props) => props.theme.effects.transition};
	}
`;

const Title = styled.p`
	font-weight: 500;
	margin-bottom: 1rem;
`;

const VideoContainer = styled.div`
	position: absolute;
	width: 33vw;
	height: 21rem;
	right: 0;
	overflow: hidden;
	clip-path: polygon(110px 0%, 100% 0%, 100% 100%, 0% 100%);
	/* & video {
		position: fixed;
		bottom: 0;
		width: 49%;
		height: auto;
	} */
	& video,
	img {
		position: fixed;
		bottom: 0;
		width: 44%;
		right: 0;
	}

	@media (max-width: 1500px) {
		& video,
		img {
			width: 48%;
			right: -6rem;
		}
	}

	@media (max-width: 1400px) {
		& video,
		img {
			width: 52%;
		}
	}

	@media (max-width: 1300px) {
		& video,
		img {
			width: 53%;
			right: -7rem;
		}
	}

	@media (max-width: 1370px) {
		& video,
		img {
			width: 53%;
		}
	}

	@media ${device.xl} {
		display: none;
	}
`;

const SmallPrint = styled.p`
	color: ${(props) => props.theme.colours.offwhite};
	font-size: 12px;
	padding: 0.5rem 0;
	& a {
		color: ${(props) => props.theme.colours.offwhite};
		text-decoration-thickness: 0.75px;
	}
	@media ${device.md} {
		text-align: center;
	}
`;

const EmailIcon = styled(Email)`
	width: 1rem;
	fill: ${(props) => props.theme.colours.offwhite};
	position: absolute;
	margin: 0.25rem 0 0 0;
`;
const PhoneIcon = styled(Phone)`
	width: 1rem;
	fill: ${(props) => props.theme.colours.offwhite};
	position: absolute;
	margin: 0.25rem 0 0 0;
`;

const ContactMethod = styled.p`
	padding-left: 1.75rem;
	& a {
		color: ${(props) => props.theme.colours.offwhite};
		text-decoration: none;
	}
`;

const Trustpilot = styled.img`
	width: 50%;
	padding-top: 2rem;
	@media ${device.xl} {
		padding-left: 7%;
		margin-left: -30%;
		padding-top: 0;
	}
	@media ${device.lg} {
		padding-left: 0;
		margin-left: -48%;
		padding-top: 0;
	}
	@media ${device.md} {
		padding-left: 30%;
		margin-left: -30%;
		padding-top: 0;
	}
	@media ${device.sm} {
		padding-left: 16%;
		margin-left: -30%;
		padding-top: 0;
	}
`;

const ShopifyExperts = styled.img`
	display: block;
	width: 50%;
	padding-top: 1rem;
	@media ${device.xl} {
		padding-left: 13%;
	}
	@media ${device.lg} {
		padding-left: 0;
	}
	@media ${device.md} {
		padding-left: 30%;
	}
	@media ${device.sm} {
		padding-left: 16%;
	}
`;

export default function Footer() {
	// Waves effect
	const [vantaEffect, setVantaEffect] = useState(0);
	const wavesBkg = useRef(null);

	// Detect browser
	const [isSafari, setIsSafari] = useState(false);

	// Monitor when Calendly form has been submitted
	const [isScheduled, setIsScheduled] = useState(false);

	useEffect(() => {
		if (navigator.userAgent.match(/AppleWebKit/) && !navigator.userAgent.match(/Chrome/)) {
			setIsSafari(true);
		} else {
			setIsSafari(false);
		}

		if (!vantaEffect) {
			setVantaEffect(
				NET({
					el: wavesBkg.current,
					mouseControls: false,
					touchControls: false,
					gyroControls: false,
					minHeight: 400.0,
					minWidth: 400.0,
					scale: 0.1,
					scaleMobile: 1.0,
					color: 0xb1b1b1,
					backgroundColor: 0x464646,
				})
			);
		}

		function handleResize() {
			vantaEffect.resize();
		}

		window.addEventListener("resize", handleResize);

		return () => {
			if (vantaEffect) vantaEffect.destroy();
		};
	}, [vantaEffect]);

	const handleEventScheduled = () => {
		setIsScheduled(true);
	};

	return (
		<Wrapper>
			<TopWrap>
				<ContainerAbsolute>
					<CallOut noMob>{footer_en.top.line1}</CallOut>
					<CallOut>
						<span>{footer_en.top.line2_1}</span>
						{footer_en.top.line2_bold}
						<span>{footer_en.top.line2_2}</span>
					</CallOut>
				</ContainerAbsolute>
				<WavesBackground ref={wavesBkg} safari={isSafari} />
			</TopWrap>
			<MiddleWrap>
				<Container>
					<MiddleFlexbox>
						<LogoWrap>
							<Logo />
							<a
								href="https://www.trustpilot.com/review/nwdevelopment.co.uk"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Trustpilot src={TrustpilotBadge} alt="reviews" />
							</a>
							<ShopifyExperts src={ShopifyExpertsLogo} alt="reviews" />
						</LogoWrap>
						<Address>
							<Title>{footer_en.middle.column1.title}</Title>
							<p>{footer_en.middle.column1.address_line1}</p>
							<p>{footer_en.middle.column1.address_line2}</p>
							<br />

							<EmailIcon />
							<ContactMethod>
								<a
									href={`mailto:${footer_en.middle.column1.email}`}
									target="_blank"
									rel="noopener noreferrer"
									id="btn_mailto" // used to identify the button for GTM
								>
									{footer_en.middle.column1.email}
								</a>
							</ContactMethod>

							<PhoneIcon />
							<ContactMethod>{footer_en.middle.column1.tel}</ContactMethod>
						</Address>
						<Links>
							<Title>{footer_en.middle.column2.title}</Title>
							{FooterNav.map((item, index) => (
								<NavWrapper key={index}>
									<NavItem>
										<Link to={item.link}>{item.title}</Link>
									</NavItem>
								</NavWrapper>
							))}
							<NavWrapper>
								<NavItem>
									<CalendlyLink
										onClick={() => openPopupWidget({ url: calendly_link_en })}
										id="btn_schedule_a_video_call" // used to identify the button for GTM
										className={isScheduled ? "calendly_event_scheduled" : null} // identify scheduled event with GTM
									>
										Schedule a Call With Us
									</CalendlyLink>

									<CalendlyEventListener onEventScheduled={handleEventScheduled} />
								</NavItem>
							</NavWrapper>
						</Links>
						<VideoFlex></VideoFlex>
					</MiddleFlexbox>
				</Container>
				<VideoContainer>
					<video loop={true} autoPlay="autoplay" muted>
						<source src={FooterVideo} type="video/mp4" />
					</video>
					{/* <img src="https://nwdevelopment.co.uk/video-var-a.jpg" /> */}
				</VideoContainer>
			</MiddleWrap>
			<LowerWrap>
				<Container>
					<SmallPrint>
						© {new Date().getFullYear()} All rights reserved ・ New Window Development is part of{" "}
						<a href="https://newwindowmarketing.co.uk" target="_blank" rel="noopener noreferrer">
							New Window Marketing Limited
						</a>
						・ Company number 10152936 ・ Registered in England and Wales ・
						<Link to="/privacy-policy">Privacy Policy</Link> ・
						<Link to="/terms-and-conditions">Terms & Conditions</Link>
					</SmallPrint>
				</Container>
			</LowerWrap>
		</Wrapper>
	);
}
