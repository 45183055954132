import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Container, device } from "../styles/Layout";
import ProgressiveImage from "react-progressive-image";
import { useCookies } from "react-cookie";

import { team_en } from "../../data/page_content/en/pages/team_en";

const slideIn = keyframes`
	from {
		transform: translateY(20px);
	} 
	to {
		/* opacity:1; */
		transform: translateY(0);
	}
`;

const Wrapper = styled.section`
	background-image: ${(props) => props.theme.colours.gradientB_reverse};
	padding-top: 6rem;
`;

const TeamContainer = styled.div`
	padding: 0 0 3rem 0;
	background: ${(props) => props.theme.colours.offwhite};
	box-shadow: 0px 0px 11px 11px #00000029;
`;

const GridWrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 3rem;
	grid-column-gap: 3rem;
	padding: 9rem 0;
	@media ${device.lg} {
		grid-template-columns: 1fr 1fr;
	}
	@media ${device.md} {
		grid-template-columns: 1fr;
	}

	& > div {
		transform: translateY(20px);
	}

	& > div:nth-child(1) {
		/* opacity: 0; */
		animation: ${slideIn} 1s ease-in-out forwards;
	}

	& > div:nth-child(2) {
		/* opacity: 0; */
		animation: ${slideIn} 1s ease-in-out 0.125s forwards;
	}

	& > div:nth-child(3) {
		/* opacity: 0; */
		animation: ${slideIn} 1s ease-in-out 0.25s forwards;
	}

	& > div:nth-child(4) {
		/* opacity: 0; */
		animation: ${slideIn} 1s ease-in-out 0.375s forwards;
	}

	& > div:nth-child(5) {
		/* opacity: 0; */
		animation: ${slideIn} 1s ease-in-out 0.5s forwards;
	}

	& > div:nth-child(6) {
		/* opacity: 0; */
		animation: ${slideIn} 1s ease-in-out 0.625s forwards;
	}

	& > div:nth-child(n + 7) {
		/* opacity: 0; */
		animation: ${slideIn} 1s ease-in-out 0.75s forwards;
	}
`;

const Member = styled.div`
	display: flex;
	column-gap: 2rem;
`;

const ImgWrap = styled.div`
	flex: 1;
`;

const Img = styled.img`
	width: 100%;
	border: 2px solid #5680e9;
	/* clip-path: polygon(30% 0, 100% 0, 100% 70%, 70% 100%, 0 100%, 0 30%); */
	transition: all 0.25s ease-in-out;
	clip-path: inset(0);
`;

const InfoWrap = styled.div`
	flex: 2;
`;

const Name = styled.h3`
	font-weight: 300;
	padding-bottom: 1rem;
	color: ${(props) => props.theme.colours.black};
`;

const Role = styled.p`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5;
	padding-bottom: 1rem;
	color: ${(props) => props.theme.colours.primary};
`;

const Info = styled.p`
	font-size: 16px;
	line-height: 1.5;
	color: ${(props) => props.theme.colours.black};
`;

export default function TeamGrid() {
	// To avoid the blur effect when the image is already in browser's cache, store a cookie in the browser after the first render. If the cookie is present, the original image will be used instead of progressive image.

	const [cookies, setCookie] = useCookies(["our_team_gallery_cookie"]);
	const [cookieIsSet, setCookieIsSet] = useState(false);

	useEffect(() => {
		if (!cookies.our_team_gallery_cookie) {
			console.log("Blur now!");

			// let cookieExpiry = new Date(new Date().getTime() + 15000); // 15 seconds (for testing purposes)
			let cookieExpiry = new Date(new Date().getTime() + 15 * 60 * 1000); // 15 minutes
			setCookie("our_team_gallery_cookie", "true", { path: "/", expires: cookieExpiry });

			setTimeout(() => {
				setCookieIsSet(true);
			}, 1500);
		} else {
			setCookieIsSet(true);
		}
	}, [cookieIsSet]);

	return (
		<Wrapper>
			<TeamContainer>
				<Container>
					<GridWrap>
						{team_en.members.map((member, index) => (
							<Member key={index}>
								<ImgWrap>
									{/* <Img src={member.img}></Img> */}

									{!cookieIsSet ? (
										<ProgressiveImage src={member.img} placeholder={member.imgTiny} delay={0}>
											{(src, loading) => (
												<Img
													style={{ filter: loading ? "blur(10px)" : "blur(0)" }}
													src={src}
													alt={member.role}
												/>
											)}
										</ProgressiveImage>
									) : (
										<Img src={member.img} />
									)}
								</ImgWrap>
								<InfoWrap>
									<Name>{member.name}</Name>
									<Role>{member.role}</Role>
									<Info>{member.info}</Info>
								</InfoWrap>
							</Member>
						))}
					</GridWrap>
				</Container>
			</TeamContainer>
		</Wrapper>
	);
}
