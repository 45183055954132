import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { Container } from "../styles/Layout";
import { device } from "../styles/Layout";

const slideIn = keyframes`
from {
    transform: translateY(100%);
    opacity:0;
} to {
    transform: translateY(0);
    opacity:1;
}

`;

const Wrapper = styled.section`
	position: fixed;

	left: 50%;
	transform: translate(-50%, 0);
	bottom: 0rem;
	height: 4rem;

	opacity: 0.9;
	z-index: 140;
	clip-path: polygon(20px 0%, 100% 0%, 830px 100%, 0% 100%);
	transition: ${(props) => props.theme.effects.transition};

	pointer-events: ${(props) => (props.closed ? "none" : "all")};

	@media ${device.md} {
		height: 6.8rem;
		clip-path: none;
		transform: none;
		left: 0;
	}
`;

const Content = styled(Container)`
	display: flex;
	padding: 0.9rem 2rem 0.5rem 4rem;
	width: 750px;
	background: ${(props) => props.theme.colours.black};
	transition: ${(props) => props.theme.effects.transition};
	animation: ${slideIn} 1s ease-in-out 3s forwards;
	transform: translateY(100%);
	margin-top: ${(props) => (props.closed ? "4.1rem" : "0")};

	@media ${device.md} {
		display: block;
		margin-top: ${(props) => (props.closed ? "6.9rem" : "0")};
		width: 100%;
		padding: 1rem;
	}
`;

const TextWrap = styled.div`
	flex: 3.75;
`;
const Title = styled.h3`
	font-size: 1rem;
	font-weight: 500;
	color: ${(props) => props.theme.colours.offwhite};
`;
const Text = styled.p`
	display: inline-block;
	font-size: 0.8rem;
	margin-top: -0.4rem;
	color: ${(props) => props.theme.colours.offwhite};
`;

const ButtonWrap = styled.div`
	flex: 2;
	display: flex;
	height: 2rem;
`;

const Button = styled.div`
	font-family: ${(props) => props.theme.fonts.title};
	margin-top: 0.35rem;
	margin-left: 1rem;
	font-size: 0.9rem;
	font-weight: 500;
	padding: 0.25rem 1rem;
	border: 1px solid ${(props) => props.theme.colours.offwhite};
	cursor: pointer;
	transform: scale(0.999); // If it's not 1 then the text does a weird flickering effect on mouseout
	transition: ${(props) => props.theme.effects.transition}; // cubic-bezier for bouncy effect
	&:hover {
		transform: scale(1.04999);
	}
`;

const AcceptBtn = styled(Button)`
	color: ${(props) => props.theme.colours.black};
	background: ${(props) => props.theme.colours.offwhite};
	@media ${device.md} {
		margin-left: 0;
		margin-right: 1rem;
	}
`;
const DenyBtn = styled(Button)`
	color: ${(props) => props.theme.colours.offwhite};
	@media ${device.md} {
		margin-left: 0;
	}
`;

const PrivacyLink = styled(Link)`
	display: inline;
	font-size: 0.8rem;
	padding-left: 0.1rem;
	color: ${(props) => props.theme.colours.offwhite};
	@media ${device.md} {
		display: block;
		position: absolute;
		right: 10%;
		bottom: 25px;
	}
`;

export default function Gdpr() {
	const [closed, setClosed] = useState(false);

	const handleClick = () => {
		setClosed(true);
	};

	return (
		<Wrapper closed={closed}>
			<Content closed={closed}>
				<TextWrap>
					<Title>We use cookies</Title>
					<Text>This website uses essential cookies to ensure proper operation. </Text>
					<PrivacyLink to="/privacy-policy">Privacy Policy.</PrivacyLink>
				</TextWrap>
				<ButtonWrap>
					<AcceptBtn onClick={handleClick}>Accept All</AcceptBtn>
					<DenyBtn onClick={handleClick}>Deny All</DenyBtn>
				</ButtonWrap>
			</Content>
		</Wrapper>
	);
}
