import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import ContactFormB from "../../components/fragments/ContactFormB";

// Styles
import Slider from "rc-slider";
import "../../css/sliders.less";
import "rc-slider/assets/index.css";
import "../../css/checkbox.css";
import { Container, device } from "../styles/Layout";
import Button from "../styles/Button";

// Images
import ChevronLeft from "../../assets/svg/triangle-left.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/info.svg";

// Get Estimate data (+icons) & price calculation formula
import { price_estimate_sliders_eng as SlidersData } from "../../data/language/eng";
import { Calculate } from "../../data/Calculate";

const slideIn = keyframes`
	from {
		/* filter: opacity(0); */
		transform: translateY(10px);
	} 
	to {
		/* filter: opacity(1); */
		transform: translateY(0px);
	}
`;

const Wrapper = styled.section`
	background-image: linear-gradient(
		to top right,
		#5ab9ea1a,
		#4bb0ec1a,
		#40a6ee1a,
		#3e9bee1a,
		#4490ed1a,
		#4e89eb1a,
		#5981e81a,
		#6479e41a,
		#6e73e01a,
		#776ddb1a,
		#8067d61a
	);
`;

const SelectOptionsWrapper = styled.div`
	padding-bottom: 10rem;
	transition: 0.5s;
	opacity: ${(props) => (props.isVisible ? 1 : 0)};

	transform: ${(props) => (props.isVisible ? "translateX(0)" : "translateX(-100px)")};

	/* background: blue; */
`;

const SliderWrapper = styled.div`
	display: grid;
	grid-template-columns: 4fr 6fr 4fr;
	padding-top: 6rem;
	@media ${device.md} {
		display: block;
	}

	/* filter: opacity(0); */
	transform: translateY(10px);
	animation: ${slideIn} 1s ease-in-out forwards;
`;

const Name = styled.p`
	font-family: "Bai Jamjuree";
	font-weight: 500;
	font-size: 18px;
	width: 12rem;
	color: ${(props) => props.theme.colours.black};
	margin-top: -0.8rem;
	height: 2rem;
	@media ${device.md} {
		padding-bottom: 2rem;
		width: auto;
	}
`;

const InfoContainer = styled.div`
	position: relative;
`;

const Info = styled.p`
	position: absolute;
	display: flex;
	margin: 0 0 0 12rem;
	padding: 1rem;
	align-items: center;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 20rem;
	background: ${(props) => props.theme.colours.primary};
	filter: drop-shadow(0 0 8px #00000047);
	color: ${(props) => props.theme.colours.offwhite};
	font-size: 16px;
	line-height: 1.5;
	opacity: 0;
	transition: 0.5s;
	border-radius: 5px;
	&:before {
		content: url(${ChevronLeft});
		position: absolute;
		margin: 0rem 0 0 -1.9rem;
	}
`;

const SliderContainer = styled.div`
	padding: 0 2rem;
	& .rc-slider {
		pointer-events: none;
	}
	& .rc-slider-mark {
		pointer-events: all;
	}
	& .rc-slider-mark-text {
		font-size: 16px;
		line-height: 1.25rem;
		color: ${(props) => props.theme.colours.black};
		margin-top: -2rem;
		padding-top: 3rem;
		user-select: none;
		max-width: 6.5rem;
	}
	& .rc-slider-rail {
		background-color: ${(props) => props.theme.colours.black};
	}
	& .rc-slider-track {
		background-color: ${(props) => props.theme.colours.primary};
		transition: all 0.5s ease-in-out;
	}

	& .rc-slider-step .rc-slider-dot.rc-slider-dot-active {
		background-color: ${(props) => props.theme.colours.primary};
		border: none;
	}

	& .rc-slider-dot {
		background-color: ${(props) => props.theme.colours.black};
		border: none;
		width: 14px;
		height: 14px;
		bottom: -5px;
	}
	& .rc-slider-handle {
		border: none;
		background-color: ${(props) => props.theme.colours.primary};
		width: 36px;
		height: 36px;
		margin-top: -16px;
		transition: all 0.5s ease-in-out;
		pointer-events: all !important;
		&:before {
			content: url(${(props) => props.icon});
			position: absolute;
			margin: 3px;
			transform: scale(0.9);
		}
		&:hover {
			background-color: red;
		}
	}

	& .rc-slider-mark-text-active {
		color: ${(props) => props.theme.colours.primary};
	}
`;

const CtaWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	margin-top: 8rem;
`;

const CurrencyToggle = styled.span`
	& ::before,
	& ::after {
		font-size: 0.8rem !important;
		font-family: "Bai Jamjuree";
	}

	// Large size toggle
	/* position: absolute;
	right: 130px;
	margin-top: 6px; */

	// Small size toggle
	display: inline-flex;
	position: absolute;
	margin: 0.2rem 0.2rem 0 1rem;

	& .can-toggle__switch {
		height: 25px !important;
		flex: 0 0 80px !important;
	}

	& .can-toggle__switch:before,
	.can-toggle__switch:after {
		line-height: 21px !important;
		width: 40px !important;
	}

	& .can-toggle__switch:before {
		left: 40px !important;
		padding: 2px 12px !important;
	}

	& input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
		transform: translate3d(36px, 0, 0);
	}

	& input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
		left: 0 !important;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
`;

const ResultWrapper = styled.div`
	transition: 0.5s;
	opacity: ${(props) => (props.isVisible ? 1 : 0)};
	/* transform: ${(props) => (props.isVisible ? "translateX(0)" : "translateX(100px)")}; */
`;

const ResultContainer = styled.div`
	display: flex;
	position: absolute;
	width: calc(100% - 4rem);
	background: ${(props) => props.theme.colours.gradientA};

	min-height: 21rem;
	margin-top: -18rem;
	box-shadow: 0 3px 8px 0px #00000047;
	z-index: 10;

	@media ${device.md} {
		display: block;
		position: relative;
		width: 100%;
	}
`;

const ResultSummary = styled.div`
	flex: 1;
	padding: 3rem 3rem 2rem 3rem;
`;

const ResultEstimate = styled.div`
	flex: 1;
	padding: 2rem;
`;

const ResultOuterContainer = styled(Container)`
	position: relative;
	padding-top: 25rem;

	@media ${device.md} {
		padding: 25rem 0 0 0;
		width: 100%;
	}
`;

const ResultTitle = styled.h2`
	font-family: "Bai Jamjuree";
	font-weight: 500;
	color: ${(props) => props.theme.colours.offwhite};
	margin-bottom: 2rem;
`;

const ResultSummaryGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 0.7rem;
	margin-bottom: 1rem;
	color: white;
	& p {
		font-size: 16px;
	}
`;

const ResultSummaryItem = styled.p`
	font-weight: 500;
`;
const ResultSummarySelected = styled.p`
	position: relative;
`;

const InfoBox = styled.span`
	display: inline-flex;
	position: absolute;

	background: white;
	color: black;
	padding: 1rem;
	top: 50%;
	transform: translate(-50%, -50%);
	margin: -0.4rem 0 0 12.5rem;
	opacity: 0;
	transition: ${(props) => props.theme.effects.transition};
	width: 300px;
	pointer-events: none;
	z-index: 11;
	box-shadow: ${(props) => props.theme.effects.boxShadow};

	width: 20rem;
	font-size: 16px;
	line-height: 1.5;
	border-radius: 5px;
`;

const InfoBtn = styled(InfoIcon)`
	width: 1rem;
	fill: white;
	margin-left: 0.25rem;
	opacity: 0.2;
	transition: ${(props) => props.theme.effects.transition};
	&:hover {
		transition: ${(props) => props.theme.effects.transition};
		opacity: 1;
	}
	&:hover + ${InfoBox} {
		transition: ${(props) => props.theme.effects.transition};
		opacity: 1;
	}

	position: absolute;
`;

const ResultEstimateBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background: ${(props) => props.theme.colours.offwhite};
	height: calc(100% - 4rem);
	padding: 2rem;
	& p {
		margin-bottom: 1rem;
	}
`;

const ResultLargeText = styled.p`
	font-family: "Bai Jamjuree";
	font-weight: 300;
`;
const ResultSmallText = styled.p`
	font-size: 16px;
`;

function addActiveClass(value, className) {
	document.querySelectorAll(`.${className} .rc-slider-mark-text`).forEach((mark, index) => {
		if (index === value) {
			mark.style.color = "#5680E9";
			mark.style.fontWeight = "600";
		} else {
			mark.style.color = "#183149";
			mark.style.fontWeight = "300";
		}
	});
}

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function scrollElement(id, offset, behavior) {
	let element = document.getElementById(id);
	let pos = element.style.position;
	let top = element.style.top;
	element.style.position = "relative";
	element.style.top = `${offset}px`;
	element.scrollIntoView({ behavior, block: "start" });
	element.style.top = top;
	element.style.position = pos;
}

export default function PriceEstimate() {
	const [valueA, setValueA] = useState(SlidersData[0].defaultMark);
	const [valueB, setValueB] = useState(SlidersData[1].defaultMark);
	const [valueC, setValueC] = useState(SlidersData[2].defaultMark);
	const [valueD, setValueD] = useState(SlidersData[3].defaultMark);
	const [valueE, setValueE] = useState(SlidersData[4].defaultMark);
	const [valueF, setValueF] = useState(SlidersData[5].defaultMark);

	const [baseEstimate, setBaseEstimate] = useState(0);
	const [lowEstimate, setLowEstimate] = useState(0);
	const [highEstimate, setHighEstimate] = useState(0);

	const [currencySymbol, setCurrencySymbol] = useState("£");
	const [currencyMiltiplier, setCurrencyMiltiplier] = useState(1);

	// Track if any slider with X+ value has been selected
	const [pagesFlag, setPagesFlag] = useState(false);
	const [contentsFlag, setContentsFlag] = useState(false);
	const [ecommerceFlag, setEcommerceFlag] = useState(false);

	// Control which forms are shown in DOM and which not
	const [showOptions, setShowOptions] = useState(true); // When this component loads options are shown
	const [showResult, setShowResult] = useState(false); // When this component loads result is hidden

	// Control which options are still in DOM but animate in or out of view before removing from DOM
	const [optionsVisible, setOptionsVisible] = useState(true); // When this component loads options are shown
	const [resultVisible, setResultVisible] = useState(false); // When this component loads result is hidden

	// Collect all necessary quote data in an object and send it to contact form to forward to emails
	const refResultSummary = useRef(null);
	const [quoteData, setQuoteData] = useState({});

	const handleGetEstimateSubmit = (e) => {
		e.preventDefault();

		// Do an animation before hiding the Options form
		setOptionsVisible(false);

		// Hide the Options from DOM now
		setTimeout(() => {
			setShowOptions(false);
		}, 500);

		// Set results form visible in the DOM
		setShowResult(true);

		// Scroll to the top of result box
		setTimeout(() => {
			scrollElement("result-box", 60, "auto");
		}, 500);

		// Animate the result in view now
		setTimeout(() => {
			setResultVisible(true);
		}, 500);
	};

	const handleGetEstimateReset = (e) => {
		e.preventDefault();

		// Do an animation before hiding the results form
		setResultVisible(false);

		// Set options form visible in the DOM
		setShowOptions(true);

		// Scroll to the top of result box
		setTimeout(() => {
			scrollElement("options-box", 0, "auto");
		}, 500);

		// Animate the options in view now
		setTimeout(() => {
			setOptionsVisible(true);
		}, 500);

		// Hide the results from DOM now
		setTimeout(() => {
			setShowResult(false);
		}, 1000);
	};

	const handleSlider = (value, className) => {
		addActiveClass(value, className);

		switch (className) {
			case "sliderA":
				setValueA(value);
				break;
			case "sliderB":
				setValueB(value);
				break;
			case "sliderC":
				setValueC(value);
				break;
			case "sliderD":
				setValueD(value);
				break;
			case "sliderE":
				setValueE(value);
				break;
			case "sliderF":
				setValueF(value);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		// Add active class to default slider marks on page load
		addActiveClass(valueA, "sliderA");
		addActiveClass(valueB, "sliderB");
		addActiveClass(valueC, "sliderC");
		addActiveClass(valueD, "sliderD");
		addActiveClass(valueE, "sliderE");
		addActiveClass(valueF, "sliderF");

		// Calculate low, base and high estimates
		setLowEstimate(
			Calculate(valueA, valueB, valueC, valueD, valueE, valueF)[0] * currencyMiltiplier
		);
		setBaseEstimate(
			Calculate(valueA, valueB, valueC, valueD, valueE, valueF)[1] * currencyMiltiplier
		);
		setHighEstimate(
			Calculate(valueA, valueB, valueC, valueD, valueE, valueF)[2] * currencyMiltiplier
		);

		// Add flags to high estimates with X+ values
		if (valueA === Object.values(SlidersData[0].marks).length - 1) {
			setPagesFlag(true);
		} else {
			setPagesFlag(false);
		}

		if (valueC === Object.values(SlidersData[2].marks).length - 1) {
			setContentsFlag(true);
		} else {
			setContentsFlag(false);
		}

		if (valueF === Object.values(SlidersData[5].marks).length - 1) {
			setEcommerceFlag(true);
		} else {
			setEcommerceFlag(false);
		}

		// Collect all necessary quote data in an object and send it to contact form to forward further to emails
		if (refResultSummary.current) {
			let resultArray = [];
			refResultSummary.current.querySelectorAll("div").forEach((outerElement) => {
				outerElement.querySelectorAll("p").forEach((innerElement) => {
					// Add each result to an array. Only first child of each p element is needed to avoid adding the info text. If info text is also desired, remove the childNodes[0] from the variable
					resultArray.push(innerElement.childNodes[0].textContent);
				});
			});

			setQuoteData({
				lowEstimate: currencySymbol + lowEstimate,
				baseEstimate: currencySymbol + baseEstimate,
				highEstimate: currencySymbol + highEstimate,
				described: resultArray,
			});
		}
	}, [
		valueA,
		valueB,
		valueC,
		valueD,
		valueE,
		valueF,
		baseEstimate,
		lowEstimate,
		highEstimate,
		showOptions,
		refResultSummary,
		currencySymbol,
		currencyMiltiplier,
	]);

	const handleSetCurrency = (e) => {
		if (e.target.checked) {
			setCurrencySymbol("$");
			setCurrencyMiltiplier(1.3);
		}

		if (!e.target.checked) {
			setCurrencySymbol("£");
			setCurrencyMiltiplier(1);
		}
	};

	const handleMouseOver = (e, targetText, targetSlider) => {
		// Match the hovered text with slider's info title and display the tooltip info
		targetText.forEach((text) => {
			const targetSelector = document.querySelector(`.${targetSlider} > p[name="${text}"]`);
			document.querySelectorAll(`.${targetSlider} .rc-slider-mark`).forEach((mark) => {
				function displayInfo(event, selector) {
					if (event.target.textContent === selector.getAttribute("name")) {
						selector.style.opacity = "1";
					}
				}

				function hideInfo(event, selector) {
					if (event.target.textContent === selector.getAttribute("name")) {
						selector.style.opacity = "0";
					}
				}

				mark.addEventListener("mouseover", (event) => displayInfo(event, targetSelector), true);
				mark.addEventListener("mouseout", (event) => hideInfo(event, targetSelector), true);
			});
		});
	};

	return (
		<Wrapper>
			<Container>
				{/* Sliders area */}
				{showOptions && (
					<SelectOptionsWrapper isVisible={optionsVisible} id="options-box">
						{SlidersData.map((slider, index) => (
							<SliderWrapper
								key={index}
								onMouseEnter={(e) =>
									handleMouseOver(e, Object.values(slider.marks), slider.className)
								}
							>
								<Name>{slider.title}</Name>
								<SliderContainer className={slider.className} icon={slider.icon}>
									<Slider
										dots
										min={0}
										max={Object.keys(slider.marks).length - 1}
										marks={slider.marks}
										step={1}
										onChange={(value) => handleSlider(value, slider.className)}
										defaultValue={slider.defaultMark}
										value={
											slider.className === "sliderA"
												? valueA
												: slider.className === "sliderB"
												? valueB
												: slider.className === "sliderC"
												? valueC
												: slider.className === "sliderD"
												? valueD
												: slider.className === "sliderE"
												? valueE
												: valueF
										}
									/>
								</SliderContainer>
								<InfoContainer className={slider.className}>
									{slider.info.map((info, index) => (
										<Info key={index} name={info.for}>
											{info.text}
										</Info>
									))}
								</InfoContainer>
							</SliderWrapper>
						))}
						<CtaWrapper>
							<div></div>
							<ButtonWrapper>
								<Button
									onClick={handleGetEstimateSubmit}
									text="Get an estimate"
									bkg="dark"
									border="dark"
									id="btn_get_an_estimate" // used to identify the button for GTM
								/>
							</ButtonWrapper>
						</CtaWrapper>
					</SelectOptionsWrapper>
				)}
			</Container>
			{/* Estimate result & from area */}
			{showResult && (
				<ResultWrapper isVisible={resultVisible} id="result-box">
					<ResultOuterContainer>
						<ResultContainer>
							<ResultSummary ref={refResultSummary}>
								<ResultTitle>Your Personalised estimate</ResultTitle>
								{SlidersData.map((slider, index) => (
									<ResultSummaryGrid key={index}>
										<ResultSummaryItem>{slider.title}</ResultSummaryItem>
										<ResultSummarySelected>
											{slider.className === "sliderA" && Object.values(slider.marks)[valueA]}
											{slider.className === "sliderB" && Object.values(slider.marks)[valueB]}
											{slider.className === "sliderC" && Object.values(slider.marks)[valueC]}
											{slider.className === "sliderD" && Object.values(slider.marks)[valueD]}
											{slider.className === "sliderE" && Object.values(slider.marks)[valueE]}
											{slider.className === "sliderF" && Object.values(slider.marks)[valueF]}
											<InfoBtn />
											<InfoBox>
												{slider.className === "sliderA" &&
													Object.keys(slider.marks)[valueA] &&
													slider.info[valueA].text}
												{slider.className === "sliderB" &&
													Object.keys(slider.marks)[valueB] &&
													slider.info[valueB].text}
												{slider.className === "sliderC" &&
													Object.keys(slider.marks)[valueC] &&
													slider.info[valueC].text}
												{slider.className === "sliderD" &&
													Object.keys(slider.marks)[valueD] &&
													slider.info[valueD].text}
												{slider.className === "sliderE" &&
													Object.keys(slider.marks)[valueE] &&
													slider.info[valueE].text}
												{slider.className === "sliderF" &&
													Object.keys(slider.marks)[valueF] &&
													slider.info[valueF].text}
											</InfoBox>
										</ResultSummarySelected>
									</ResultSummaryGrid>
								))}
							</ResultSummary>
							<ResultEstimate>
								<ResultEstimateBox>
									<div>
										<ResultLargeText>
											We estimate that your website would cost somewhere in between{" "}
											<b>{currencySymbol + numberWithCommas(lowEstimate)}</b> and{" "}
											<b>{currencySymbol + numberWithCommas(baseEstimate)}</b>
											<CurrencyToggle className="can-toggle">
												<input id="currencyCheck" type="checkbox" onChange={handleSetCurrency} />
												<label htmlFor="currencyCheck">
													<span
														className="can-toggle__switch"
														data-checked="$"
														data-unchecked="£"
													></span>
												</label>
											</CurrencyToggle>
										</ResultLargeText>
										<ResultSmallText>
											Please fill in the form below and get a more precise quote for your project.
											If there is an option you couldn't find in our calculator, rest assured it's
											all possible and quite likely already included in the estimate above. We look
											forward to hearing from you and let's get started!
										</ResultSmallText>
									</div>
								</ResultEstimateBox>
							</ResultEstimate>
						</ResultContainer>
					</ResultOuterContainer>
					<ContactFormB
						returnAction={handleGetEstimateReset}
						isVisible={resultVisible}
						quoteData={quoteData}
					/>
				</ResultWrapper>
			)}
		</Wrapper>
	);
}
