// Get price estimate slider handle icons
import Pages from "../../assets/svg/quote-icon-pages.svg";
import Palette from "../../assets/svg/quote-icon-palette.svg";
import Design from "../../assets/svg/quote-icon-design.svg";
import Seo from "../../assets/svg/quote-icon-seo.svg";
import Backend from "../../assets/svg/quote-icon-backend.svg";
import Ecommerce from "../../assets/svg/quote-icon-ecommerce.svg";

// PAGES

// Get Price Estimate page
export const price_estimate_sliders_eng = [
	{
		title: "Number of pages (excl. product pages in ecommerce sites)",
		className: "sliderA",
		marks: {
			0: "1-10",
			1: "11-25",
			2: "25-50",
			3: "50+",
		},
		defaultMark: 0,
		icon: Pages,
		info: [
			{
				for: "1-10",
				text: "Presentational site with a handful of pages, including contact form, gallery, slideshows, maps and other essential features.",
			},
			{
				for: "11-25",
				text: "Presentational site with a reasonable number of pages, including contact form, gallery, slideshows, maps and other essential features.",
			},
			{
				for: "25-50",
				text: "Relatively large site with many pages, including contact form, gallery, slideshows, maps and other essential features.",
			},
			{
				for: "50+",
				text: "Large site with many unique pages and design features, including contact form, gallery, slideshows, maps and other features.",
			},
		],
	},
	{
		title: "Design",
		className: "sliderB",
		marks: {
			0: "Not needed",
			1: "Simple yet modern",
			2: "World class",
		},
		defaultMark: 1,
		icon: Palette,
		info: [
			{
				for: "Not needed",
				text: "You have already prepared a brilliant design and we just need to develop a website based on it.",
			},
			{
				for: "Simple yet modern",
				text: "Beautiful custom web design with a simple yet modern look, including some cool icon and button animations.",
			},
			{
				for: "World class",
				text: "Top class design with advanced animations and cool 3D effects if required. Advanced user testing and feedback will be provided.",
			},
		],
	},
	{
		title: "Professionally written content",
		className: "sliderC",
		marks: {
			0: "Not needed",
			1: "1-5",
			2: "6-10",
			3: "11-25",
			4: "25+",
		},
		defaultMark: 0,
		icon: Design,
		info: [
			{
				for: "Not needed",
				text: "You will provide us with all the text content.",
			},
			{
				for: "1-5",
				text: "Professionally written content for a few essential pages of your site.",
			},
			{
				for: "6-10",
				text: "Professionally written content for a few essential pages of your site.",
			},
			{
				for: "11-25",
				text: "Professionally written content for a few essential pages of your site.",
			},
			{
				for: "25+",
				text: "Professionally written content for, presumably, all pages of your website.",
			},
		],
	},
	{
		title: "SEO",
		className: "sliderD",
		marks: {
			0: "Basic SEO",
			1: "Basic SEO + 5 Keywords",
			2: "Basic SEO + 10 Keywords",
			3: "Basic SEO + 20 Keywords",
		},
		defaultMark: 0,
		icon: Seo,
		info: [
			{
				for: "Basic SEO",
				text: "Basic on-site SEO, including title, description, alt tag generation by our in-house SEO professionals.",
			},
			{
				for: "Basic SEO + 5 Keywords",
				text: "Our SEO experts will review each page on your site and adjust the content accordingly to target up to 5 keywords of your choice.",
			},
			{
				for: "Basic SEO + 10 Keywords",
				text: "Our SEO experts will review each page on your site and adjust the content accordingly to target 10 keywords of your choice.",
			},
			{
				for: "Basic SEO + 20 Keywords",
				text: "Our SEO experts will review each page on your site and adjust the content accordingly to target 20 keywords of your choice.",
			},
		],
	},
	{
		title: "Custom Backend (CMS)",
		className: "sliderE",
		marks: {
			0: "Not needed",
			1: "Basic",
			2: "Advanced",
			3: "Full",
		},
		defaultMark: 0,
		icon: Backend,
		info: [
			{
				for: "Not needed",
				text: "Use an already existing CMS, such as Shopify for ecommerce or Wordpress for presentational sites.",
			},
			{
				for: "Basic",
				text: "Update your blog, portfolio or other essential content yourself with a custom content management system.",
			},
			{
				for: "Advanced",
				text: "Mange event listings, real estate listings, appointment booking, and more with a custom content management system.",
			},
			{
				for: "Full",
				text: "Manage the entire content of your website yourself with a custom content management system taylored specifically to your needs.",
			},
		],
	},
	{
		title: "E-commerce",
		className: "sliderF",
		marks: {
			0: "Not needed",
			1: "Shopify",
			2: "Custom",
		},
		defaultMark: 0,
		icon: Ecommerce,
		info: [
			{
				for: "Not needed",
				text: "The site isn't planning to sell any products online.",
			},
			{
				for: "Shopify",
				text: "Small to medium size shop with Shopify backend and a handful of cool custom features.",
			},
			{
				for: "Custom",
				text: "Large online store with thausands of products and many categories. Use a fully customisable backend and frontend or a custom Shopify theme with advanced features.",
			},
		],
	},
];

// Why Us page
export const why_us_en = {
	explained: [
		{
			title: "We are performance focused",
			text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
		},
		{
			title: "We are experts in our field",
			text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
		},
		{
			title: "We make your site covert",
			text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
		},
		{
			title: "Partnering with other agencies",
			text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
		},
	],
};
