// Dependances
import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Page components
import Nav from "../components/fragments/Nav";
import SectionHero from "../components/fragments/SectionHero";
import Section from "../components/fragments/Section";
import Flowchart from "../components/fragments/Flowchart";
import ServicesExplained from "../components/fragments/ServicesExplained";
import ContactFormA from "../components/fragments/ContactFormA";
import OurWorkCompact from "../components/fragments/OurWorkCompact";
import PageBanner from "../components/fragments/PageBanner";
import OurWorkGallery from "../components/fragments/OurWorkGallery";

// Text content & SEO data
import { home_en } from "../data/page_content/en/pages/home_en";
import { our_work_en } from "../data/page_content/en/pages/our_work_en";
import { device } from "../components/styles/Layout";

const Img = styled.img`
	width: 90%;
	margin: 3rem 0 0 3rem;
	mix-blend-mode: luminosity;
	@media ${device.md} {
		width: 75%;
		margin: 5.8rem 0 0 3rem;
	}
	@media ${device.sm} {
		width: 75%;
		margin: 4rem 0 0 3rem;
	}
`;

export default function Home() {
	useEffect(() => {
		window.scrollTo(0, 0);
		console.log(home_en.seo.description);
	}, []);

	return (
		<>
			<Helmet>
				<title>{home_en.seo.title}</title>
				<meta name="description" content={home_en.seo.description} />
			</Helmet>
			<Nav />
			<SectionHero />
			<Section
				title={home_en.top_section.title}
				subtitle={home_en.top_section.subtitle}
				id="whatwedo"
			/>
			<Flowchart />
			<ServicesExplained />

			<PageBanner
				title={our_work_en.title}
				subtitle={our_work_en.subtitle}
				img={<Img src={our_work_en.header_img} />}
			/>
			<OurWorkGallery mobileMarginBottom="-10rem" />
			<ContactFormA />
		</>
	);
}
