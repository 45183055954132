// Header image
import PoundSymbolImg from "../../../../assets/img/banner-img-pound-symbol.png";

export const get_estimate_en = {
	seo: {
		title: "Get a Price Estimate | New Window Development",
		description:
			"Get an instant price estimate for your new web development project and get it emailed to you.",
	},
	header_img: PoundSymbolImg,
	title: "Instant price estimate",
	subtitle:
		"Get an instant price estimate for your new web development project and get it emailed to you.",
};
