import React from "react";
import styled, { keyframes } from "styled-components";

import TopBg from "../../assets/img/programming-on-imac.jpg";
import Samsung from "../../assets/img/samsung-s21-frame.png";
import MifoldScrn from "../../assets/img/mifold-scrn.jpg";
import ClearbitScrn from "../../assets/img/clearbit-scrn.jpg";
import BoomerangScrn from "../../assets/img/boomerang-scrn.jpg";
import ChocolateScrn from "../../assets/img/chocolate-scrn.jpg";
import PokePayScrn from "../../assets/img/paypay-scrn.jpg";
import MorpheeScrn from "../../assets/img/morphee-scrn.jpg";
import Laptops from "../../assets/img/laptops.png";

import { ReactComponent as LogoStripes } from "../../assets/svg/nwd-logo-stripes.svg";
import { ReactComponent as ChevronDownWhite } from "../../assets/svg/chevron-down.svg";

const phoneScroll = keyframes`
    0% {
        margin-top: 0px;
    }
    5% {
        margin-top: -450px;
    }
    8% {
        margin-top: -450px;
    }
    13% {
        margin-top:-1050px;
    }
    15% {
        margin-top:-1050px;
    }
    20% {
        margin-top:-1650px;
    }
    23% {
        margin-top:-1650px;
    }
    28% {
        margin-top:-2060px;
    }
    30% {
        margin-top:-2060px;
    }
    35% {
        margin-top:-1650px;
    }
    37% {
        margin-top:-1650px;
    }
    43% {
        margin-top:-1050px;
    }
    45% {
        margin-top:-1050px;
    }
    50% {
        margin-top:-450px;
    }
    52% {
        margin-top:-450px;
    }
    58% {
        margin-top:0px;
    }
`;

const Wrapper = styled.div`
	animation: ${phoneScroll} 50s ease-in-out 3s infinite;
	animation-play-state: running;
	&:hover {
		animation-play-state: paused;
	}
`;

const TopSection = styled.div`
	background: url(${TopBg}) no-repeat center center;
	background-size: cover;
	width: 100%;
	height: 450px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const TopContent = styled.div`
	padding: 4rem 1rem 1rem 1rem;
	text-align: center;
`;

const Section = styled.div`
	background: ${(props) => props.bg};
	height: ${(props) => (props.short ? "410px" : "600px")};
	width: 100%;
	overflow: hidden;
	position: relative;
`;

const TitleOne = styled.h2`
	color: ${(props) => props.color};
	padding-top: 45px;
	font-size: 1rem;
	text-align: center;
	padding-bottom: 15px;
`;

const TitleTwo = styled.p`
	text-align: center;
	font-size: 2rem;
`;

const PhoneFrame = styled.img`
	width: 260px;
	margin-top: 10px;
	position: absolute;
	z-index: 0;
`;

const FrameContent = styled.img`
	width: 216px;
	margin-top: 42px;
	margin-left: 23px;
	position: absolute;
	opacity: 1;
	border-radius: 23px;
`;

const LaptopsImg = styled.img`
	width: 400px;
	margin-left: -50px;
	margin-top: -25px;
`;

const Logo = styled(LogoStripes)`
	width: 20px;
	position: absolute;
	margin-top: -390px;
	margin-left: -200px;
`;

const MainTitle = styled.h1`
	margin-bottom: 2rem;
	text-align: center;
	opacity: 0.9;
`;

const MainSubtitle = styled.p`
	font-size: 16px;
	text-align: center;
	line-height: 1.5;
	opacity: 0.9;
`;

const ChevronDown = styled(ChevronDownWhite)`
	margin-top: 2rem;
`;

export default function PhoneContent() {
	return (
		<Wrapper>
			<TopSection>
				<Logo />
				<TopContent>
					<MainTitle>Ready to code</MainTitle>
					<MainSubtitle>
						From Strategy and design to full web development and marketing support
					</MainSubtitle>
					<ChevronDown />
				</TopContent>
			</TopSection>
			{/* 
			<Section bg="#01A23F">
				<TitleOne color="#006427">startups & shopping sites</TitleOne>
				<TitleTwo>PokePay</TitleTwo>
				<PhoneFrame src={Samsung} />
				<FrameContent src={PokePayScrn} />
			</Section> */}

			<Section bg="#369896">
				<TitleOne color="#194847">ecommerce sites</TitleOne>
				<TitleTwo>Morphee</TitleTwo>
				<PhoneFrame src={Samsung} />
				<FrameContent src={MorpheeScrn} />
			</Section>

			<Section bg="#28adff">
				<TitleOne color="#284bad">startups & services</TitleOne>
				<TitleTwo>Clearbit</TitleTwo>
				<PhoneFrame src={Samsung} />
				<FrameContent src={ClearbitScrn} />
			</Section>
			{/* 
			<Section bg="#0fa690">
				<TitleOne color="#0f5738">startup & shopping sites</TitleOne>
				<TitleTwo>Mifold</TitleTwo>
				<PhoneFrame src={Samsung} />
				<FrameContent src={MifoldScrn} />
			</Section> */}

			{/* <Section bg="#E7455C">
				<TitleOne color="#6C1226">boutique sites</TitleOne>
				<TitleTwo>Simply Chocolate</TitleTwo>
				<PhoneFrame src={Samsung} />
				<FrameContent src={ChocolateScrn} />
			</Section> */}

			<Section bg="#f0846d">
				<TitleOne color="#9c432c">fun & boutique sites</TitleOne>
				<TitleTwo>Boomerang Booth</TitleTwo>
				<PhoneFrame src={Samsung} />
				<FrameContent src={BoomerangScrn} />
			</Section>

			<Section bg="#ffd700" short>
				<TitleOne color="#544a07">desktop focused sites</TitleOne>
				<LaptopsImg src={Laptops} />
			</Section>
		</Wrapper>
	);
}
