import React from "react";
import styled, { keyframes } from "styled-components";

import { ReactComponent as IPhoneFrame } from "../../assets/svg/iphone-frame.svg";
import { device } from "../styles/Layout";

const phoneScroll = keyframes`
    from {
        margin-top: 0;
    }

    to {
        margin-top: -400%;
    }
`;

const PhoneWrapper = styled.div`
	transform: scale(1);
	transition: 0.5s;
	padding-bottom: 1rem;
	clip-path: polygon(
		0 0,
		48% 0,
		93% 100%,
		23% 100%,
		0% 38%
	); // Need to clip path so hover doesn't go too far away from the phone frame

	@media ${device.lg} {
		clip-path: none;
	}

	/* &:hover {
		transform: scale(1.05);
		transition: 0.5s;
	} */
`;

const PhoneFrame = styled(IPhoneFrame)`
	height: 37rem;
	overflow: overlay;
	& path.frame {
		filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.5));
	}
`;

const Perspective = styled.div`
	perspective: 2900px;
	perspective-origin: 49% -7%;
`;
const PhoneContentBox = styled.div`
	width: 261px;
	height: 598px;
	background: black;
	position: absolute;
	opacity: 1;
	overflow: hidden;
	margin-left: 146px;
	margin-top: -25px;
	border-radius: 33px;
	border-bottom-left-radius: 35px;

	transform: scale3d(1, 1, 1) rotateX(35.8deg) rotateY(24deg) rotateZ(-36deg)
		translate3d(0px, 0px, 0px) skew(2deg, -0.1deg);

	// The imposed image gets slightly misaligned when viewed from smaller devices, so the image needs to be readjusted slightly
	@media ${device.lg} {
		width: 263px;
		margin-top: -26px;
	}
	@media ${device.md} {
		width: 271px;
		margin-left: 140px;
		transform: scale3d(1, 1, 1) rotateX(35.8deg) rotateY(24deg) rotateZ(325deg)
			translate3d(0px, 0px, 0px) skew(2deg, -0.1deg);
	}
	/* overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	} */
`;

const PhoneCameraElement = styled.div`
	height: 27px;
	width: 109px;
	background: black;
	position: absolute;
	margin-left: 75px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	z-index: 10;
`;

const PhoneContent = styled.div`
	/* animation: ${phoneScroll} 7s ease-in-out infinite alternate;
	animation-play-state: running;
	&:hover {
		animation-play-state: paused;
	} */
`;

export default function Phone({ children }) {
	return (
		<PhoneWrapper>
			<Perspective>
				<PhoneContentBox>
					<PhoneCameraElement />
					<PhoneContent>{children}</PhoneContent>
				</PhoneContentBox>
			</Perspective>
			<PhoneFrame />
		</PhoneWrapper>
	);
}
