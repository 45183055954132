import React, { useEffect } from "react";
import styled from "styled-components";
import BasicPage from "../components/fragments/BasicPage";

import Nav from "../components/fragments/Nav";
import PageBanner from "../components/fragments/PageBanner";

const Title = styled.p`
	font-size: 16px;
	color: ${(props) => props.theme.colours.black};
	text-transform: uppercase;
	font-weight: 600;
	padding: 3rem 0 1rem 0;
`;

const Text = styled.p`
	font-size: 16px;
	color: ${(props) => props.theme.colours.black};
	font-weight: 300;
	padding-top: 1rem;
	max-width: 65rem;
`;

export default function TermsConditions() {
	useEffect(() => {
		window.scrollTo(0, 0);
	});

	return (
		<>
			<Nav type="dark" />
			<PageBanner title="Our Standard Terms and Conditions" />
			<BasicPage>
				<Title>1. Introduction</Title>
				<Text>
					Thank You for choosing New Window Development. All agreements are made solely between the
					Client, “You” (the party to whom the Company provides services) and "New Window Marketing"
					representing the New Window Development brand.
				</Text>

				<Title>2. Project Definition</Title>
				<Text>
					These Terms of Business should be read in conjunction with the Proposal sent to You at the
					same time (“Project Definition”) which incorporates these Terms of Business. The Project
					Definition sets out, amongst other things, the services New Window Marketing will provide
					to You (“Works”) and the fees which will be charged (“Fees”). New Window Marketing aims to
					offer a friendly and efficient service and will exercise reasonable skill, care and
					diligence in carrying out the Works. These Terms of Business come into force with
					immediate effect upon Your first instructions or order and these Terms of Business will
					apply to any current and future instructions or commission that You are kind enough to
					give New Window Marketing and to any specific Works referred to in the current or a future
					Project Definition.
				</Text>
				<Text>
					These Terms of Business and the Project Definition constitute the entire agreement between
					New Window Marketing and You regarding the Works. Any additional work not specified in the
					Project Definition must be authorised by a written change order signed by You and New
					Window Marketing and may result in changes to Fees and Disbursements.
				</Text>

				<Title>3. Your Brief</Title>
				<Text>
					You, as New Window Marketing’s client, represent, undertake and warrant to New Window
					Marketing that, to the best of Your knowledge: (i) any instructions given and materials
					supplied by You will be accurate and complete and will not cause New Window Marketing to
					infringe the rights (including intellectual property rights) of any third party or the
					laws or regulations (including data protection legislation) of any country and (ii) You
					have complied and will comply with the Minimum Client Requirements set out in the Project
					Definition.
				</Text>
				<Text>
					New Window Marketing and You agree to work together to complete the Works in a timely
					manner. New Window Marketing agrees to work expeditiously to complete the Works in line
					with the Timeframe agreed in the Project Definition however this Timeframe is dependent on
					New Window Marketing being provided with Your continued cooperation in order to approve
					Works at various stages. New Window Marketing cannot be held responsible for any loss
					incurred where You (or any third party instructed by You) have not provided clear and
					complete instructions within any given time limit and with sufficient notice.
				</Text>
				<Text>
					If You request changes to the Works New Window Marketing reserves the right to revise the
					Project Definition. If the Works are shortened, delayed, cancelled or terminated early by
					You, the final invoice will include the balance of the Fees for providing the Works plus
					any reasonable costs and disbursements incurred by New Window Marketing due to Your acts
					or omissions. For example, You shall be liable for the costs and disbursements incurred by
					New Window Marketing for prebooked fieldwork, which is delayed, not used or not fully
					used.
				</Text>

				<Text>
					New Window Marketing recommends that You retain copies and back-ups of all materials, data
					or information provided to and from New Window Marketing.
				</Text>

				<Title>4. Sub-contracting</Title>
				<Text>
					New Window Marketing reserves the right to assign sub-contractors to this project to
					ensure the right fit for the job as well as timely completion. New Window Marketing shall
					be the primary obligor in relation to such sub-contractors.
				</Text>
				<Text>
					New Window Marketing is only responsible for the quality of the service provided by
					sub-contractors if those sub-contractors have been selected and paid for directly by New
					Window Marketing. If You designate a specific sub-contractor, then New Window Marketing
					shall not be responsible for the accuracy, completeness or quality of the work of that
					sub-contractor.
				</Text>

				<Title>5. Fees and Disbursements</Title>
				<Text>
					New Window Marketing’s Fees are exclusive of VAT which must therefore be added at the
					current rate. This will be indicated on invoices. New Window Marketing’s Fees are also
					exclusive of disbursements (including travel), which are therefore charged in addition.
					Disbursements include payments made or incurred on Your behalf as well as miscellaneous
					office expenses such as printing, travelling and out of pocket expenses. Mileage will be
					charged in line with HMRC guidelines.
				</Text>
				<Text>
					Payment of Fees for Works shall be on delivery of the Works. Any Works provided over more
					than one calendar month will be subject to invoicing at the start of each calendar month
					with the final invoice raised on delivery of the final Works. All invoices shall be due on
					the invoice date and shall be subject to payment within 14 days.
				</Text>
				<Text>
					If You dispute, deem incorrect or inaccurate any invoice, contract or agreement New Window
					Marketing must be notified, in writing, within 5 days of receipt. Failure to do so renders
					any future claim inadmissible.
				</Text>
				<Text>
					In order for New Window Marketing to remain in business, payments must be made promptly. A
					twenty pound (£20.00) additional fee will be incurred if payment is not received within
					ten (10) days of the due date. If an amount remains delinquent thirty (30) days after its
					due date, an additional five per cent (5%) interest will be added for each month of
					delinquency from the date of the invoice. New Window Marketing reserves the right to
					suspend work without notice until Final Payment is made. Where debt collection or
					proceedings prove necessary, You agree to pay all fees and costs incurred by that process.
				</Text>

				<Title>6. Confidentiality and Intellectual Property</Title>
				<Text>
					All information You provide New Window Marketing will be regarded as confidential to the
					extent that it is not in the public domain, but You should advise New Window Marketing if
					any information is particularly sensitive. New Window Marketing will not divulge any
					confidential information obtained from You otherwise than in accordance with Your
					instructions.
				</Text>
				<Text>
					You unconditionally guarantee that any elements of text, graphics, photos, designs,
					trademarks, or other artwork (including all associated intellectual property) furnished by
					You (or on Your behalf) to New Window Marketing for inclusion in the Works are owned by
					You, or that You have permission from the rightful owner to use each of these elements,
					and will hold harmless, indemnify, and defend New Window Marketing and its subcontractors
					from any claim or suit arising from the use of such elements furnished by You.
				</Text>
				<Text>
					Intellectual property rights (including but not limited to copyright and database rights)
					in all Works are owned by New Window Marketing in the first instance. All intellectual
					property rights owned by New Window Marketing in finished, approved Works created
					specifically for You will be assigned to You automatically on receipt by New Window
					Marketing of the Final Payment of Fees in cleared funds. All Intellectual property rights
					in third party materials are owned by those third parties and will be licensed to You on
					the terms set out in the Project Definition.
				</Text>
				<Text>
					New Window Marketing and its sub-contractors retain the right to display Works, graphics
					and other design elements as examples of their work in their respective portfolios
					(including online).
				</Text>

				<Title>7. Termination</Title>
				<Text>
					Either party may terminate this engagement upon one months notice. Notice must be provided
					in writing. New Window Marketing will use its reasonable endeavours to complete any Works
					in progress and New Window Marketing will remain entitled to payment for completion of
					those Works.
				</Text>
				<Text>
					Either party may terminate this engagement immediately for a material breach by the other
					which is incapable of remedy or, if capable of remedy, is not remedied within 45 days of
					notification being given to the defaulting party.
				</Text>
				<Text>
					New Window Marketing reserves the right, for good reason and upon reasonable notice, to
					terminate New Window Marketing’s engagement without further liability on New Window
					Marketing. This will be confirmed to You in writing, if requested. “Good reasons” include
					if You: do not give New Window Marketing instructions within a reasonable period of time;
					do not pay promptly any request for money on account; or do not pay a bill within the due
					period.
				</Text>

				<Title>8. Warranties and Liability</Title>
				<Text>
					All conclusions, recommendations, forecasts, reports, letters or other communications,
					whether oral or written, provided by New Window Marketing (together, the
					“Recommendations”) are made in good faith and on the basis of information available to New
					Window Marketing at the time whether from You or from information in the public domain and
					the validity of such Recommendations will depend, amongst other factors, on Your effective
					cooperation and the quality of the information made available by You. No warranty or
					representation, express or implied, is given as to the Recommendations provided by New
					Window Marketing and You shall be responsible for the proper adaptation of such
					Recommendations to Your own circumstances.
				</Text>
				<Text>
					All Recommendations given by New Window Marketing are for Your use only and are not to be
					disclosed or reproduced to third parties without the prior written consent of New Window
					Marketing.
				</Text>
				<Text>
					New Window Marketing shall not be liable for any consequential or indirect loss suffered
					by You whether such loss arises from a breach of contract or tort or in any other way
					(including losses arising indirectly or consequentially from New Window Marketing’s
					negligence). You shall not be liable for any consequential or indirect loss suffered by
					New Window Marketing whether such loss arises from a breach of contract or tort or in any
					other way. Compensation for any direct losses arising under this agreement shall be
					limited to the value of the current Project Definition.
				</Text>
				<Text>
					You and New Window Marketing acknowledge that the Fees payable under these Terms of
					Business have been determined on the basis of these limitations of liability and reflect
					the division of risks set out in these Terms of Business and that, accordingly, the
					division of risk is agreed by the parties to be fair and reasonable in the circumstances.
				</Text>
				<Text>
					If any provision of this agreement shall be unlawful, void, or for any reason
					unenforceable, then that provision shall be deemed severable from this agreement and shall
					not affect the validity and enforceability of any remaining provisions.
				</Text>

				<Title>9. Data Protection</Title>
				<Text>
					Details of the individual to whom these Terms of Business are sent, together (where
					relevant) with details of other key individuals within Your organisation supplied to New
					Window Marketing from time to time, will be entered onto New Window Marketing’s client
					database. New Window Marketing will use these details primarily to provide You with the
					Works. In addition, New Window Marketing may use these details to contact You by post,
					telephone, e-mail or fax for marketing purposes or to make searches with credit reference
					agencies. New Window Marketing may also disclose these details for these purposes to any
					agents, associates, advisors or contractors that we agree New Window Marketing should work
					with on Your behalf.
				</Text>
				<Text>
					It is Your responsibility to ensure that the communication of any sensitive or personal
					data by You or Your agents to New Window Marketing does not breach the rights of any data
					subjects, and that the subsequent use of such data by New Window Marketing pursuant to the
					Project Definition does not breach any data protection regulations or legislation. You
					hereby indemnify New Window Marketing in this respect.
				</Text>

				<Title>10. Electronic Communications</Title>
				<Text>
					During the course of this matter, we may wish to communicate electronically with one
					another. The electronic transmission of information cannot be guaranteed to be secure or
					error-free, as it will be transmitted over a public network, and such information could be
					intercepted, corrupted, lost, destroyed, arrive late or incomplete, or otherwise be
					adversely affected or unsafe for use.
				</Text>
				<Text>
					We each agree to use reasonable procedures to check for the most recently known viruses
					before sending and receiving information electronically, but we each recognise that such
					procedures cannot be a guarantee that transmissions will be virus-free. We shall each be
					responsible for protecting our own interests in relation to electronic communications.
					Neither of us shall be liable to the other on any basis, whether in contract, tort
					(including negligence) or otherwise, in respect of any damage or loss arising from or in
					connection with the electronic communication of information between us.
				</Text>

				<Title>11. General</Title>
				<Text>
					A person who is not a party to the agreement between us has no right under the Contracts
					(Rights of Third Parties) Act 1999 to enforce any term of the engagement.
				</Text>
				<Text>
					The rights and remedies available to New Window Marketing by virtue of these Terms of
					Business are without prejudice to any other rights or remedies available to New Window
					Marketing. Any failure by New Window Marketing to exercise or delay by New Window
					Marketing in exercising a right or remedy provided by these Terms of Business or by law
					does not constitute a waiver of the right or remedy, or a waiver of other rights or
					remedies.
				</Text>
				<Text>
					The contract between us is on the basis of these Terms of Business and is subject to
					English Law and the exclusive jurisdiction of the English Courts.
				</Text>
				<Text>
					We look forward to working with You. Your continuing instructions will amount to an
					acceptance of these Terms of Business.
				</Text>
			</BasicPage>
		</>
	);
}
