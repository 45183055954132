import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";

import WAVES from "vanta/dist/vanta.waves.min";
import NET from "vanta/dist/vanta.net.min";

import { Container, device } from "../styles/Layout";
import Button from "../styles/Button";
import Phone from "./Phone";
import PhoneContent from "./PhoneContent";
import Strip from "./Strip";

const bkgFadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

// Used only in desktop viewport
const slideFromBottom = keyframes`
    from {
        opacity:0;
        transform: translateY(20px);
    }
    to {
        opacity:1;
        transform: translateY(0);
    }
`;

// Used only in mobile viewport. Additional properties are added if viewed from a mobile device
const slideFromBottom_scaled = keyframes`
    from {
        opacity:0;
        transform: translateY(20px) translateX(-16%) scale(.6);
    }
    to {
        opacity:1;
        transform: translateY(0) translateX(-16%) scale(.6);
    }
`;

// Used only in desktop viewport
const slideFromTop = keyframes`
    from {
        opacity:0;
        transform: translateY(-20px);
    }
    to {
        opacity:1;
        transform: translateY(0);
    }
`;

// Used only in mobile viewport. Scale property is added if viewed from a mobile device
const slideFromTop_scaled = keyframes`
    from {
        opacity:0;
        transform: translateY(-20px) scale(.9);
    }
    to {
        opacity:1;
        transform: translateY(0) scale(.9);
    }
`;

const Wrapper = styled.section`
	height: 100vh;
	width: 100vw;
	background: ${(props) => props.theme.colours.gradientA};
	color: white;
	@media ${device.xl} {
		height: calc(100vh + 12rem);
	}
	@media ${device.lg} {
		height: calc(100vh);
	}
`;

const PanelWrapper = styled.div`
	display: flex;
	@media ${device.xl} {
		display: block;
	}
	width: 100%;
	min-height: 750px;
`;

const HeroContainer = styled(Container)`
	position: absolute;
	display: flex;
	left: 0;
	right: 0;
	z-index: 2;
	height: 100vh;
	align-items: center;
`;

const WavesBackground = styled.div`
	height: 100%;
	animation: ${bkgFadeIn} 1s ease-in-out;
	mix-blend-mode: ${(props) =>
		props.safari
			? "overlay"
			: "luminosity"}; // Safari doesn't seem to support luminosity very well, so use overlay as a fallback instead which looks OK, but not as good as luminosity
`;

const LeftPanel = styled.div`
	flex: 1;
	align-self: center;
	padding-top: 3rem;

	animation: ${slideFromBottom} 1s ease-in-out;

	@media ${device.xxl} {
		flex: 1.2;
	}
	@media ${device.xl} {
		margin: 15rem 0 0 25%;
	}
	@media ${device.lg} {
		margin: 0 0 0 17%;
	}
	@media ${device.md} {
		margin: 0 0 0 9%;
	}
	@media ${device.sm} {
		margin: -3rem 0 0 0;
		animation: ${slideFromBottom_scaled} 1s ease-in-out forwards;
	}
`;

const RightPanel = styled.div`
	flex: 1;
	align-self: center;

	animation: ${slideFromTop} 1s ease-in-out forwards;

	@media ${device.xxl} {
		animation: ${slideFromTop_scaled} 1s ease-in-out forwards;
	}

	@media ${device.xl} {
		margin-top: 2rem;
	}

	@media ${device.md} {
		margin-top: -6rem;
	}
`;

const ButtonWrap = styled.div`
	display: flex;
	& a {
		margin-right: 1rem;
	}

	@media ${device.xl} {
		justify-content: center;
	}
`;

const TitleSmall = styled.p`
	padding-bottom: 1rem;
	filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2));
	@media ${device.xl} {
		text-align: center;
	}
`;

const TitleMain = styled.h1`
	padding-bottom: 2rem;
	filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2));
	@media ${device.xl} {
		text-align: center;
	}
`;

const CalloutText = styled.p`
	font-weight: ${(props) => (props.caption ? "500" : "300")};
	font-family: ${(props) => (props.caption ? "Bai Jamjuree" : "Open Sans")};
	filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2));
`;

const CalloutTextGrid = styled.div`
	display: grid;
	grid-template-columns: 0.5fr 1fr;
	@media ${device.xxl} {
		grid-template-columns: 0.65fr 1fr;
	}
	@media ${device.xl} {
		display: none;
	}
	grid-row-gap: 2rem;
	padding-bottom: 2rem;
`;

const BehindPhoneGraphics = styled.div`
	position: absolute;
`;

export default function SectionHero({ props }) {
	// Waves effect
	const [vantaEffect, setVantaEffect] = useState(0);
	const wavesBkg = useRef(null);

	// Detect browser
	const [isSafari, setIsSafari] = useState(false);

	useEffect(() => {
		if (navigator.userAgent.match(/AppleWebKit/) && !navigator.userAgent.match(/Chrome/)) {
			setIsSafari(true);
		} else {
			setIsSafari(false);
		}

		if (!vantaEffect) {
			// setVantaEffect(
			// 	WAVES({
			// 		el: wavesBkg.current,
			// 		mouseControls: true,
			// 		touchControls: true,
			// 		gyroControls: false,
			// 		minHeight: 600,
			// 		minWidth: 200.0,
			// 		scale: 0.75,
			// 		scaleMobile: 0.75,
			// 		color: 0x414141,
			// 		shininess: 133.0,
			// 		waveHeight: 15.5,
			// 		waveSpeed: 0.75,
			// 		zoom: 1,
			// 	})
			// );
			setVantaEffect(
				NET({
					el: wavesBkg.current,
					mouseControls: true,
					touchControls: true,
					gyroControls: false,
					minHeight: 200.0,
					minWidth: 200.0,
					scale: 1.0,
					scaleMobile: 1.0,
					color: 0xb1b1b1,
					backgroundColor: 0x464646,
				})
			);
		}

		function handleResize() {
			vantaEffect.resize();
		}

		window.addEventListener("resize", handleResize);

		return () => {
			if (vantaEffect) vantaEffect.destroy();
		};
	}, [vantaEffect]);

	const handleScrollTo = (e) => {
		e.preventDefault();

		const WhatWeDoSection = document.querySelector("#whatwedo");

		// scrollIntoView with options doesn't work in Safari, so use a different method for Safari. A polyfill for this is needed unfortunately.
		if (isSafari) {
			window.scrollTo({
				top: WhatWeDoSection.offsetTop - 100,
				behavior: "smooth",
			});
		} else {
			// Natively supported by Chrome, Edge, Firefox, Opera except Safari. Performs much better than the polyfilled option for Safari above.
			WhatWeDoSection.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	return (
		<Wrapper>
			<HeroContainer>
				<PanelWrapper>
					<LeftPanel>
						<Phone>
							<PhoneContent />
						</Phone>
						<BehindPhoneGraphics>
							<Strip
								positionText="right"
								positionStrip="-500px 0 0 200px"
								text="Strategy"
								color="#5AB9EA"
							/>
							<Strip
								positionText="right"
								positionStrip="-400px 0 0 270px"
								text="UX/UI Design"
								color="#8860D0"
							/>
							<Strip
								positionText="left"
								positionStrip="-200px 0 0 -5px"
								text="Development"
								color="#5680E9"
							/>
							<Strip
								positionText="left"
								positionStrip="-100px 0 0 60px"
								text="SEO, PPC, CRO"
								color="#183149"
							/>
						</BehindPhoneGraphics>
					</LeftPanel>
					<RightPanel>
						<TitleSmall>Welcome to New Window Development</TitleSmall>
						<TitleMain>Your Future Web Development Agency</TitleMain>
						<CalloutTextGrid>
							<CalloutText caption>World-class Design</CalloutText>
							<CalloutText>Impress your visitors</CalloutText>
							<CalloutText caption>Ecommerce Experts</CalloutText>
							<CalloutText>Shopify & React app specialists</CalloutText>
							<CalloutText caption>Lightning fast</CalloutText>
							<CalloutText>Using the fastest web frameworks</CalloutText>
						</CalloutTextGrid>
						<ButtonWrap>
							<Button
								text="What we do"
								bkg="light"
								border="light"
								link="/"
								onClick={handleScrollTo}
							/>
							<Button
								text="price estimate"
								bkg="dark"
								border="dark"
								link="/get-estimate"
								id="btn_get_a_price_estimate" // used to identify the button for GTM
							/>
						</ButtonWrap>
					</RightPanel>
				</PanelWrapper>
			</HeroContainer>
			<WavesBackground ref={wavesBkg} safari={isSafari}></WavesBackground>
		</Wrapper>
	);
}
