// Dependencies
import React from "react";
import styled, { keyframes } from "styled-components";

// Styles
import { Container, device } from "../styles/Layout";

const appearFromBottom = keyframes`
	from {
		opacity:0;
		transform:translateY(20px);
	} to {
		opacity:1;
		transform:translateY(0);
	}
`;

const appearFromTop = keyframes`
	from {
		opacity:0;
		transform:translateY(-20px);
	} to {
		opacity:1;
		transform:translateY(0);
	}
`;

const Wrapper = styled.section`
	height: ${(props) => (props.height ? props.height : "32rem")};
	position: relative;
	background-image: ${(props) => props.theme.colours.gradientB};
	/* z-index: ${(props) => (props.zIndex ? props.zIndex : "0")}; */
	/* pointer-events: none; */
	@media ${device.md} {
		height: ${(props) => (props.height ? props.height : "40rem")};
	}
`;

const FlexContainer = styled(Container)`
	display: flex;
	align-items: center;
	height: 100%;
	padding-top: 4rem;
	@media ${device.md} {
		display: block;
		padding-top: ${(props) => (props.mobileTopPadding ? props.mobileTopPadding : "24rem")};
	}
`;

const Title = styled.h1`
	color: ${(props) => props.theme.colours.black};
	margin-bottom: 2rem;
	margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
`;

const Subtitle = styled.div`
	// Copying values from the <p> element, but using div due to possible nesting on some pages
	font-family: "Open Sans", sans-serif;
	font-size: 20px;
	font-weight: 300;
	letter-spacing: 0.4px;
	line-height: 2rem;

	color: ${(props) => props.theme.colours.black};
	max-width: 35rem;
`;

const TitleContainer = styled.div`
	text-align: left;
	flex: 1;
	animation: ${appearFromBottom} 1s ease-in-out;
`;

const ImageWrapper = styled.div`
	flex: 1;
	animation: ${appearFromTop} 1s ease-in-out;
	mix-blend-mode: luminosity;
	@media ${device.md} {
		position: absolute;
		margin-top: -36rem;
		z-index: -1;
	}
`;

export default function PageBanner({
	title,
	subtitle,
	img,
	children,
	height,
	imagePos,
	marginTop,
	mobileTopPadding,
}) {
	return (
		<Wrapper height={height} imagePos={imagePos}>
			<FlexContainer mobileTopPadding={mobileTopPadding}>
				<TitleContainer>
					<Title marginTop={marginTop}>{title}</Title>
					<Subtitle>
						{subtitle}
						{children}
					</Subtitle>
				</TitleContainer>
				<ImageWrapper>{img}</ImageWrapper>
			</FlexContainer>
		</Wrapper>
	);
}
