// Header image
import HeaderImg from "../../../../assets/img/banner-img-team.png";

// Team member images
import FrancisImg from "../../../../assets/img/team-francis.jpg";
import HaleyImg from "../../../../assets/img/team-haley.jpg";
import GuyImg from "../../../../assets/img/team-guy.jpg";
import MarcusImg from "../../../../assets/img/team-marcus.jpg";
import PouyaImg from "../../../../assets/img/team-pouya.jpg";
import RobImg from "../../../../assets/img/team-rob.jpg";
import MikeImg from "../../../../assets/img/team-mike.jpg";
import MirelaImg from "../../../../assets/img/team-mirela.jpg";
import AmandaImg from "../../../../assets/img/team-amanda.jpg";

// Team member tiny images
import FrancisImgTiny from "../../../../assets/img/team-francis-tiny.jpg";
import HaleyImgTiny from "../../../../assets/img/team-haley-tiny.jpg";
import GuyImgTiny from "../../../../assets/img/team-guy-tiny.jpg";
import MarcusImgTiny from "../../../../assets/img/team-marcus-tiny.jpg";
import PouyaImgTiny from "../../../../assets/img/team-pouya-tiny.jpg";
import RobImgTiny from "../../../../assets/img/team-rob-tiny.jpg";
import MikeImgTiny from "../../../../assets/img/team-mike-tiny.jpg";
import MirelaImgTiny from "../../../../assets/img/team-mirela-tiny.jpg";
import AmandaImgTiny from "../../../../assets/img/team-amanda-tiny.jpg";

export const team_en = {
	seo: {
		title: "Our Team | New Window Development",
		description:
			"We're small but mighty. No project is too small or too big for us - we can provide you with a one-on-one consultation or expand quickly to meet your project size.",
	},
	header_img: HeaderImg,
	title: "Team",
	subtitle:
		"We're small but mighty. No project is too small or too big for us - we can provide you with a one-on-one consultation or expand quickly to meet your project size.",
	members: [
		{
			name: "Francis Kolms",
			role: "Lead Account Manager",
			info: "With expertise in UX/UI design, web development and digital marketing, Francis is going to ensure that your project flows seamlessly from the start to the final delivery.",
			img: FrancisImg,
			imgTiny: FrancisImgTiny,
		},
		{
			name: "Haley Phair",
			role: "UX/UI Designer",
			info: "With many years of experience in UX/UI design, Haley is the go to guy for creating wireframes, design and working prototypes for projects.",
			img: HaleyImg,
			imgTiny: HaleyImgTiny,
		},
		// {
		// 	name: "Carol Tam",
		// 	role: "UX/UI Designer",
		// 	info: "The right hand of Anthony. If anything needs to be photoshopped, edited or retouched or visually altered, Carol is the one to call.",
		// 	img: FrancisImg,
		// imgTiny: FrancisImgTiny,
		// },
		{
			name: "Marcus Schimizzi",
			role: "Web Developer",
			info: "Once the UX/UI design for the website is approved, Marcus will turn it into a fully functional and beautiful website. ",
			img: MarcusImg,
			imgTiny: MarcusImgTiny,
		},
		{
			name: "Pouya Ataei",
			role: "Web Developer",
			info: "Experienced web developer with skills tailored for backend development. Building APIs, database and server management is Pouya's forte. ",
			img: PouyaImg,
			imgTiny: PouyaImgTiny,
		},
		// {
		// 	name: "Guy Valere",
		// 	role: "Web Developer",
		// 	info: "Full-stack developer taking charge of our smaller projects and ensuring that they are delivered on time and on budget.",
		// 	img: GuyImg,
		// imgTiny: GuyImgTiny,
		// },
		{
			name: "Amanda Kinghtley",
			role: "SEO & Copywriter",
			info: "Amanda's experience with linguistics and style help to make sure that ideas for getting your business out there make it off the drawing board and onto screen.",
			img: AmandaImg,
			imgTiny: AmandaImgTiny,
		},
		{
			name: "Mike Caldwell",
			role: "Google Ads & Analytics Expert",
			info: "Mike assists us with day-to-day management of SEO and Advertising accounts to make sure you get the bests return on your investment.",
			img: MikeImg,
			imgTiny: MikeImgTiny,
		},
		{
			name: "Mirela Dewshi",
			role: "Finance & Accounting",
			info: "Our chartered accountant making sure the business is operating smoothly.",
			img: MirelaImg,
			imgTiny: MirelaImgTiny,
		},
	],
};
