// Dependencies
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { FallingLines } from "react-loader-spinner";

// Styles
import { Container, device } from "../styles/Layout";
import Button from "../styles/Button";

// Text data
import contact_form_c_template from "../../data/email_templates/contact_form_c_template";
import ValidateForm from "../../data/ValidateForm";

// Icons
import { ReactComponent as Person } from "../../assets/svg/person_black_24dp.svg";
import { ReactComponent as Email } from "../../assets/svg/email_black_24dp.svg";
import { ReactComponent as Phone } from "../../assets/svg/phone_black_24dp.svg";
import { ReactComponent as Globe } from "../../assets/svg/language_black_24dp.svg";
import { ReactComponent as TellMore } from "../../assets/svg/add_comment_black_24dp.svg";
import { ReactComponent as Lock } from "../../assets/svg/lock_black_24dp.svg";

const fadeInOut = keyframes`
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
`;

const fadeIn = keyframes`
	from {
		opacity: 0;
	} to {
		opacity: 1;
	}
`;

const Wrapper = styled.section`
	display: flex;
	align-items: center;
	background: ${(props) => props.theme.colours.offwhite};
	box-shadow: 0px 0px 11px 11px #00000029;
	overflow: overlay;
	padding-bottom: 6rem;
`;

const FormContainer = styled(Container)`
	position: relative;
`;

const FormWrapper = styled.form`
	padding: 3rem 0 1rem 0;
	opacity: ${(props) => (!props.visible ? 0 : 1)};
	transition: ${(props) => props.theme.effects.transition};
	pointer-events: ${(props) => (props.visible ? "all" : "none")};
`;

const Title = styled.h1`
	margin-bottom: 2rem;
	padding-top: 3rem;
	color: ${(props) => props.theme.colours.black};
	&:after {
		content: "_";
		position: absolute;
		font-size: 1.5rem;
		margin-top: 0.1rem;
		margin-left: 0.5rem;
		animation: ${fadeInOut} 1s linear infinite;
	}
`;

const Input = styled.input`
	display: block;
	width: calc(100% - 6rem);
	padding: 1rem 3rem;
	border: none;
	border-bottom: 2px solid ${(props) => props.theme.colours.grey};
	background: transparent;
	transition: ${(props) => props.theme.effects.transition};
	&:hover,
	&:focus {
		border-bottom: 2px solid ${(props) => props.theme.colours.primary};
		transition: ${(props) => props.theme.effects.transition};
	}
`;

const Textarea = styled.span`
	display: block;
	width: calc(100% - 6rem);
	padding: 1.17rem 3rem;
	border: none;
	border-bottom: 2px solid ${(props) => props.theme.colours.grey};
	background: transparent;
	margin-bottom: 3rem;
	max-width: 50rem;
	overflow: hidden;
	resize: vertical;
	&:hover,
	&:focus {
		border-bottom: 2px solid ${(props) => props.theme.colours.primary};
		transition: ${(props) => props.theme.effects.transition};
		outline: none;
	}
	&[contentEditable]:empty::before {
		content: "Tell us more about your project";
		color: #757575;
	}
`;

const InputWrap = styled.div`
	margin-bottom: 1.5rem;
	& svg {
		position: absolute;
		margin-top: 1rem;
		fill: ${(props) => props.theme.colours.primary};
		transition: ${(props) => props.theme.effects.transition};
	}
	&:hover svg {
		transform: scale(1.2);
		transition: ${(props) => props.theme.effects.transition};
	}
`;

const PrivacyWrap = styled.div`
	font-size: 1rem;
	margin: 2rem 0 0 1.5rem;
	& a {
		color: ${(props) => props.theme.colours.black};
	}
	& svg {
		fill: ${(props) => props.theme.colours.black};
		width: 1rem;
		position: absolute;
		margin: -0.25rem 0 0 -1.5rem;
	}
`;

const InputWrapperTop = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 2rem;
	margin-top: 3rem;
	@media ${device.md} {
		display: block;
	}
`;

const InputWrapperBottom = styled.div`
	display: grid;
	grid-template-columns: 0.97fr 2fr;
	grid-column-gap: 2rem;
	@media ${device.md} {
		display: block;
	}
`;

const ButtonWrap = styled.div`
	display: flex;
	margin-top: -2.5rem;
	& button:first-child {
		margin-right: 1rem;
	}

	@media ${device.md} {
		margin-top: -0.75rem;
		& button {
			width: 100%;
		}
	}
`;

const ResultContainer = styled.p`
	position: absolute;
	padding-top: 3rem;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: ${(props) => props.theme.colours.black};
	animation: ${fadeIn} 0.5s linear;
`;

const ErrorMsg = styled.p`
	color: red;
	position: absolute;
	color: ${(props) => props.theme.colours.warning};
	position: absolute;
	font-size: 12px;
	font-weight: 400;
	margin-left: 3rem;
`;

export default function ContactFormC(props) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [tel, setTel] = useState("");
	const [currentWebsite, setCurrentWebsite] = useState("");
	const [tellUsMore, setTellUsMore] = useState("");

	const [loading, setLoading] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);

	const [resultMsg, setResultMsg] = useState("");

	// Form validation
	const [errors, setErrors] = useState({});
	const [isValidated, setIsValidated] = useState({});

	// Use effect to check for validationPassed value and update it immediately
	useEffect(() => {
		setIsValidated(ValidateForm({ name, email, tel }).validationPassed);
	}, [name, email, tel]);

	const handleSubmit = (e) => {
		e.preventDefault();

		// Display errors if any
		setErrors(ValidateForm({ name, email, tel }));

		if (isValidated) {
			// Hide form and show loading icon
			setLoading(true);

			// Generate email content from template
			const emailTemplate = contact_form_c_template(name, email, tel, currentWebsite, tellUsMore);

			// Send email to form submitter (CUSTOMER/USER)
			axios
				.post(process.env.REACT_APP_EMAIL_SEND_API, emailTemplate.emailToRecipient)
				.then((res) => {
					setResultMsg(emailTemplate.successMsg);
				})
				.catch((err) => {
					setResultMsg(emailTemplate.errorMsg);
				});

			// Send email to form receiver (ADMIN)
			axios
				.post(process.env.REACT_APP_EMAIL_SEND_API, emailTemplate.emailToSubmitter)
				.then((res) => {
					setLoading(false);
					setFormSubmitted(true);
					setResultMsg(emailTemplate.successMsg);
				})
				.catch((err) => {
					setLoading(false);
					setFormSubmitted(true);
					setResultMsg(emailTemplate.errorMsg);
				});
		}

		// // Animation & UX Test only
		// setTimeout(() => {
		// 	setLoading(false);
		// 	setFormSubmitted(true);
		// 	setResultMsg(emailTemplate.successMsg);
		// }, 500);
	};

	return (
		<Wrapper>
			<FormContainer isVisible={props.isVisible}>
				{loading && (
					<ResultContainer>
						<FallingLines width="110" color="#5680E9" />
					</ResultContainer>
				)}
				{formSubmitted && <ResultContainer dangerouslySetInnerHTML={{ __html: resultMsg }} id="contact_form_submitted"/>}

				<FormWrapper onSubmit={handleSubmit} visible={!(loading || formSubmitted)}>
					<Title>Send us a message directly</Title>
					<InputWrapperTop>
						<InputWrap>
							<Person />
							<Input
								type="text"
								placeholder="Name *"
								value={name}
								onChange={(e) => setName(e.target.value)}
								maxLength="30"
							/>
							{errors.name && <ErrorMsg>{errors.name}</ErrorMsg>}
						</InputWrap>
						<InputWrap>
							<Email />
							<Input
								type="email"
								placeholder="Email *"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								maxLength="60"
							/>
							{errors.email && <ErrorMsg>{errors.email}</ErrorMsg>}
						</InputWrap>
						<InputWrap>
							<Phone />
							<Input
								type="tel"
								placeholder="Tel"
								value={tel}
								onChange={(e) => setTel(e.target.value)}
								maxLength="30"
							/>
							{errors.tel && <ErrorMsg>{errors.tel}</ErrorMsg>}
						</InputWrap>
					</InputWrapperTop>
					<InputWrapperBottom>
						<InputWrap>
							<Globe />
							<Input
								type="text"
								placeholder="Current website (if you have one)"
								value={currentWebsite}
								onChange={(e) => setCurrentWebsite(e.target.value)}
								maxLength="30"
							/>
						</InputWrap>
						<InputWrap>
							<TellMore />
							<Textarea
								placeholder="Tell us more"
								role="textbox"
								contentEditable
								// This textarea is a <span> with contentEditable attribute, therefore value and onChange are not available. onKeyUp is used instead.
								onKeyUp={(e) => setTellUsMore(e.target.textContent)}
							/>
						</InputWrap>
					</InputWrapperBottom>
					<ButtonWrap>
						<Button
							text="Send us a message"
							bkg="dark"
							border="dark"
							icon={<Email />}
							type="submit"
						/>
					</ButtonWrap>
					<PrivacyWrap>
						<Lock />
						By submitting this form you agree to our{" "}
						<Link to="/privacy-policy">privacy policy</Link>
					</PrivacyWrap>
				</FormWrapper>
			</FormContainer>
		</Wrapper>
	);
}
