// Dependancies
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";

// Styles
import { Container, device } from "../styles/Layout";
import ServiceContainer from "./ServiceContainer";

// Component fragments
import IMac from "./IMac";

// Imposed images on the iMac
import UserJourneyImg from "../../assets/img/imac-scrn-aa-user-journey-v3.jpg";
import Wireframes from "../../assets/img/imac-scrn-ab-wireframes.jpg";
import Design from "../../assets/img/imac-scrn-design.jpg";
import UserTesting from "../../assets/img/imac-scrn-user-testing-v2.jpg";
import CodeImg from "../../assets/img/imac-scrn-code.jpg";
import Seo from "../../assets/img/imac-scrn-seo.jpg";
import GoogleAdsImg from "../../assets/img/imac-scrn-ppc.jpg";
import CroImg from "../../assets/img/imac-scrn-cro.jpg";
import SeoCopywritingImg from "../../assets/img/imac-scrn-seo-copywriting-v2.jpg";
import ConsultancyImg from "../../assets/img/imac-scrn-consultancy.jpg";

// Text content
import { home_en } from "../../data/page_content/en/pages/home_en";

// Set backgrounds for each section
const sectionColors = {
	// White
	a: {
		bkg: "#F8F8F8",
		btn: "#5680E9",
		text: "#1A334D",
		icons: "grey", // colours get ignored and greyscale filter gets applied to icons
	},
	// Violet
	c: {
		bkg: "#8761CF",
		btn: "#3b2465",
		text: "white",
		icons: "light", // colours get ignored and brightness filter gets applied to icons
	},
	// Dark Blue
	d: {
		bkg: "#567FE9",
		btn: "#1A334D",
		text: "white",
		icons: "original", // no filter applied to icons
	},
	// Light Blue
	b: {
		bkg: "#5AB9E9",
		btn: "#1A334D",
		text: "#1A334D",
		icons: "original", // no filter applied to icons
	},
};

const ServiceWrapper = styled.div`
	height: 80vh;
	display: flex;
	align-items: center;
`;

const Wrapper = styled.section`
	margin: 6rem 0 0 0;
	background: ${(props) => props.bkg};
	transition: background 0.75s;
	div${ServiceWrapper}:last-child {
		height: 100vh;
	}

	@media ${device.lg} {
		display: none;
	}
`;

const IMacWrapper = styled.div`
	position: relative;
	@media ${device.lg} {
		display: none;
	}
`;

export default function ServicesExplained() {
	// Set initial offset states for each section, to be used in the scrollmagic animation. Updated to correct values in useEffect.
	const [duration, setDuration] = useState(500);
	const [offsetA, setOffsetA] = useState(0);
	const [offsetB, setOffsetB] = useState(0);
	const [offsetC, setOffsetC] = useState(0);
	const [offsetD, setOffsetD] = useState(0);

	// Determine the currently selected category for each service and set defaults
	const [selectedServiceA, setSelectedServiceA] = useState(UserJourneyImg);
	const [selectedServiceB, setSelectedServiceB] = useState(CodeImg);
	const [selectedServiceC, setSelectedServiceC] = useState(Seo);
	const [selectedServiceD, setSelectedServiceD] = useState(ConsultancyImg);

	// Create container refs to store the current values for each selected category
	const refSelectedServiceA = useRef(null);
	const refSelectedServiceB = useRef(null);
	const refSelectedServiceC = useRef(null);
	const refSelectedServiceD = useRef(null);

	// Set refs for each section, to be used in the scrollmagic animation.
	const refServiceWrapA = useRef();
	const refServiceWrapB = useRef();
	const refServiceWrapC = useRef();
	const refServiceWrapD = useRef();

	// Determine which object should be shown outside iMac's screen
	const [outerObject, setOuterObject] = useState("");

	// Detect the wrapper's height to pass it on to scrollmagic so it knows the total hight of the component depending on the screen size
	const refWrapper = useRef();
	const [wrapperHeight, setWrapperHeight] = useState(0);

	// Set coefficient for wrapper height adjustment
	const [coefficient, setCoefficient] = useState(1);

	// Load first image in the iMac
	const [imposedImg, setImposedImg] = useState(UserJourneyImg);

	// Passing props from child to this parent
	const [serviceHighlight, setServiceHighlight] = useState({});

	// Initiate transition state when imposed image, outside object changes
	const [transition, setTransition] = useState(false);
	const [outerObjectTransition, setOuterObjectTransition] = useState(false);

	// Background states for each service
	const [background, setBackground] = useState("#F8F8F8");

	// Detect desktop or mobile views
	const [isMobile, setIsMobile] = useState(false);

	// Preload imposed images for smooth transitions
	const preloadImages = (images) => {
		images.forEach((img) => {
			let preloadLink = document.createElement("link");
			preloadLink.href = img;
			preloadLink.rel = "preload";
			preloadLink.as = "image";
			document.head.appendChild(preloadLink);
		});
	};

	// Transition between imposed images, objects and backgrounds
	const transitionTo = (image, object, bkg) => {
		if (image) {
			setTransition(true);
			setTimeout(() => {
				setImposedImg(image);
				setTransition(false);
			}, 300);
		}

		if (object) {
			setOuterObject(object);
			setOuterObjectTransition(true);
			setTimeout(() => {
				setOuterObjectTransition(false);
			}, 300);
		}

		if (bkg) {
			setBackground(bkg);
		}
	};

	// Set the initial offset for each section
	const handleScroll = () => {
		setDuration(window.innerHeight);
		setOffsetA(refServiceWrapA.current.offsetTop - window.innerHeight / 2);
		setOffsetB(refServiceWrapB.current.offsetTop - window.innerHeight / 2);
		setOffsetC(refServiceWrapC.current.offsetTop - window.innerHeight / 2);
		setOffsetD(refServiceWrapD.current.offsetTop - window.innerHeight / 2);
	};

	useLayoutEffect(() => {
		// Preload all imposed images once arrived at this component
		preloadImages([
			UserJourneyImg,
			Wireframes,
			UserTesting,
			Design,
			CodeImg,
			Seo,
			SeoCopywritingImg,
			GoogleAdsImg,
			CroImg,
			ConsultancyImg,
		]);
	}, []);

	useEffect(() => {
		// To ensure the iMac stops at the right position at the end of the wrapper, modify the wrapper height value with a coefficent.
		if (window.innerHeight <= 700) setCoefficient(0.93);
		if (window.innerHeight >= 700 && window.innerHeight < 800) setCoefficient(0.91);
		if (window.innerHeight >= 800 && window.innerHeight < 900) setCoefficient(0.89);
		if (window.innerHeight >= 900 && window.innerHeight < 1000) setCoefficient(0.87);
		if (window.innerHeight >= 1000 && window.innerHeight < 1100) setCoefficient(0.86);
		if (window.innerHeight >= 1100 && window.innerHeight < 1350) setCoefficient(0.84);
		if (window.innerHeight >= 1350) setCoefficient(0.83);

		// Set the wrapper height so it can be passed on to scrollmagic
		setWrapperHeight(refWrapper.current.clientHeight * coefficient);

		// Set images imposed on the iMac when clicked on a service category
		// Service A
		if (serviceHighlight.id === "Strategy") {
			transitionTo(UserJourneyImg);
		} else if (serviceHighlight.id === "Wireframes") {
			transitionTo(Wireframes);
		} else if (serviceHighlight.id === "Prototyping---Design") {
			transitionTo(Design);
		} else if (serviceHighlight.id === "User-Testing") {
			transitionTo(UserTesting);
			// Service B
			// Image defaults to initial image no matter which tab is clicked
			// Service C
		} else if (serviceHighlight.id === "On-Site-SEO") {
			transitionTo(Seo);
		} else if (serviceHighlight.id === "SEO-Copywriting") {
			transitionTo(SeoCopywritingImg);
		} else if (serviceHighlight.id === "Google-Ads") {
			transitionTo(GoogleAdsImg);
		} else if (serviceHighlight.id === "CRO--Conversion-Rate-Optimisation-") {
			transitionTo(CroImg);
		}
		// Service D
		// Image defaults to initial image no matter which tab is clicked

		// Need to check to which section the clicked service belongs to and assign an image to the iMac accordingly
		if (home_en.services_explained.service_a.title === serviceHighlight.service) {
			if (serviceHighlight.id === "Strategy") {
				setSelectedServiceA(UserJourneyImg);
			} else if (serviceHighlight.id === "Wireframes") {
				setSelectedServiceA(Wireframes);
			} else if (serviceHighlight.id === "Prototyping---Design") {
				setSelectedServiceA(Design);
			} else if (serviceHighlight.id === "User-Testing") {
				setSelectedServiceA(UserTesting);
			}
		} else if (home_en.services_explained.service_b.title === serviceHighlight.service) {
			if (serviceHighlight.id === "Dynamic---Fast") {
				setSelectedServiceB(CodeImg);
			} else if (serviceHighlight.id === "Fully-Responsive") {
				setSelectedServiceB(CodeImg);
			} else if (serviceHighlight.id === "MERN---Serverless") {
				setSelectedServiceB(CodeImg);
			} else if (serviceHighlight.id === "Scalable") {
				setSelectedServiceB(CodeImg);
			}
		} else if (home_en.services_explained.service_c.title === serviceHighlight.service) {
			if (serviceHighlight.id === "On-Site-SEO") {
				setSelectedServiceC(Seo);
			} else if (serviceHighlight.id === "SEO-Copywriting") {
				setSelectedServiceC(SeoCopywritingImg);
			} else if (serviceHighlight.id === "Google-Ads") {
				setSelectedServiceC(GoogleAdsImg);
			} else if (serviceHighlight.id === "CRO--Conversion-Rate-Optimisation-") {
				setSelectedServiceC(CroImg);
			}
		} else if (home_en.services_explained.service_d.title === serviceHighlight.service) {
			if (serviceHighlight.id === "Consultancy") {
				setSelectedServiceD(ConsultancyImg);
			} else if (serviceHighlight.id === "Domain---Hosting") {
				setSelectedServiceD(ConsultancyImg);
			} else if (serviceHighlight.id === "Maintenance") {
				setSelectedServiceD(ConsultancyImg);
			} else if (serviceHighlight.id === "General-Support") {
				setSelectedServiceD(ConsultancyImg);
			}
		}

		// Store the selected section in a reference
		refSelectedServiceA.current = selectedServiceA;
		refSelectedServiceB.current = selectedServiceB;
		refSelectedServiceC.current = selectedServiceC;
		refSelectedServiceD.current = selectedServiceD;

		// Trigger the transition between sections
		window.addEventListener("scroll", handleScroll, true);

		// // Don't render the iMac on mobile
		// if (window.innerWidth < device.large) setIsMobile(true);

		function checkWindowSize() {
			if (window.innerWidth > device.xlarge) return setIsMobile(false);
			if (window.innerWidth < device.xlarge) return setIsMobile(true);
		}
		window.addEventListener("resize", checkWindowSize);

		return () => {
			// Clean up the event listeners when the component is unmounted
			window.removeEventListener("scroll", handleScroll, true);
			window.removeEventListener("resize", checkWindowSize);
		};
	}, [
		setWrapperHeight,
		selectedServiceA,
		selectedServiceB,
		selectedServiceC,
		selectedServiceD,
		serviceHighlight,
		coefficient,
	]);

	return (
		<Wrapper ref={refWrapper} bkg={background}>
			<Container>
				{/* To avoid rendering duplicate lottie icons for desktop and mobile, render only the required version depending on the device / screen size.  */}
				{window.innerWidth > device.xlarge && (
					<IMacWrapper>
						<IMac
							height={wrapperHeight}
							imposedImg={imposedImg}
							transition={transition}
							outerObject={outerObject}
							outerObjectTransition={outerObjectTransition}
						/>
					</IMacWrapper>
				)}

				<Controller>
					<Scene triggerHook={0} offset={offsetA} duration={duration} pin triggerElement={null}>
						{(progress) => (
							<Timeline
								totalProgress={progress}
								paused
								target={
									<ServiceWrapper ref={refServiceWrapA}>
										<ServiceContainer
											container="A"
											setServiceHighlight={setServiceHighlight}
											textCol={sectionColors.a.text}
											btnCol={sectionColors.a.btn}
											iconStyle={sectionColors.a.icons}
											title={home_en.services_explained.service_a.title}
											subtitle={home_en.services_explained.service_a.subtitle}
											subcategory1={home_en.services_explained.service_a.subcategory1}
											subdescription1={home_en.services_explained.service_a.subdescription1}
											subcategory2={home_en.services_explained.service_a.subcategory2}
											subdescription2={home_en.services_explained.service_a.subdescription2}
											subcategory3={home_en.services_explained.service_a.subcategory3}
											subdescription3={home_en.services_explained.service_a.subdescription3}
											subcategory4={home_en.services_explained.service_a.subcategory4}
											subdescription4={home_en.services_explained.service_a.subdescription4}
										></ServiceContainer>
									</ServiceWrapper>
								}
							>
								<Tween
									from={{ opacity: "1" }}
									to={{ opacity: "1" }}
									duration={6}
									onStart={() => transitionTo(null, "lines", sectionColors.a.bkg)}
									onReverseComplete={() => transitionTo(null, "no image", sectionColors.a.bkg)}
								/>
								<Tween to={{ opacity: "1" }} duration={4} />
								<Tween
									to={{ opacity: "0" }}
									duration={2}
									onComplete={() =>
										transitionTo(refSelectedServiceB.current, null, sectionColors.b.bkg)
									}
									onReverseComplete={() =>
										transitionTo(refSelectedServiceA.current, "lines", sectionColors.a.bkg)
									}
								/>
							</Timeline>
						)}
					</Scene>
					<Scene triggerHook={100} offset={offsetB} duration={duration} pin triggerElement={null}>
						{(progress) => (
							<Timeline
								totalProgress={progress}
								paused
								target={
									<ServiceWrapper ref={refServiceWrapB}>
										<ServiceContainer
											container="B"
											setServiceHighlight={setServiceHighlight}
											textCol={sectionColors.b.text}
											btnCol={sectionColors.b.btn}
											iconStyle={sectionColors.b.icons}
											title={home_en.services_explained.service_b.title}
											subtitle={home_en.services_explained.service_b.subtitle}
											subcategory1={home_en.services_explained.service_b.subcategory1}
											subdescription1={home_en.services_explained.service_b.subdescription1}
											subcategory2={home_en.services_explained.service_b.subcategory2}
											subdescription2={home_en.services_explained.service_b.subdescription2}
											subcategory3={home_en.services_explained.service_b.subcategory3}
											subdescription3={home_en.services_explained.service_b.subdescription3}
											subcategory4={home_en.services_explained.service_b.subcategory4}
											subdescription4={home_en.services_explained.service_b.subdescription4}
										/>
									</ServiceWrapper>
								}
							>
								<Tween
									from={{ opacity: "0" }}
									to={{ opacity: "1" }}
									duration={6}
									onStart={() => transitionTo(null, "devices", sectionColors.b.bkg)}
								/>
								<Tween to={{ opacity: "1" }} duration={4} />
								<Tween
									to={{ opacity: "0" }}
									duration={2}
									onComplete={() =>
										transitionTo(refSelectedServiceC.current, null, sectionColors.c.bkg)
									}
									onReverseComplete={() =>
										transitionTo(refSelectedServiceB.current, "devices", sectionColors.b.bkg)
									}
								/>
							</Timeline>
						)}
					</Scene>
					<Scene
						triggerHook={0}
						offset={offsetC}
						duration={duration}
						pin={{ pushFollowers: false }}
						triggerElement={null}
					>
						{(progress) => (
							<Timeline
								totalProgress={progress}
								paused
								target={
									<ServiceWrapper ref={refServiceWrapC}>
										<ServiceContainer
											container="C"
											setServiceHighlight={setServiceHighlight}
											textCol={sectionColors.c.text}
											btnCol={sectionColors.c.btn}
											iconStyle={sectionColors.c.icons}
											title={home_en.services_explained.service_c.title}
											subtitle={home_en.services_explained.service_c.subtitle}
											subcategory1={home_en.services_explained.service_c.subcategory1}
											subdescription1={home_en.services_explained.service_c.subdescription1}
											subcategory2={home_en.services_explained.service_c.subcategory2}
											subdescription2={home_en.services_explained.service_c.subdescription2}
											subcategory3={home_en.services_explained.service_c.subcategory3}
											subdescription3={home_en.services_explained.service_c.subdescription3}
											subcategory4={home_en.services_explained.service_c.subcategory4}
											subdescription4={home_en.services_explained.service_c.subdescription4}
										></ServiceContainer>
									</ServiceWrapper>
								}
							>
								<Tween
									from={{ opacity: "0" }}
									to={{ opacity: "1" }}
									duration={6}
									onStart={() => transitionTo(null, "charts", sectionColors.c.bkg)}
								/>
								<Tween to={{ opacity: "1" }} duration={4} />
								<Tween
									to={{ opacity: "0" }}
									duration={2}
									onComplete={() =>
										transitionTo(refSelectedServiceD.current, null, sectionColors.d.bkg)
									}
									onReverseComplete={() =>
										transitionTo(refSelectedServiceC.current, "charts", sectionColors.c.bkg)
									}
								/>
							</Timeline>
						)}
					</Scene>
					<Scene
						triggerHook={100}
						offset={offsetD}
						duration={duration}
						pin={{ pushFollowers: false }}
						triggerElement={null}
					>
						{(progress) => (
							<Timeline
								totalProgress={progress}
								paused
								target={
									<ServiceWrapper ref={refServiceWrapD}>
										<ServiceContainer
											container="D"
											setServiceHighlight={setServiceHighlight}
											textCol={sectionColors.d.text}
											btnCol={sectionColors.d.btn}
											iconStyle={sectionColors.d.icons}
											title={home_en.services_explained.service_d.title}
											subtitle={home_en.services_explained.service_d.subtitle}
											subcategory1={home_en.services_explained.service_d.subcategory1}
											subdescription1={home_en.services_explained.service_d.subdescription1}
											subcategory2={home_en.services_explained.service_d.subcategory2}
											subdescription2={home_en.services_explained.service_d.subdescription2}
											subcategory3={home_en.services_explained.service_d.subcategory3}
											subdescription3={home_en.services_explained.service_d.subdescription3}
											subcategory4={home_en.services_explained.service_d.subcategory4}
											subdescription4={home_en.services_explained.service_d.subdescription4}
										/>
									</ServiceWrapper>
								}
							>
								<Tween
									from={{ opacity: "0" }}
									to={{ opacity: "1" }}
									duration={6}
									onStart={() => transitionTo(null, "support")}
								/>
								<Tween to={{ opacity: "1" }} duration={4} />
								<Tween to={{ opacity: "1" }} duration={2} />
							</Timeline>
						)}
					</Scene>
				</Controller>
			</Container>
		</Wrapper>
	);
}
