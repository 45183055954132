// Dependencies
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Burger from "@animated-burgers/burger-squeeze";
import { openPopupWidget } from "react-calendly";

// Styles
import "@animated-burgers/burger-squeeze/dist/styles.css";
import { device } from "../styles/Layout";

// Data
import { MobileNav } from "../../data/NavData";

const delayStyle = (value) => {
	setTimeout(() => {
		return value;
	}, 1000);
};

const MobileNavWrapper = styled.div`
	position: absolute;
	z-index: 13;
	overflow: hidden;

	height: ${(props) => (props.isOpen ? "100%" : delayStyle("6rem"))};
	width: ${(props) => (props.isOpen ? "100%" : "6rem")};

	transition: width 0.5s ease-in-out;
	right: 0;

	@media (min-width: ${device.large}px) {
		display: none;
	}
`;

const MenuContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => props.theme.colours.gradientA};
	height: 100vh;
	width: 100vw;
	transition: transform 0.5s ease-in-out;
	transform: ${(props) => (props.isOpen ? "translateX(0)" : "translateX(100%)")};

	& a {
		font-family: ${(props) => props.theme.fonts.title};
		font-size: 1.5rem;
		text-decoration: none;
		color: ${(props) => props.theme.colours.offwhite};
		display: block;
		line-height: 4rem;
	}
`;

const LinksContainer = styled.div``;

const SecondaryLinks = styled.div`
	margin-top: 1rem;
	padding-top: 1rem;
	border-top: 1px solid ${(props) => props.theme.colours.offwhite};
	& a {
		font-size: 1.25rem;
	}
`;

const BurgerButtonWrap = styled.div`
	position: absolute;
	z-index: 13;
	right: 1.5rem;
	margin-top: 1.75rem;
	& .burger-lines,
	.burger .burger-lines:after,
	.burger .burger-lines:before {
		background: ${(props) =>
			props.isHome ? props.theme.colours.offwhite : props.theme.colours.black};
		background: ${(props) => props.isOpen && props.theme.colours.offwhite};
	}
`;

const LinkWrap = styled.div``;

export default function BurgerMenu() {
	// Burger menu
	const [burgerOpen, setBurgerOpen] = useState(false);

	// State container for detecting if current page is home or not
	const [isHome, setIsHome] = useState(true);

	// Detect if current page is home or not (home page has a dark background and needs different burger styling)
	const toggleBurgerColor = () => {
		if (window.location.pathname !== "/") {
			setIsHome(false);
		} else {
			setIsHome(true);
		}
	};

	useEffect(() => {
		if (burgerOpen) {
			document.body.classList.add("no-scroll");
		}

		if (!burgerOpen) {
			document.body.classList.remove("no-scroll");
		}

		toggleBurgerColor();
	}, [burgerOpen]);

	const handleBurgerClick = () => {
		setBurgerOpen(!burgerOpen);
	};

	const handleLinkClick = () => {
		setBurgerOpen(!burgerOpen);
		toggleBurgerColor();
	};

	return (
		<MobileNavWrapper isOpen={burgerOpen}>
			<BurgerButtonWrap isOpen={burgerOpen} isHome={isHome}>
				<Burger isOpen={burgerOpen} onClick={handleBurgerClick} />
			</BurgerButtonWrap>
			<MenuContainer isOpen={burgerOpen}>
				<LinksContainer>
					{MobileNav.primary.map((item, index) => (
						<LinkWrap key={index} onClick={handleLinkClick}>
							<Link to={item.link}>{item.title}</Link>
						</LinkWrap>
					))}
					<SecondaryLinks>
						{/* Get a price estimate */}
						<LinkWrap onClick={handleLinkClick}>
							<Link to={MobileNav.secondary[0].link}>{MobileNav.secondary[0].title}</Link>
						</LinkWrap>

						{/* Schedule a video call */}
						<LinkWrap onClick={() => openPopupWidget({ url: MobileNav.secondary[1].link })}>
							<a>{MobileNav.secondary[1].title}</a>
						</LinkWrap>
					</SecondaryLinks>
				</LinksContainer>
			</MenuContainer>
		</MobileNavWrapper>
	);
}
