import { company_data_en } from "../company_data/company_data_en";

export const footer_en = {
	top: {
		line1: "Wish to call us directly before making any decisions?",
		line2_1: "Call us at ",
		line2_bold: company_data_en.tel_local,
		line2_2: " or use our live chat window to talk to one of our experts.",
	},
	middle: {
		column1: {
			title: "GET IN TOUCH",
			address_line1: company_data_en.address_line_1,
			address_line2: company_data_en.address_line_2,
			email: company_data_en.email,
			tel: company_data_en.tel_international,
		},
		column2: {
			title: "QUICK LINKS",
			// Footer menu items get rendered below
		},
	},
	// Bottom small print section is inserted directly into the footer component.
};
