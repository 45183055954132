// Home page
export const home_en = {
	seo: {
		title: "Your Future Web Development Agency | New Window Development",
		description:
			"New Window Development is a web development agency based in London. We offer a wide range of web development services including custom web development, e-commerce development, and web design.",
	},
	hero_section: {
		small_title: "Welcome to New Window Development",
		large_title: "Your Future Web Development Agency",
		callout_text1: {
			title: "World-class design",
			desc: "Impress your visitors",
		},
		callout_text2: {
			title: "Ecommerce specialists",
			desc: "Shopify & ReactJS experts",
		},
		callout_text3: {
			title: "Lightning fast",
			desc: "Using the fastest web frameworks",
		},

		mobile_flyouts: {
			1: "Strategy",
			2: "UX/UI Design",
			3: "Development",
			4: "SEO, PPC, CRO",
		},
	},
	top_section: {
		title: "What we do",
		subtitle:
			"We are a digital agency offering user experience design, custom website development and fully integrated digital marketing services to make your business a success.",
	},
	flowchart: {
		title1: "Strategy",
		subtitle1: "We conduct competition research, develop user journeys and create a strategy",
		title2: "UX/UI Design",
		subtitle2:
			"Fully Bespoke Graphic Design and tailored UX experience that fits your exact requirements",
		title3: "Web Development",
		subtitle3:
			"Our developers code to achieve excellence in speed, security and asset optimisation",
		title4: "Launch",
		subtitle4:
			"Finalising the last details and launching the site. 1 month free after-launch support.",
		title5: "Digital Marketing & Support",
		subtitle5: "Digital marketing and IT support to make your site deliver your goals",
	},
	services_explained: {
		service_a: {
			title: "UX/UI design",
			subtitle: "Beautiful and with purpose",
			subcategory1: "Strategy",
			subdescription1:
				"We conduct competition research, develop user journeys and create a strategy, ensuring your website is a great fit for your target audience and your vision becomes a reality while staying within predetermined brand guidelines.",
			subcategory2: "Wireframes",
			subdescription2:
				"We provide wireframe design ensuring your site is solid at the structural level and laying the foundation for an amazing user experience. The aim of a wirefrime is a visual understanding of a page early in the project before the creative design process begins.",
			subcategory3: "Prototyping & Design",
			subdescription3:
				"Once the wireframes are approved, we start the graphic design process. We use a variety of tools to ensure that the design is both visually appealing and functional before handed to our development team for realisation.",
			subcategory4: "User Testing",
			subdescription4:
				"We conduct extensive user testing to ensure the design delivers expected results before the site is launched. This ensures that nothing was missed and your site will deliver results from the first day forward without any unpleasant surprises.",
		},
		service_b: {
			title: "Web development",
			subtitle: "Fast and responsive",
			subcategory1: "Dynamic & Fast",
			subdescription1:
				"We develop websites that are dynamic, fast and secure. By using the latest technologies and most up to date libraries, our development team ensures that your site is optimised for the best possible user experience. ",
			subcategory2: "Fully Responsive",
			subdescription2:
				"Whether your website is viewed through a mobile device, tablet or desktop, we ensure that it is fully responsive making it look fantastic on any device. No corners will be cut in delivering the best user experience no matter the device they are using.",
			subcategory3: "Ecommerce Experts",
			subdescription3:
				"Our team has extensive experience in ecommerce projects ranging from custom Shopify themes and app development to fully custom solutions built with the MERN stack and deployed on your server of choice.",
			subcategory4: "Scalable",
			subdescription4:
				"The websites we build are fully scalable, meaning that you can add more content to your site without having to worry about the performance. Be it a social media site or an ecommerce store with thausands of users, we can handle it.",
		},
		service_c: {
			title: "Digital Marketing",
			subtitle: "Make your website a success",
			subcategory1: "On-Site SEO",
			subdescription1:
				"With any website that we build we offer the on-site SEO service. Our expert SEO team will ensure all the necessary keywords, tags and SEO techniques are in place to get your website indexed and ranked on search engines.",
			subcategory2: "SEO Copywriting",
			subdescription2:
				"Writing great product descriptions is one thing. But writing the same description while keeping best SEO practices is another. We offer SEO copywriting services to ensure that your website is well-described and that your content is made to rank in search engines.",
			subcategory3: "Google Ads",
			subdescription3:
				"Want to get your site on top of Google results the day it launches? No problem. We offer Google Ads campaign setup and management to get visitors flying to your site as soon as the campaign is launched.",
			subcategory4: "CRO (Conversion Rate Optimisation)",
			subdescription4:
				"If your site doesn't convert visitors to leads or sales, our Conversion Rate Optimisation (CRO) service can help. With A/B and multivariate testing our experts will help your site increase conversions and ultimately your revenue with it.",
		},
		service_d: {
			title: "Support & Consultancy",
			subtitle: "Experts at your fingertips",
			subcategory1: "Consultancy",
			subdescription1:
				"If you already have an active website you're quite happy with but need technical and design support we can help. Or, perhaps, you're a start-up and need a consultant to get your idea off the ground? Our experts are ready to help.",
			subcategory2: "Domain & Hosting",
			subdescription2:
				"Depending on your project specification, we use AWS or Heroku for hosting and will set it all up for you as part of the project. However, if you have your own hosting provider or cloud account already set up we will work with that.",
			subcategory3: "Maintenance",
			subdescription3:
				"We offer cost effective maintenance plans to make sure your site stays up to date. Anytime you need improvements, we'll be ready to implement. Nevertheless, if you already have an active website built by someone else, 90% of the cases we can help maintain it too.",
			subcategory4: "General Support",
			subdescription4:
				"Dealing with web issues can be complicated and frustrating. Our experts provide advice and support using the best of their knowledge to help you deal with any problems quickly and efficiently.",
		},
	},
};
