// Dependencies
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { openPopupWidget, CalendlyEventListener } from "react-calendly";
import axios from "axios";

// Styles
import { Container, device } from "../styles/Layout";
import Button from "../styles/Button";

// Email template data
import contact_form_a_template from "../../data/email_templates/contact_form_a_template";

// Form validation function
import ValidateForm from "../../data/ValidateForm";

// Icons
import { ReactComponent as Person } from "../../assets/svg/person_black_24dp.svg";
import { ReactComponent as Email } from "../../assets/svg/email_black_24dp.svg";
import { ReactComponent as Phone } from "../../assets/svg/phone_black_24dp.svg";
import { ReactComponent as Globe } from "../../assets/svg/language_black_24dp.svg";
import { ReactComponent as TellMore } from "../../assets/svg/add_comment_black_24dp.svg";
import { ReactComponent as Lock } from "../../assets/svg/lock_black_24dp.svg";
import { ReactComponent as TaskComplete } from "../../assets/svg/task_alt_black_24dp.svg";

// Text content & Calendly data
import { contact_form_a_en } from "../../data/page_content/en/components/contact_form_a_en";
import { calendly_link_en } from "../../data/page_content/en/company_data/calendly_link_en";

const fadeInOut = keyframes`
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
`;

const Wrapper = styled.section`
	display: flex;
	align-items: center;
	/* min-height: 100vh; */
	height: 100vh;
	max-height: 60rem;
	background: ${(props) => props.theme.colours.offwhite};
	box-shadow: 0px 0px 11px 11px #00000029;
	overflow: hidden;

	@media ${device.lg} {
		height: auto;
		max-height: none;
		margin-top: 10rem;
	}
`;

const FormWrapper = styled.form`
	display: flex;
	padding: 3rem 0;

	@media ${device.lg} {
		display: block;
		padding: 4rem 0;
	}
`;

const Left = styled.div`
	flex: 1;
	flex-shrink: 1;
`;
const Right = styled.div`
	flex: 1;
	margin-top: -0.45rem;
	display: grid;
	@media ${device.lg} {
		margin-top: 2rem;
	}
`;

const Title = styled.h1`
	margin-bottom: 4rem;
	color: ${(props) => props.theme.colours.black};
	&:after {
		content: "_";
		position: absolute;
		font-size: 1.5rem;
		margin-top: 0.1rem;
		margin-left: 0.5rem;
		animation: ${fadeInOut} 1s linear infinite;
	}
`;

const Subtitle = styled.h2`
	margin-top: 1rem;
	color: ${(props) => props.theme.colours.black};
`;

const Text = styled.p`
	font-size: 1rem;
	font-weight: ${(props) => (props.bold ? "600" : "300")};
	color: ${(props) => props.theme.colours.black};
	margin-top: 1rem;
	padding-right: 8rem;
	@media ${device.lg} {
		padding-right: 0;
	}
`;

const TextBottom = styled.div`
	margin-top: 4rem;
	@media ${device.lg} {
		display: none;
	}
`;

const FootnoteLink = styled(Link)`
	font-size: 16px;
	color: ${(props) => props.theme.colours.primary};
	cursor: pointer;
	transition: ${(props) => props.theme.effects.transition};
	&:hover {
		color: ${(props) => props.theme.colours.secondaryDkB};
		transition: ${(props) => props.theme.effects.transition};
	}
`;

const Input = styled.input`
	display: block;
	width: calc(100% - 6rem);
	padding: 1rem 3rem;
	border: none;
	border-bottom: 2px solid ${(props) => props.theme.colours.grey};
	background: transparent;
	transition: ${(props) => props.theme.effects.transition};
	&:hover,
	&:focus {
		border-bottom: 2px solid ${(props) => props.theme.colours.primary};
		transition: ${(props) => props.theme.effects.transition};
	}
`;

const Textarea = styled.span`
	display: block;
	width: calc(100% - 6rem);
	padding: 1.17rem 3rem;
	border: none;
	border-bottom: 2px solid ${(props) => props.theme.colours.grey};
	background: transparent;
	margin-bottom: 3rem;
	max-width: 50rem;
	overflow: hidden;
	resize: vertical;
	&:hover,
	&:focus {
		border-bottom: 2px solid ${(props) => props.theme.colours.primary};
		transition: ${(props) => props.theme.effects.transition};
		outline: none;
	}
	&[contentEditable]:empty::before {
		content: "Tell us more about your project";
		color: #757575;
	}
	@media ${device.lg} {
		margin-bottom: 1rem;
	}
`;

const InputWrap = styled.div`
	margin-bottom: 1.5rem;
	& svg {
		position: absolute;
		margin-top: 1rem;
		fill: ${(props) => props.theme.colours.primary};
		transition: ${(props) => props.theme.effects.transition};
	}
	&:hover svg {
		transform: scale(1.2);
		transition: ${(props) => props.theme.effects.transition};
	}
`;

const PrivacyWrap = styled.div`
	font-size: 1rem;
	margin: 2rem 0 0 1.5rem;
	& a {
		color: ${(props) => props.theme.colours.black};
	}
	& svg {
		fill: ${(props) => props.theme.colours.black};
		width: 1rem;
		position: absolute;
		margin: -0.25rem 0 0 -1.5rem;
	}
`;

const ConfirmationBox = styled.div`
	align-self: center;
	padding: 3rem 3rem 4rem 3rem;
	background: ${(props) => props.theme.colours.gradientA};
	& ${Text} {
		color: ${(props) => props.theme.colours.offwhite};
		padding: 0 0 0 2rem;
	}
	& svg {
		position: absolute;
		margin: 0.2rem 0 0 -2rem;
		fill: ${(props) => props.theme.colours.offwhite};
	}
`;

const ErrorMsg = styled.p`
	color: red;
	position: absolute;
	color: ${(props) => props.theme.colours.warning};
	position: absolute;
	font-size: 12px;
	font-weight: 400;
	margin-left: 3rem;
`;

const ConfirmationIcon = styled(TaskComplete)``;

export default function ContactFormA() {
	// Set contact form fields
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [tel, setTel] = useState("");
	const [currentWebsite, setCurrentWebsite] = useState("");
	const [tellUsMore, setTellUsMore] = useState("");

	// Monitor when Calendly form has been submitted
	const [isScheduled, setIsScheduled] = useState(false);

	// Form validation
	const [errors, setErrors] = useState({});
	const [isValidated, setIsValidated] = useState({});

	// Use effect to check for validationPassed value and update it immediately
	useEffect(() => {
		setIsValidated(ValidateForm({ name, email, tel }).validationPassed);
	}, [name, email, tel]);

	const handleScheduling = (e) => {
		// Display errors if any
		setErrors(ValidateForm({ name, email, tel }));

		// If form is valid, proceed
		if (isValidated) {
			// Open Calendly widget in a modal
			openPopupWidget({ url: calendly_link_en, prefill: { name, email } });

			// Generate email content from template
			const emailTemplate = contact_form_a_template(name, email, tel, currentWebsite, tellUsMore);

			// Send email to to ADMIN
			axios.post(process.env.REACT_APP_EMAIL_SEND_API, emailTemplate.emailToAdmin);

			// Submitted form data is sent to admin only to notify that someone is interested in scheduling a discovery call and collect the field data.
		}
	};

	const handleEventScheduled = () => {
		setIsScheduled(true);
	};

	return (
		<Wrapper>
			<Container>
				<FormWrapper>
					<Left>
						<Title>{contact_form_a_en.title}</Title>
						<Subtitle>{contact_form_a_en.subtitle1}</Subtitle>
						<Text>{contact_form_a_en.subpara1}</Text>
						<Subtitle>{contact_form_a_en.subtitle2}</Subtitle>
						<Text>{contact_form_a_en.subpara2}</Text>
						<Subtitle>{contact_form_a_en.subtitle3}</Subtitle>
						<Text>{contact_form_a_en.subpara3}</Text>
						<TextBottom>
							<Text>{contact_form_a_en.footnoteText}</Text>
							<FootnoteLink 
								to="/get-estimate"
								id="btn_get_a_price_estimate" // used to identify the button for GTM
							>
								{contact_form_a_en.footnoteLink}
							</FootnoteLink>
						</TextBottom>
					</Left>
					{isScheduled ? (
						<Right>
							<ConfirmationBox 
								className="calendly_event_scheduled" // class used to identify the completed calendly event in GTM
							> 
								{/* <Text>{contact_form_a_en.thankYouText}</Text> */}
								<Text bold>
									<ConfirmationIcon />
									{contact_form_a_en.confirmationTitle}, {name}!
								</Text>
								<Text>{contact_form_a_en.confirmationText}</Text>
							</ConfirmationBox>
						</Right>
					) : (
						<Right>
							<InputWrap>
								<Person />
								<Input
									type="text"
									placeholder={contact_form_a_en.form.name + " *"}
									value={name}
									onChange={(e) => setName(e.target.value)}
									maxLength="30"
								/>
								{errors.name && <ErrorMsg>{errors.name}</ErrorMsg>}
							</InputWrap>
							<InputWrap>
								<Email />
								<Input
									type="email"
									placeholder={contact_form_a_en.form.email + " *"}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									maxLength="40"
								/>
								{errors.email && <ErrorMsg>{errors.email}</ErrorMsg>}
							</InputWrap>
							<InputWrap>
								<Phone />
								<Input
									type="tel"
									placeholder={contact_form_a_en.form.phone}
									value={tel}
									onChange={(e) => setTel(e.target.value)}
									maxLength="20"
								/>
								{errors.tel && <ErrorMsg>{errors.tel}</ErrorMsg>}
							</InputWrap>
							<InputWrap>
								<Globe />
								<Input
									type="text"
									placeholder={contact_form_a_en.form.website}
									value={currentWebsite}
									onChange={(e) => setCurrentWebsite(e.target.value)}
									maxLength="60"
								/>
							</InputWrap>
							<InputWrap>
								<TellMore />
								<Textarea
									placeholder="Tell us more"
									role="textbox"
									contentEditable
									// This textarea is a <span> with contentEditable attribute, therefore value and onChange are not available. onKeyUp is used instead.
									onKeyUp={(e) => setTellUsMore(e.target.textContent)}
								/>
							</InputWrap>
							<Button
								text={contact_form_a_en.form.button}
								bkg="dark"
								border="dark"
								link="#"
								fullwidth="true"
								onClick={handleScheduling}
								id="btn_schedule_a_video_call" // used to identify the button for GTM
							/>
							<PrivacyWrap>
								<Lock />
								{contact_form_a_en.form.privacyText}
								<Link to="/privacy-policy">{contact_form_a_en.form.privacyLink}</Link>
							</PrivacyWrap>
						</Right>
					)}
				</FormWrapper>
			</Container>
			<CalendlyEventListener onEventScheduled={handleEventScheduled} />
		</Wrapper>
	);
}
