import React from "react";
import styled, { keyframes } from "styled-components";

const clipLongStrip = "polygon(14px 0%, 100% 0%, 137px 100%, 1% 100%)";
const clipShortStrip = "polygon(13px 0, 100% 0, 30% 100%, 2% 100%)";
const shadow = "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2))";
const rightX = 260;
const leftX = 160;

const slideInRight = keyframes`
	from {
		transform: translateX(-${rightX}px);
	}
	to {
		transform: translateX(0);
		opacity:1;
	}
`;

const slideInRightFirst = keyframes`
	from {
		transform: translateX(-${leftX}px);
		opacity:0;
	}
	to {
		transform: translateX(0);
		opacity:1;
	}
`;

const slideInLeft = keyframes`
	from {
		transform: translateX(${rightX}px);
	}
	to {
		transform: translateX(0);
		opacity:1;
	}
`;

const slideInLeftFirst = keyframes`
	from {
		transform: translateX(${leftX}px);
		opacity:0;
	}
	to {
		transform: translateX(0);
		opacity:1;
	}
`;

const StripWrapper = styled.div`
	position: absolute;
	height: 34px;
	display: flex;
	font-size: 12px;
	font-weight: 400;
	z-index: -1;
	margin: ${(props) => props.margin};
`;

const StripLeft = styled.div`
	background: ${(props) => props.color};
	height: 100%;
	min-width: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	clip-path: ${clipLongStrip};
	opacity: 0;

	transform: ${(props) =>
		props.positionText === "left" ? `translateX(${rightX}px)` : `translateX(-${leftX}px)`};
	animation: ${(props) => (props.positionText === "left" ? slideInLeft : slideInRightFirst)} 1.5s
		cubic-bezier(0.175, 0.885, 0.32, 1.1) forwards;
	animation-delay: ${(props) => (props.positionText === "left" ? "1.5s" : ".25s")};
`;

const StripMiddleOne = styled.div`
	background: ${(props) => props.color};
	height: 100%;
	width: 18px;
	clip-path: ${clipShortStrip};

	transform: ${(props) =>
		props.positionText === "left" ? `translateX(${rightX}px)` : `translateX(-${leftX}px)`};
	animation: ${(props) => (props.positionText === "left" ? slideInLeft : slideInRight)} 1.5s
		cubic-bezier(0.175, 0.885, 0.32, 1.1) 0.75s forwards;
`;
const StripMiddleTwo = styled.div`
	background: ${(props) => props.color};
	height: 100%;
	width: 18px;
	clip-path: ${clipShortStrip};

	transform: ${(props) =>
		props.positionText === "left" ? `translateX(${rightX}px)` : `translateX(-${leftX}px)`};
	animation: ${(props) => (props.positionText === "left" ? slideInLeft : slideInRight)} 1.5s
		cubic-bezier(0.175, 0.885, 0.32, 1.1) 0.75s forwards;
`;
const StripRight = styled.div`
	display: flex;
	background: ${(props) => props.color};
	height: 100%;
	min-width: 150px;
	align-items: center;
	justify-content: center;
	clip-path: ${clipLongStrip};
	opacity: 0;

	transform: ${(props) =>
		props.positionText === "right" ? `translateX(-${rightX}px)` : `translateX(${leftX}px)`};
	animation: ${(props) => (props.positionText === "right" ? slideInRight : slideInLeftFirst)} 1.5s
		cubic-bezier(0.175, 0.885, 0.32, 1.1) 1.5s forwards;
	animation-delay: ${(props) => (props.positionText === "right" ? "1.5s" : ".25s")};
`;

const ShadowBox = styled.div`
	filter: ${shadow};
	margin-left: -7px;
`;

export default function Strip({ text, positionText, positionStrip, icon, color }) {
	return (
		<StripWrapper margin={positionStrip}>
			<ShadowBox>
				<StripLeft positionText={positionText} color={color}>
					{positionText === "left" ? text : null}
				</StripLeft>
			</ShadowBox>
			<ShadowBox>
				<StripMiddleOne positionText={positionText} color={color} />
			</ShadowBox>
			<ShadowBox>
				<StripMiddleTwo positionText={positionText} color={color} />
			</ShadowBox>
			<ShadowBox>
				<StripRight positionText={positionText} color={color}>
					{positionText === "right" ? text : null}
				</StripRight>
			</ShadowBox>
		</StripWrapper>
	);
}
