export default function contact_form_b_template(
	name,
	email,
	tel,
	currentWebsite,
	tellUsMore,
	quoteData
) {
	// quoteData property returns an array of strings which need to be put in pairs to display in the email
	const result = splitIntoPairs(quoteData.described).map((el) => {
		return `${el[0]} : ${el[1]}`;
		// result "Number of pages : 1-10"
	});

	const quoteDataMap = result
		.map((el) => {
			return `<p>${el}</p>`;
		})
		.join(""); // If not joined, there will appear unneccessary commas between the elements

	// Email to form submitter (CUSTOMER/USER)
	const emailToSubmitter = {
		fromEmail: "info@nwdevelopment.co.uk",
		toEmail: email,
		subject: "Here is your project estimate",
		body: `
		<style>
		p {
			font-size:16px; 
			font-family: "Open Sans", sans-serif;
		}
		a {
			display:block;
			font-size:16px; 
			font-family: "Open Sans", sans-serif;
		}
	</style>
		<p>Hello ${name},</p>
		<p>Thank you for your interest in our web development services. Our estimate for your project is somewhere inbetween ${quoteData.lowEstimate} and ${quoteData.baseEstimate} based of the following options:</p>
        ${quoteDataMap}
		<p>Our team will review your requirements and we'll get back to you with a more precise quote or ask for more information to get the final price calculated for you.</p>
		<p>Kind regards,</p>
		<p>
			New Window Development Team
			<a href="https://nwdevelopment.co.uk">https://nwdevelopment.co.uk</a>
		</p>
		`,
	};

	// Email to form receiver (ADMIN)
	const emailToRecipient = {
		fromEmail: "info@nwdevelopment.co.uk",
		toEmail: "info@nwdevelopment.co.uk",
		subject: `${name} is interested in an exact quote for their project`,
		body: `
		<p>Name: ${name}</p>
		<p>Email: ${email}</p>
		<p>Tel: ${tel}</p>
		<p>Current Website: ${currentWebsite}</p>
		<p>Tell us more: ${tellUsMore}</p>
		<p>Quoted range: ${quoteData.lowEstimate} - ${quoteData.baseEstimate}</p>
        ${quoteDataMap}
		`,
	};

	const successMsg = `Thank you! We have sent this quote to your email address.<br>We are going to get in touch with you within the next 24 hours to provide you with a more exact quote.`;

	const errorMsg = `Sorry, something went wrong.<br>Please try again later or send us an email directly to info@nwdevelopment.co.uk`;

	const data = {
		emailToSubmitter,
		emailToRecipient,
		successMsg,
		errorMsg,
	};

	return data;
}

// quoteData property returns an array of strings which need to be put in pairs with the function below
function splitIntoPairs(arr) {
	const res = [];
	for (let i = 0; i < arr.length; i += 2) {
		const chunk = arr.slice(i, i + 2);
		res.push(chunk);
	}
	return res;
}
