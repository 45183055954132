export default function contact_form_a_template(name, email, tel, currentWebsite, tellUsMore) {
	// Email to form submitter (CUSTOMER/USER) is not necessary because this form uses Calendly modal for form submission

	// Email to form receiver (ADMIN)
	const emailToAdmin = {
		fromEmail: "form@nwdevelopment.co.uk",
		toEmail: "info@nwdevelopment.co.uk",
		subject: `${name} has submitted a Schedule A Video Call form at NWD`,
		body: `
		<p>Name: ${name}</p>
		<p>Email: ${email}</p>
		<p>Tel: ${tel}</p>
		<p>Current Website: ${currentWebsite}</p>
		<p>Tell us more: ${tellUsMore}</p>
		`,
	};

	const successMsg = `Thank you! Your message has been sent.<br>We are going to get back to you within the next 24 hours.`;

	const errorMsg = `Sorry, something went wrong.<br>Please try again later or send us an email directly to info@nwdevelopment.co.uk`;

	const data = {
		emailToAdmin,
		successMsg,
		errorMsg,
	};

	return data;
}
