import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const fadeInOut = keyframes`
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
`;

const ButtonLink = styled(Link)`
	background: ${(props) => props.bkg === "light" && "white"};
	background: ${(props) => props.bkg === "dark" && props.theme.colours.primary};
	background: ${(props) => props.bkg === "none" && "none"};

	border: 2px solid;
	border-color: ${(props) => props.border === "dark" && props.theme.colours.primary};
	border-color: ${(props) => props.border === "light" && "white"};

	color: ${(props) => props.bkg === "light" && props.theme.colours.primary};
	color: ${(props) => props.bkg === "dark" && "white"};
	color: ${(props) => props.bkg === "none" && "white"};

	display: flex;
	justify-content: center;

	box-shadow: 0 3px 8px 0px #00000047;

	text-decoration: none;
	width: ${(props) => (props.fullwidth ? "100%" : "14rem")};
	/* width: ${(props) => props.small && "12rem"}; */

	height: ${(props) => (props.small ? "2.25rem" : "2.75rem")};

	border-radius: 0.25rem;
	font-family: "Open Sans", sans-serif;
	text-align: center;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	transform: scale(0.999); // If it's not 1 then the text does a weird flickering effect on mouseout
	transition: ${(props) => props.theme.effects.transition}; // cubic-bezier for bouncy effect
	cursor: pointer;

	&:hover {
		transform: scale(1.04999);
	}
	&:hover div:after {
		content: "_";
		position: absolute;
		font-size: 1.2rem;
		margin-top: -0.5rem;
		margin-left: 0.1rem;
		animation: ${fadeInOut} 1s linear infinite;
	}
	& svg {
		margin: 0.4rem 0.5rem 0 0;
		fill: ${(props) => props.bkg === "light" && props.theme.colours.primary};
		fill: ${(props) => props.bkg === "dark" && "white"};
		fill: ${(props) => props.bkg === "none" && "white"};
	}
`;

const ButtonNoLink = styled.button`
	background: ${(props) => props.bkg === "light" && "white"};
	background: ${(props) => props.bkg === "dark" && props.theme.colours.primary};
	background: ${(props) => props.bkg === "none" && "none"};

	border: 2px solid;
	border-color: ${(props) => props.border === "dark" && props.theme.colours.primary};
	border-color: ${(props) => props.border === "light" && "white"};

	color: ${(props) => props.bkg === "light" && props.theme.colours.primary};
	color: ${(props) => props.bkg === "dark" && "white"};

	display: flex;
	justify-content: center;

	box-shadow: 0 3px 8px 0px #00000047;
	text-decoration: none;

	width: ${(props) => (props.fullwidth ? "100%" : "14rem")};
	/* width: ${(props) => props.small && "12rem"}; */

	height: ${(props) => (props.small ? "2.25rem" : "2.75rem")};

	border-radius: 0.25rem;
	font-family: "Open Sans", sans-serif;
	text-align: center;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	transform: scale(0.999); // If it's not 1 then the text does a weird flickering effect on mouseout
	transition: ${(props) => props.theme.effects.transition}; // cubic-bezier for bouncy effect
	cursor: pointer;

	&:hover {
		transform: scale(1.04999);
	}
	&:hover div:after {
		content: "_";
		position: absolute;
		font-size: 1.2rem;
		margin-top: -0.5rem;
		margin-left: 0.1rem;
		animation: ${fadeInOut} 1s linear infinite;
	}
	& svg {
		margin: 0.4rem 0.5rem 0 0;
		fill: ${(props) => props.bkg === "light" && props.theme.colours.primary};
		fill: ${(props) => props.bkg === "dark" && "white"};
	}
`;

const Text = styled.div`
	width: fit-content;
	align-self: center;
	pointer-events:none;
`;

export default function Button({ text, link, bkg, border, fullwidth, onClick, icon, small, id }) {
	return (
		<>
			{link ? (
				<ButtonLink
					to={link}
					bkg={bkg}
					border={border}
					fullwidth={fullwidth}
					onClick={onClick}
					small={small}
					id={id}
				>
					{icon}
					<Text>{text}</Text>
				</ButtonLink>
			) : (
				<ButtonNoLink
					bkg={bkg}
					border={border}
					fullwidth={fullwidth}
					onClick={onClick}
					small={small}
					id={id}
				>
					{icon}
					<Text>{text}</Text>
				</ButtonNoLink>
			)}
		</>
	);
}
