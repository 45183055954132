// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Components
import Nav from "../components/fragments/Nav";
import PageBanner from "../components/fragments/PageBanner";
import PriceEstimate from "../components/fragments/PriceEstimate";

// Text and header image
import { get_estimate_en } from "../data/page_content/en/pages/get_estimate_en";

// Styles
import { device } from "../components/styles/Layout";

const Img = styled.img`
	width: 105%;
	margin: 2rem 0 0 3rem;
	mix-blend-mode: luminosity;
	@media ${device.md} {
		width: 75%;
		margin: 10rem 0 0 3rem;
	}
`;

export default function GetEstimate() {
	useEffect(() => {
		window.scrollTo(0, 0);
	});

	return (
		<>
			<Helmet>
				<title>{get_estimate_en.seo.title}</title>
				<meta name="description" content={get_estimate_en.seo.description} />
			</Helmet>
			<Nav type="dark" />
			<PageBanner
				title={get_estimate_en.title}
				subtitle={get_estimate_en.subtitle}
				img={<Img src={get_estimate_en.header_img} />}
			/>
			<PriceEstimate />
		</>
	);
}
