// Dependencies
import React from "react";
import styled, { keyframes } from "styled-components";

// Styles
import { Container, device } from "../styles/Layout";

// Images
import { ReactComponent as ReactDuoColourLogo } from "../../assets/svg/react-duo-colour.svg";
import { ReactComponent as ShopifyDuoColourLogo } from "../../assets/svg/shopify-duo-colour.svg";

// Content
import { why_us_en } from "../../data/page_content/en/pages/why_us_en";

const slideIn = keyframes`
	from {
		transform: translateY(20px);
	} 
	to {
		transform: translateY(0);
	}
`;

const appear = keyframes`
	from {
		opacity:0;
	} 
	to {
		opacity:1;
	}
`;

const Wrapper = styled.section`
	background-image: ${(props) => props.theme.colours.gradientB_reverse};
`;

const Box = styled.div`
	display: flex;
	position: absolute;
	width: calc(100% - 4rem);
	background: ${(props) => props.theme.colours.gradientA};
	min-height: 21rem;
	margin-top: -18rem;
	box-shadow: 0 3px 8px 0px #00000047;
	z-index: 10;
	@media ${device.md} {
		display: block;
		position: relative;
		width: 100%;
	}

	animation: ${slideIn} 0.75s ease-in-out forwards;
`;

const BoxContainer = styled(Container)`
	position: relative;
	padding-top: 25rem;
	color: ${(props) => props.theme.colours.offwhite};
	& h2 {
		font-weight: 500;
		position: absolute;
	}
	& p {
		font-size: 16px;
		padding-top: 3rem;
	}

	@media ${device.md} {
		padding: 25rem 0 0 0;
		width: 100%;
		& h2 {
			line-height: 1.5;
		}
		& p {
			padding-top: 6rem;
		}
	}
`;

const BoxLeft = styled.div`
	flex: 1;
	padding: 6rem 3rem 3rem 3rem;
`;

const BoxRight = styled.div`
	flex: 1;
	padding: 6rem 3rem 3rem 3rem;
	@media ${device.md} {
		padding: 0rem 3rem 3rem 3rem;
		margin-top: -6rem;
	}
`;

const ReactLogo = styled(ReactDuoColourLogo)`
	position: absolute;
	margin-top: -3.46rem;
	margin-left: -2rem;

	opacity: 0;
	animation: ${appear} 0.5s ease-in-out 0.5s forwards;
`;

const ShopifyLogo = styled(ShopifyDuoColourLogo)`
	position: absolute;
	margin-top: -4.37rem;
	width: 110px;

	left: 58%;
	transform: translate(-58%, 0);

	opacity: 0;
	animation: ${appear} 0.5s ease-in-out 0.75s forwards;

	@media ${device.md} {
		display: none;
	}
`;

export default function HighlightBox() {
	return (
		<Wrapper>
			<BoxContainer>
				<Box>
					<ReactLogo />
					<ShopifyLogo />
					<BoxLeft>
						<h2>{why_us_en.highlight_box.title}</h2>
						<p>{why_us_en.highlight_box.left_col}</p>
					</BoxLeft>
					<BoxRight>
						<p>{why_us_en.highlight_box.right_col}</p>
					</BoxRight>
				</Box>
			</BoxContainer>
		</Wrapper>
	);
}
