export default function ValidateForm({ name, email, tel }) {
	let errors = { validationPassed: true };

	if (!name) {
		errors.name = "Please enter your name";
		errors.validationPassed = false;
	}

	if (!email) {
		errors.email = "Please enter your email";
		errors.validationPassed = false;
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
		errors.email = "Invalid email address";
		errors.validationPassed = false;
	}

	if (!tel) {
		errors.tel = null;
	} else if (!/^([0-9 -+-()]+)$/.test(tel)) {
		errors.tel = "Please enter a valid phone number or feel free to leave it blank";
		errors.validationPassed = false;
	}

	return errors;
}
