import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import lottie from "lottie-web";

import { ReactComponent as MacFrame } from "../../assets/svg/imac-frame-v2.svg";
import Devices3d from "../../assets/img/devices-3d-v4.png";
import Chart from "../../assets/img/chart-3d.png";
import Headset from "../../assets/img/headset-3d.png";

const scrollUp = keyframes`
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(-520px);
	}
	100% {
		transform: translateY(0px);
	}
`;

const IMacFrame = styled(MacFrame)`
	width: 500px;
	height: 500px;
`;

const Perspective = styled.div`
	perspective: 1400px;
	perspective-origin: 45% 50%;
`;

const ScreenContentBox = styled.div`
	position: absolute;
	overflow: hidden;
	margin-top: 399px;
	width: 539px;
	height: 279px;
	background: #1b1b1b;
	/* background: #e7e7e7; */
	opacity: 1;
	right: 0;
	z-index: 5;

	transform: rotateX(0.9deg) rotateY(319deg) rotateZ(5deg) translateX(-85px) translateY(-840px)
		translateZ(5px);
`;

const Wrapper = styled.div`
	position: absolute;

	// To ensure the intitial iMac position is correctly set agains the Service A section, the center points need to be slightly adjusted depending on the height of the sceen.

	// wrapper height 3950px (screen height 1400px)
	margin-top: ${(props) => props.height > 3950 && "40vh"};

	// wrapper height 3800px (screen height 1350px)
	margin-top: ${(props) => props.height < 3950 && props.height > 3800 && "41vh"};

	// wrapper height 2920px (screen height 1000px)
	margin-top: ${(props) => props.height < 3800 && props.height > 0 && "39vh"};

	right: 0;
	transform: translateY(-250px);
`;

const Img = styled.img`
	width: 100%;

	// Imposed images on the screen and automatically scrolled down and up. This didn't seem to look great when implemented.
	/* animation: ${scrollUp} 20s ease-in-out infinite; */

	transition: 0.5s;
	z-index: 5;

	opacity: ${(props) => (props.transition ? 0.5 : 1)};
	transform: ${(props) => (props.transition ? "translateY(-20px)" : "translateY(0)")};
`;

const Devices = styled.img`
	position: absolute;
	width: 290px;
	margin-top: -250px;
	margin-left: 170px;
	opacity: ${(props) => (props.transition ? 0 : 1)};
	transform: ${(props) =>
		props.transition ? "translate3d(100px, 0px, 5px);" : "translate3d(0, 0, 0)"};
	transition: 0.5s;
	z-index: 10;
`;

// Z-Index doesn't work on objects with css's rotate transform, so need to add here rotation value to keep the objects in front of the iMac screen if the browser is Safari
const ObjectWrap = styled.div`
	transform: ${(props) => (props.safari ? "rotate(0deg)" : "")};
`;

const PerspectiveLottie = styled.div`
	position: absolute;
	z-index: 10;
	height: 500px;
	margin-top: -555px;
	margin-left: 85px;
	overflow: hidden;
	transform: scale(1.95);
	opacity: 0.9;
`;

// Preload imposed images for smoother transitions
const preloadImages = (images) => {
	images.forEach((img) => {
		let preloadLink = document.createElement("link");
		preloadLink.href = img;
		preloadLink.rel = "preload";
		preloadLink.as = "image";
		document.head.appendChild(preloadLink);
	});
};

export default function IMac({
	height,
	imposedImg,
	transition,
	outerObject,
	outerObjectTransition,
}) {
	// Detect browser
	const [isSafari, setIsSafari] = useState(false);

	// Reference for lottie animation
	const lottieRef = useRef();

	useLayoutEffect(() => {
		// Preload all images that appear in front of the iMac once this components loads
		preloadImages([Devices3d, Chart, Headset]);
	}, []);

	useEffect(() => {
		// Detect Safari browser
		if (navigator.userAgent.match(/AppleWebKit/) && !navigator.userAgent.match(/Chrome/)) {
			setIsSafari(true);
		} else {
			setIsSafari(false);
		}

		// Load lottie animation
		setTimeout(() => {
			lottie.loadAnimation({
				name: "lines",
				container: lottieRef.current,
				renderer: "svg",
				loop: false,
				autoplay: true,
				animationData: require("../../assets/lottie/imac-perspective-lines-v2.json"),
			});
		}, 200);

		return () => {
			lottie.destroy("lines");
		};
	}, [outerObject]); // Updates only when outerObject changes

	return (
		<Controller>
			<Scene duration={height - 500} pin>
				<Wrapper height={height}>
					<IMacFrame />
					<Perspective>
						{/* Display imposed screen graphics */}
						<ScreenContentBox>
							<Img src={imposedImg} transition={transition} />
						</ScreenContentBox>

						{/* Display outside objects */}
						{outerObject === "devices" && (
							<ObjectWrap safari={isSafari}>
								<Devices src={Devices3d} transition={outerObjectTransition} />
							</ObjectWrap>
						)}

						{outerObject === "lines" && (
							<PerspectiveLottie ref={lottieRef} transition={outerObjectTransition} />
						)}

						{outerObject === "charts" && (
							<ObjectWrap safari={isSafari}>
								<Devices src={Chart} transition={outerObjectTransition} />
							</ObjectWrap>
						)}
						{outerObject === "support" && (
							<ObjectWrap safari={isSafari}>
								<Devices src={Headset} transition={outerObjectTransition} />
							</ObjectWrap>
						)}
					</Perspective>
				</Wrapper>
			</Scene>
		</Controller>
	);
}
