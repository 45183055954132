// Dependancies
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import lottie from "lottie-web";
import { Controller, Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";

// Styles
import { device, Grid } from "../styles/Layout";

// Images & SVG
import Stripe from "../../assets/svg/stripe-white-opaque.svg";

// Hooks
import useIntersection from "../hooks/useIntersection";

// Text content
import { home_en } from "../../data/page_content/en/pages/home_en";

const distance = 4;
const border = 0.3;

const stream = keyframes`
    0% {background-position-x: 0px}
    100% {background-position-x: 25px}
`;

const fadeIn = keyframes`
    from {
        opacity:0
    } to {
        opacity:1
    }
`;

const DesktopVersionWrapper = styled.div`
	background: ${(props) => props.theme.colours.offwhite};
	/* overflow: hidden; */
`;

const UpperBox = styled.div`
	height: 12rem;
	background: #f2f2f2;
	position: relative;
	border-bottom: ${border + "rem"} ${(props) => props.theme.colours.primary} solid;
	box-shadow: -6px 6px 6px 0px #8a8a8a24;

	@media ${device.xl} {
		width: 90vw;
		margin: 0 auto 4rem auto;
	}
`;

const LowerBox = styled.div`
	height: 12rem;
	background: #f2f2f2;
	margin-top: ${distance + "rem"};
	border-top: ${border + "rem"} ${(props) => props.theme.colours.primary} solid;
	box-shadow: -6px 6px 6px 0px #8a8a8a24;
`;

const ProgressBar = styled.div`
	height: 2.5rem;
	width: 100%;
	background-color: ${(props) => props.theme.colours.primary};
	background-image: url(${Stripe});
	animation: ${stream} 4s infinite;
	animation-timing-function: linear;
	margin-top: ${distance + "rem"};
`;

const IconAndTextWrap = styled.div`
	display: flex;
	height: 5.3rem;
	padding: 2rem 2rem 2rem 1rem;
	text-align: center;

	@media ${device.xl} {
		padding: 2rem 1rem;
	}
`;

const Text = styled.p`
	color: ${(props) => props.theme.colours.black};
	font-size: 16px;
	text-align: left;
	flex: 3;
	padding-left: 1rem;
	align-self: center;
`;
const Title = styled.h3`
	display: block;
	color: ${(props) => props.theme.colours.black};
	margin-top: ${(props) => (props.lower ? "1.9rem" : "-.45rem")};
	margin-bottom: ${(props) => (props.lower ? "-.5rem" : "2rem")};
	padding-bottom: ${(props) => props.highlight && "1rem"};
	padding-top: ${(props) => props.highlight && "1rem"};

	text-align: center;
	font-weight: 300;
	background: ${(props) => props.highlight && props.theme.colours.primary};
	color: ${(props) => props.highlight && "white"};
`;

const Stalk = styled.div`
	height: ${distance + border + "rem"};
	background: ${(props) => props.theme.colours.primary};
	width: 0.5rem;
	position: relative;
	margin: 0 auto;
	margin-top: ${(props) => props.highlight && "-2rem"};
	margin-top: ${(props) => props.lower && "-4.4rem"};
`;

const Icon = styled.div`
	flex: 1;
`;

const TopNumbersWrap = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 4rem;
	position: absolute;
	z-index: 10;
	width: 100%;
	margin-top: -18.05rem;
	animation: ${fadeIn} 2s forwards;
	animation-play-state: ${(props) => (props.animate ? "running" : "paused")};
`;

const BottomNumbersWrap = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 4rem;
	position: absolute;
	z-index: 2;
	width: 60%;
	left: 20%;
	margin: -18.05rem auto 0 auto;
	animation: ${fadeIn} 2s forwards;
	animation-play-state: ${(props) => (props.animate ? "running" : "paused")};
`;

const Number = styled.div`
	font-family: "Bai Jamjuree";
	color: white;
	font-size: 16px;
	text-align: center;

	padding-left: ${(props) => props.topleft && "5.4rem"};
	padding-right: ${(props) => props.topright && "5.4rem"};
`;

const MobileVersionWrapper = styled.div`
	display: block;
`;

// Individual inline style adjustments
const UpperGrid = {
	padding: "0 4rem",
};

const LowerGrid = {
	width: "60%",
	margin: "0 auto",
};

export default function Flowchart() {
	// Set container references
	const containerOne = useRef(null);
	const containerTwo = useRef(null);
	const containerThree = useRef(null);
	const containerFour = useRef(null);
	const containerFive = useRef(null);

	const [animate, setAnimate] = useState(false);
	const ref = useRef();
	const inViewport = useIntersection(ref, "-100px");

	const [isMobile, setIsMobile] = useState(false);

	useLayoutEffect(() => {
		if (inViewport) {
			setAnimate(true);
		}
	}, [inViewport]);

	useEffect(() => {
		setTimeout(() => {
			lottie.loadAnimation({
				name: "strategy",
				container: containerOne.current,
				renderer: "svg",
				loop: true,
				autoplay: false,
				animationData: require("../../assets/lottie/project-management.json"),
			});

			lottie.loadAnimation({
				name: "ux-ui",
				container: containerTwo.current,
				renderer: "svg",
				loop: true,
				autoplay: false,
				animationData: require("../../assets/lottie/ux-ui.json"),
			});

			lottie.loadAnimation({
				name: "web-development",
				container: containerThree.current,
				renderer: "svg",
				loop: true,
				autoplay: false,
				animationData: require("../../assets/lottie/web-development.json"),
			});

			lottie.loadAnimation({
				name: "launch",
				container: containerFour.current,
				renderer: "svg",
				loop: true,
				autoplay: false,
				animationData: require("../../assets/lottie/launch.json"),
			});

			lottie.loadAnimation({
				name: "support",
				container: containerFive.current,
				renderer: "svg",
				loop: true,
				autoplay: false,
				animationData: require("../../assets/lottie/marketing.json"),
			});
		}, 200);

		function checkWindowSize() {
			if (window.innerWidth > device.xlarge) return setIsMobile(false);
			if (window.innerWidth < device.xlarge) return setIsMobile(true);
		}

		window.addEventListener("resize", checkWindowSize);

		return () => {
			lottie.destroy();
			window.removeEventListener("resize", checkWindowSize);
		};
	}, [isMobile]);

	return (
		<>
			{/* To avoid rendering duplicate lottie icons for desktop and mobile, render only the required version depending on the device / screen size.  */}
			{window.innerWidth > device.xlarge ? (
				<DesktopVersionWrapper>
					<Controller>
						<Scene
							triggerHook={100}
							offset={700}
							duration={200}
							pin={{ pushFollowers: false }}
							triggerElement={null}
						>
							{(progress) => (
								<Timeline
									totalProgress={progress}
									paused
									target={
										<Grid lg={3} gap="4rem" style={UpperGrid}>
											<UpperBox
												ref={ref}
												onMouseEnter={() => lottie.play("strategy")}
												onMouseLeave={() => lottie.pause("strategy")}
											>
												<IconAndTextWrap>
													<Icon ref={containerOne} />
													<Text>{home_en.flowchart.subtitle1}</Text>
												</IconAndTextWrap>
												<Title>{home_en.flowchart.title1}</Title>
												<Stalk />
											</UpperBox>

											<UpperBox
												ref={ref}
												onMouseEnter={() => lottie.play("web-development")}
												onMouseLeave={() => lottie.pause("web-development")}
											>
												<IconAndTextWrap>
													<Icon ref={containerThree} />
													<Text>{home_en.flowchart.subtitle3}</Text>
												</IconAndTextWrap>
												<Title highlight>{home_en.flowchart.title3}</Title>
												<Stalk highlight />
											</UpperBox>
											<UpperBox
												ref={ref}
												onMouseEnter={() => lottie.play("support")}
												onMouseLeave={() => lottie.pause("support")}
											>
												<IconAndTextWrap>
													<Icon ref={containerFive} />
													<Text>{home_en.flowchart.subtitle5}</Text>
												</IconAndTextWrap>
												<Title>{home_en.flowchart.title5}</Title>
												<Stalk />
											</UpperBox>
										</Grid>
									}
								>
									<Tween from={{ translateY: "30px" }} to={{ translateY: "0" }} />
								</Timeline>
							)}
						</Scene>

						<ProgressBar />

						<Scene
							triggerHook={100}
							offset={800}
							duration={200}
							pin={{ pushFollowers: false }}
							triggerElement={null}
						>
							{(progress) => (
								<Timeline
									totalProgress={progress}
									paused
									target={
										<Grid lg={2} gap="4rem" style={LowerGrid}>
											<LowerBox
												ref={ref}
												onMouseEnter={() => lottie.play("ux-ui")}
												onMouseLeave={() => lottie.pause("ux-ui")}
											>
												<Stalk lower />
												<Title lower>{home_en.flowchart.title2}</Title>
												<IconAndTextWrap>
													<Icon ref={containerTwo} />
													<Text lower>{home_en.flowchart.subtitle2}</Text>
												</IconAndTextWrap>
											</LowerBox>
											<LowerBox
												ref={ref}
												onMouseEnter={() => lottie.play("launch")}
												onMouseLeave={() => lottie.pause("launch")}
											>
												<Stalk lower />
												<Title lower>{home_en.flowchart.title4}</Title>
												<IconAndTextWrap>
													<Icon ref={containerFour} />
													<Text lower>{home_en.flowchart.subtitle4}</Text>
												</IconAndTextWrap>
											</LowerBox>
										</Grid>
									}
								>
									<Tween from={{ translateY: "-30px" }} to={{ translateY: "0" }} />
								</Timeline>
							)}
						</Scene>
					</Controller>
					<TopNumbersWrap ref={ref} animate={animate}>
						<Number topleft>1</Number>
						<Number>3</Number>
						<Number topright>5</Number>
					</TopNumbersWrap>
					<BottomNumbersWrap ref={ref} animate={animate}>
						<Number>2</Number>
						<Number>4</Number>
					</BottomNumbersWrap>
				</DesktopVersionWrapper>
			) : (
				<MobileVersionWrapper>
					<UpperBox ref={ref}>
						<IconAndTextWrap>
							<Icon ref={containerOne} />
							<Text>{home_en.flowchart.subtitle1}</Text>
						</IconAndTextWrap>
						<Title>{home_en.flowchart.title1}</Title>
						<Stalk />
					</UpperBox>
					<UpperBox ref={ref}>
						<IconAndTextWrap>
							<Icon ref={containerTwo} />
							<Text>{home_en.flowchart.subtitle2}</Text>
						</IconAndTextWrap>
						<Title>{home_en.flowchart.title2}</Title>
						<Stalk />
					</UpperBox>
					<UpperBox ref={ref}>
						<IconAndTextWrap>
							<Icon ref={containerThree} />
							<Text>{home_en.flowchart.subtitle3}</Text>
						</IconAndTextWrap>
						<Title highlight>{home_en.flowchart.title3}</Title>
						<Stalk highlight />
					</UpperBox>
					<UpperBox ref={ref}>
						<IconAndTextWrap>
							<Icon ref={containerFour} />
							<Text>{home_en.flowchart.subtitle4}</Text>
						</IconAndTextWrap>
						<Title>{home_en.flowchart.title4}</Title>
						<Stalk />
					</UpperBox>
					<UpperBox ref={ref}>
						<IconAndTextWrap>
							<Icon ref={containerFive} />
							<Text>{home_en.flowchart.subtitle5}</Text>
						</IconAndTextWrap>
						<Title>{home_en.flowchart.title5}</Title>
					</UpperBox>
				</MobileVersionWrapper>
			)}
		</>
	);
}
