// Dependencies
import React from "react";
import styled, { keyframes } from "styled-components";
import { Container, device } from "../styles/Layout";

// Components
import PageBanner from "./PageBanner";

// Text & images
import { our_work_en } from "../../data/page_content/en/pages/our_work_en";

const Wrapper = styled.section`
	background-image: ${(props) => props.theme.colours.gradientB_reverse};
	padding-bottom: 9rem;
	padding-top: 6rem;
`;

const GalleryWrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 3rem;
	grid-column-gap: 3rem;
	@media ${device.lg} {
		grid-template-columns: 1fr 1fr;
	}
	@media ${device.md} {
		grid-template-columns: 1fr;
	}
`;

const GalleryItem = styled.div`
	display: block;
	background: white;
	padding: 1.5rem;
	box-shadow: ${(props) => props.theme.effects.boxShadow};
`;

const Img = styled.img`
	width: 100%;
	transition: all 0.25s ease-in-out;
	clip-path: inset(0);
`;

const Title = styled.h3`
	/* font-family: ${(props) => props.theme.fonts.title}; */
	padding: 1rem 0 0.5rem 0;
	font-weight: 300;
	transition: ${(props) => props.theme.effects.transition};
	color: ${(props) => props.theme.colours.black};
	& a {
		text-decoration: none;
		color: ${(props) => props.theme.colours.black};
		transition: ${(props) => props.theme.effects.transition};
	}
	& a:hover {
		text-decoration: underline;
		transition: ${(props) => props.theme.effects.transition};
	}
`;

const Text = styled.p`
	font-size: 16px;
	color: ${(props) => props.theme.colours.black};
`;

export default function OurWorkCompact() {
	return (
		<>
			<PageBanner
				title={our_work_en.title}
				subtitle={our_work_en.subtitle}
				img={<Img src={our_work_en.header_img} />}
			/>
			<Wrapper>
				<Container>
					<GalleryWrap>
						{our_work_en.gallery.map((item, index) => (
							<GalleryItem key={index}>
								<Img src={item.img} />
								<Title>
									<a href={item.link} target="_blank" rel="noopener noreferrer">
										{item.title}
									</a>
								</Title>
								<Text>{item.text}</Text>
							</GalleryItem>
						))}
					</GalleryWrap>
				</Container>
			</Wrapper>
		</>
	);
}
