// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Components
import Nav from "../components/fragments/Nav";
import PageBanner from "../components/fragments/PageBanner";
import HighlightBox from "../components/fragments/HighlightBox";
import GridIcons from "../components/fragments/GridIcons";
import WhyUsExplained from "../components/fragments/WhyUsExplained";
import ContactFormA from "../components/fragments/ContactFormA";

// Styles
import { device } from "../components/styles/Layout";

// Content & header image
import { why_us_en } from "../data/page_content/en/pages/why_us_en";

const Img = styled.img`
	width: 107%;
	margin: 3rem 0 0 0rem;
	mix-blend-mode: luminosity;
	@media ${device.md} {
		width: 95%;
		margin: 3.5rem 0 0 0;
	}
`;

export default function WhyUs() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Helmet>
				<title>{why_us_en.seo.title}</title>
				<meta name="description" content={why_us_en.seo.description} />
			</Helmet>
			<Nav type="dark" currentPageUri="/why-us" />
			<PageBanner
				title={why_us_en.title}
				subtitle={why_us_en.subtitle}
				img={<Img src={why_us_en.header_img} />}
				imagePos="top"
			/>
			<HighlightBox />
			<GridIcons />
			<WhyUsExplained />
			<ContactFormA />
		</>
	);
}
