import React from "react";
import styled from "styled-components";

// Testimonials slider
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/dist/css/splide.min.css";

import { device } from "../styles/Layout";

// Backup Testimonials slider if Splide starts to feel slow
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";

// Text & images
// import { our_work_en } from "../../data/page_content/en/pages/our_work_en";
import { ReactComponent as Quote } from "../../assets/svg/quote-mark.svg";
import { ReactComponent as Trustpilot } from "../../assets/svg/trustpilot-logo.svg";
import { ReactComponent as TrustpilotStars } from "../../assets/svg/trustpilot-5-stars.svg";

const Wrapper = styled.div`
	margin: 8rem 0 0 0;
	& .splide__arrow {
		background: none;
	}
`;

const ReviewWrapper = styled.div`
	padding: 0 10vw;
	@media ${device.md} {
		padding: 0;
	}
`;

const QuoteWrapper = styled.div`
	& svg {
		width: 5rem;
		margin-top: 0.5rem;

		@media ${device.md} {
			width: 2rem;
		}
	}
`;

const ReviewTextWrap = styled.div`
	display: flex;
	align-items: flex-start;
	column-gap: 2rem;
	border-bottom: 1px solid grey;
	padding-bottom: 1rem;
`;

const ReviewText = styled.p`
	/* font-size: 16px; */
	@media ${device.md} {
		font-size: 1rem;
		line-height: 1.8;
	}
`;

const TrustpilotLogo = styled(Trustpilot)`
	width: 8rem;
`;

const Stars = styled(TrustpilotStars)`
	width: 7rem;
	height: auto;
`;

const CreditsWrap = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
`;

const RightWrap = styled.div`
	display: flex;
	@media ${device.md} {
		display: block;
	}
`;

const Author = styled.div`
	align-self: center;
	padding-right: 1rem;
	font-weight: 500;
	@media ${device.md} {
		font-size: 1rem;
		line-height: 1.8;
	}
`;

const reviews = [
	{
		text: "By far one of the best web design services that I could possibly get. The day my business needed major revamping and branding I started searching online. NW Development was one of the few that looked as good as they promised. Everything seemed in place to put my trust in them and let me tell you during the entire course of the task there was not a single second where I thought that they were concerned about the money. All they focused on was how satisfied I was with the work and whether I was looking for any edits.",
		author: "Emma Graham",
	},
	{
		text: "Francis and his team developed our website which focuses on health services in and around Belfast. Over the years they continually improved our site and provided excellent support no matter how fussy we are. Alongside taking care of our website needs they also provided invaluable support to our SEO strategy with top page results. We strongly recommend them if you’re looking for a friendly and professional team to work with!",
		author: "Dace Delaney",
	},
	{
		text: "What a great experience to work with the team at NWD. From first engagement to execution, they were helpful, enthusiastic, responsive and always two steps ahead. I cannot overstate how happy we are with the website that Francis and his team put together. New Windows really has a gift for taking the client's vision and turning it into a reality. Highly recommend them and excited to keep working together!",
		author: "Rob Barker",
	},
];

export default function TestimonialsSlider() {
	return (
		<Wrapper>
			<Splide
				aria-label="Testimonials"
				options={{
					type: "loop",
					speed: 1250,
					autoplay: true,
					pagination: false,
					breakpoints: {
						768: {
							arrows: false,
						},
					},
				}}
			>
				{reviews.map((item, index) => (
					<SplideSlide index={index}>
						<ReviewWrapper>
							<ReviewTextWrap>
								<QuoteWrapper>
									<Quote />
								</QuoteWrapper>
								<ReviewText>{item.text}</ReviewText>
							</ReviewTextWrap>

							<CreditsWrap>
								<TrustpilotLogo />
								<RightWrap>
									<Author>{item.author}</Author>
									<Stars />
								</RightWrap>
							</CreditsWrap>
						</ReviewWrapper>
					</SplideSlide>
				))}
			</Splide>

			{/* <Carousel 
				autoPlay="true" 
				infiniteLoop="true" 
				interval="3000" 
				showIndicators="false" 
			>
			{reviews.map((item, index) => (
				<ReviewWrapper index={index}>
					<ReviewTextWrap>
						<QuoteWrapper>
							<Quote />
						</QuoteWrapper>
						<ReviewText>{item.text}</ReviewText>
					</ReviewTextWrap>

					<CreditsWrap>
						<TrustpilotLogo />
						<RightWrap>
							<Author>{item.author}</Author>
							<Stars />
						</RightWrap>
					</CreditsWrap>
				</ReviewWrapper>
				))}
			</Carousel> */}
		</Wrapper>
	);
}
