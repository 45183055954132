import { calendly_link_en } from "./page_content/en/company_data/calendly_link_en";

export const TopNav = [
	{
		title: "Why Us",
		link: "/why-us",
		color: "#8860D0",
	},
	{
		title: "Our Work",
		link: "/our-work",
		color: "#5AB9EA",
	},
	{
		title: "Team",
		link: "/team",
		color: "#5680E9",
	},
	{
		title: "Contact",
		link: "/contact",
		color: "#183149",
	},
];

export const MobileNav = {
	primary: [
		{
			title: "Home",
			link: "/",
		},
		{
			title: "Why Us",
			link: "/why-us",
		},
		{
			title: "Our Work",
			link: "/our-work",
		},
		{
			title: "Team",
			link: "/team",
		},
		{
			title: "Contact",
			link: "/contact",
		},
	],
	secondary: [
		{
			title: "Get a Price Estimate",
			link: "/get-estimate",
		},
		{
			title: "Schedule a Call",
			link: calendly_link_en,
		},
	],
};

export const FooterNav = [
	{
		title: "Home",
		link: "/",
	},
	{
		title: "Why Us",
		link: "/why-us",
	},
	{
		title: "Our Work",
		link: "/our-work",
	},
	{
		title: "Team",
		link: "/team",
	},
	{
		title: "Contact",
		link: "/contact",
	},
	{
		title: "Get an Estimate",
		link: "/get-estimate",
	},
	// Shecdule a video call link inserted in the footer component directly
];
