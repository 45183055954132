import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Container } from "../styles/Layout";
import useIntersection from "../hooks/useIntersection";

const appearFromTop = keyframes`
	from {
		opacity:0;
		transform:translateY(-20px);
	} to {
		opacity:1;
		transform:translateY(0);
	}
`;

const Wrapper = styled.section``;

const Title = styled.h1`
	color: ${(props) => props.theme.colours.black};
	margin-bottom: 2rem;
	animation: ${appearFromTop} 1s;
	animation-play-state: ${(props) => (props.animate ? "running" : "paused")};
`;

const Subtitle = styled.p`
	color: ${(props) => props.theme.colours.black};
	max-width: 40rem;
	margin: 0 auto;
	animation: ${appearFromTop} 1s;
	animation-play-state: ${(props) => (props.animate ? "running" : "paused")};
`;

const TitleContainer = styled.div`
	display: block;
	text-align: center;
	margin: 8rem auto;
`;

export default function Section({ title, subtitle, id }) {
	const [animate, setAnimate] = useState(false);
	const ref = useRef();
	const inViewport = useIntersection(ref, "-100px");

	useEffect(() => {
		if (inViewport) {
			setAnimate(true);
		}
	}, [inViewport]);

	return (
		<Wrapper id={id}>
			<Container>
				<TitleContainer>
					<Title ref={ref} animate={animate}>
						{title}
					</Title>
					<Subtitle ref={ref} animate={animate}>
						{subtitle}
					</Subtitle>
				</TitleContainer>
			</Container>
		</Wrapper>
	);
}
