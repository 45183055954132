// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Components
import Nav from "../components/fragments/Nav";
import PageBanner from "../components/fragments/PageBanner";
import OurWorkGallery from "../components/fragments/OurWorkGallery";

// Text content & header image
import { our_work_en } from "../data/page_content/en/pages/our_work_en";
import { device } from "../components/styles/Layout";
// import BrandsGrid from "../components/fragments/BrandsGrid";

const Img = styled.img`
	width: 90%;
	margin: 3rem 0 0 3rem;
	mix-blend-mode: luminosity;
	@media ${device.md} {
		width: 75%;
		margin: 5.8rem 0 0 3rem;
	}
	@media ${device.sm} {
		width: 75%;
		margin: 4rem 0 0 3rem;
	}
`;

export default function OurWork() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Helmet>
				<title>{our_work_en.seo.title}</title>
				<meta name="description" content={our_work_en.seo.description} />
				{/* <meta name="robots" content="noindex" /> */}
			</Helmet>
			<Nav type="dark" />
			<PageBanner
				title={our_work_en.title}
				subtitle={our_work_en.subtitle}
				img={<Img src={our_work_en.header_img} />}
			/>
			<OurWorkGallery />
			{/* <BrandsGrid /> */}
		</>
	);
}
