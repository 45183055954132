// Dependencies
import React from "react";
import styled, { keyframes } from "styled-components";

// Styles
import { Container, device } from "../styles/Layout";

// Icons
import { ReactComponent as ResponsiveDesignIcon } from "../../assets/svg/cov-design-responsive.svg";
import { ReactComponent as BestLayoutIcon } from "../../assets/svg/cov-best-layout.svg";
import { ReactComponent as EasyNavIcon } from "../../assets/svg/cov-easy-navigation.svg";
import { ReactComponent as SeoIcon } from "../../assets/svg/cov-seo.svg";
import { ReactComponent as BrowserFriendlyIcon } from "../../assets/svg/cov-browser-friendly.svg";
import { ReactComponent as PerformanceIcon } from "../../assets/svg/cov-performance.svg";
import { ReactComponent as CodeIcon } from "../../assets/svg/cov-code.svg";
import { ReactComponent as HostingIcon } from "../../assets/svg/cov-hosting.svg";

// Text content
import { why_us_en } from "../../data/page_content/en/pages/why_us_en";

const appearFromTop = keyframes`
	from {
		opacity:0;
		transform:translateY(20px);
	} to {
		opacity:1;
		transform:translateY(0);
	}
`;

const Wrapper = styled.section`
	box-shadow: 0px 0px 11px 11px #00000029;
	padding-top: 9rem;
`;

const TitleContainer = styled.div`
	text-align: left;
	animation: ${appearFromTop} 1s ease-in-out;
`;

const Title = styled.h1`
	color: ${(props) => props.theme.colours.black};
	margin-bottom: 2rem;
`;

const Subtitle = styled.p`
	color: ${(props) => props.theme.colours.black};
	max-width: 35rem;
`;

const CardGridWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-row-gap: 6rem;
	grid-column-gap: 3rem;
	padding: 6rem 0 9rem 0;
	@media ${device.lg} {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@media ${device.md} {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 0;
		margin-top: 3rem;
	}
`;

const Card = styled.div`
	text-align: center;
	@media ${device.md} {
		& svg {
			// Icon
			height: 5rem;
		}
	}
`;

const CardTitle = styled.h3`
	padding-top: 1rem;
	margin-top: 1rem;
	font-weight: 300;
	color: ${(props) => props.theme.colours.black};
`;

export default function GridIcons() {
	return (
		<Wrapper>
			<Container>
				<TitleContainer>
					<Title>{why_us_en.grid_icons.title}</Title>
					<Subtitle>{why_us_en.grid_icons.subtitle}</Subtitle>
				</TitleContainer>
				<CardGridWrapper>
					<Card>
						<ResponsiveDesignIcon />
						<CardTitle>{why_us_en.grid_icons.icon_names[0]}</CardTitle>
					</Card>
					<Card>
						<BestLayoutIcon />
						<CardTitle>{why_us_en.grid_icons.icon_names[1]}</CardTitle>
					</Card>
					<Card>
						<EasyNavIcon />
						<CardTitle>{why_us_en.grid_icons.icon_names[2]}</CardTitle>
					</Card>
					<Card>
						<SeoIcon />
						<CardTitle>{why_us_en.grid_icons.icon_names[3]}</CardTitle>
					</Card>
					<Card>
						<BrowserFriendlyIcon />
						<CardTitle>{why_us_en.grid_icons.icon_names[4]}</CardTitle>
					</Card>
					<Card>
						<PerformanceIcon />
						<CardTitle>{why_us_en.grid_icons.icon_names[5]}</CardTitle>
					</Card>
					<Card>
						<CodeIcon />
						<CardTitle>{why_us_en.grid_icons.icon_names[6]}</CardTitle>
					</Card>
					<Card>
						<HostingIcon />
						<CardTitle>{why_us_en.grid_icons.icon_names[7]}</CardTitle>
					</Card>
				</CardGridWrapper>
			</Container>
		</Wrapper>
	);
}
