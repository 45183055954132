// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Components
import Nav from "../components/fragments/Nav";
import PageBanner from "../components/fragments/PageBanner";
import TeamGrid from "../components/fragments/TeamGrid";

// Styles
import { device } from "../components/styles/Layout";

// Content and header image
import { team_en } from "../data/page_content/en/pages/team_en";

const Img = styled.img`
	width: 107%;
	margin: 3rem 0 0 0rem;
	mix-blend-mode: luminosity;
	@media ${device.md} {
		width: 95%;
		margin: 4rem 0 0 0;
	}
	@media ${device.sm} {
		width: 95%;
		margin: 2rem 0 0 0;
	}
`;

export default function Team() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Helmet>
				<title>{team_en.seo.title}</title>
				<meta name="description" content={team_en.seo.description} />
				{/* <meta name="robots" content="noindex" /> */}
			</Helmet>
			<Nav type="dark" />
			<PageBanner
				title={team_en.title}
				subtitle={team_en.subtitle}
				img={<Img src={team_en.header_img} />}
			/>
			<TeamGrid />
		</>
	);
}
