import React from "react";
import styled from "styled-components";
import { Container } from "../styles/Layout";

const Wrapper = styled.section`
	background-image: ${(props) => props.theme.colours.gradientB_reverse};
	padding-bottom: 9rem;
`;

export default function BasicPage({ children }) {
	return (
		<Wrapper>
			<Container>{children}</Container>
		</Wrapper>
	);
}
