// Dependencies
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styled, { keyframes } from "styled-components";
import { useCookies } from "react-cookie";
import ProgressiveImage from "react-progressive-image";

// Layout
import { Container, device } from "../styles/Layout";

// Text & images
import { our_work_en } from "../../data/page_content/en/pages/our_work_en";
import { ReactComponent as Cross } from "../../assets/svg/close_black_24dp.svg";
import TestimonialsSlider from "./TestimonialsSlider";

const slideIn = keyframes`
	from {
		transform: translateY(20px);
	} 
	to {
		transform: translateY(0);
	}
`;

const Wrapper = styled.section`
	background-image: ${(props) => props.theme.colours.gradientB_reverse};
	padding-bottom: 9rem;
	padding-top: 6rem;
	@media ${device.md} {
		margin-bottom: ${(props) => props.mobileMarginBottom};
	}
`;

const GalleryWrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 3rem;
	grid-column-gap: 3rem;
	@media ${device.lg} {
		grid-template-columns: 1fr 1fr;
	}
	@media ${device.md} {
		grid-template-columns: 1fr;
	}

	& > div {
		transform: translateY(20px);
	}

	// GalleryItem
	& > div:nth-child(1) {
		animation: ${slideIn} 1s ease-in-out forwards;
	}

	& > div:nth-child(2) {
		animation: ${slideIn} 1s ease-in-out 0.125s forwards;
	}

	& > div:nth-child(3) {
		animation: ${slideIn} 1s ease-in-out 0.25s forwards;
	}

	& > div:nth-child(4) {
		animation: ${slideIn} 1s ease-in-out 0.375s forwards;
	}

	& > div:nth-child(5) {
		animation: ${slideIn} 1s ease-in-out 0.5s forwards;
	}

	& > div:nth-child(6) {
		animation: ${slideIn} 1s ease-in-out 0.625s forwards;
	}

	& > div:nth-child(n + 7) {
		/* opacity: 0; */
		animation: ${slideIn} 1s ease-in-out 0.75s forwards;
	}
`;

const GalleryItemWrap = styled.div`
	cursor: pointer;
`;

const GalleryItem = styled.div`
	display: block;
	background: white;
	padding: 1.5rem;
	box-shadow: ${(props) => props.theme.effects.boxShadow};
	transition: all 0.25s ease-in-out;
	&:hover {
		transform: translateY(-1rem);
		transition: all 0.25s ease-in-out;
	}
`;

const Img = styled.img`
	width: 100%;
	transition: all 0.25s ease-in-out;
	clip-path: inset(0);
`;

const Title = styled.h3`
	/* font-family: ${(props) => props.theme.fonts.title}; */
	padding: 1rem 0 0.5rem 0;
	font-weight: 300;
	transition: ${(props) => props.theme.effects.transition};
	color: ${(props) => props.theme.colours.black};
	& a {
		text-decoration: none;
		color: ${(props) => props.theme.colours.black};
		transition: ${(props) => props.theme.effects.transition};
	}
	& a:hover {
		text-decoration: underline;
		transition: ${(props) => props.theme.effects.transition};
	}
`;

const Text = styled.p`
	font-size: 16px;
	color: ${(props) => props.theme.colours.black};
`;

const ModalContent = styled.div`
	overflow: scroll;
`;

const CaseStudyTitle = styled.h1`
	margin: 4rem 0 0 4rem;
	@media ${device.lg} {
		margin: 2rem;
	}
`;
const CaseStudySubtitle = styled.p`
	margin: 0 0 4rem 4rem;
	@media ${device.lg} {
		margin: 0 0 4rem 2rem;
	}
`;
const RowTextWrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2rem;
	margin: 4rem;
	@media ${device.lg} {
		grid-template-columns: 1fr;
		grid-row-gap: 2rem;
		margin: 2rem;
	}
`;
const TextBlockWrap = styled.div``;

const TextBlockTitle = styled.h3`
	margin-bottom: 1rem;
`;
const TextBlock = styled.p`
	font-size: 16px;
	line-height: 1.75rem;
`;

const CaseStudyImageWrap = styled.div`
	padding: ${(props) => props.right && "0 4rem 0 0"};
	padding: ${(props) => props.left && "0 0 0 4rem"};
	@media ${device.lg} {
		padding: 0;
	}
`;

const CaseStudyImage = styled.img`
	width: 100%;
`;

const CloseBtn = styled.button`
	position: fixed;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	top: 0.4rem;
	z-index: 9999999;
	border: none;
	background: none;
	& svg {
		fill: white;
		width: 2rem;
		height: 2rem;
	}
`;

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgba(31,31,31,0.4)";

export default function OurWorkGallery({ mobileMarginBottom }) {
	// To avoid the blur effect when the image is already in browser's cache, store a cookie in the browser after the first render. If the cookie is present, the original image will be used instead of progressive image.

	const [cookies, setCookie] = useCookies(["our_work_gallery_cookie"]);
	const [cookieIsSet, setCookieIsSet] = useState(false);

	const [modalContent, setModalContent] = useState({});
	const [modalIsOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (!cookies.our_work_gallery_cookie) {
			console.log("Blur now!");

			// let cookieExpiry = new Date(new Date().getTime() + 15000); // 15 seconds (for testing purposes)
			let cookieExpiry = new Date(new Date().getTime() + 15 * 60 * 1000); // 15 minutes
			setCookie("our_work_gallery_cookie", "true", { path: "/", expires: cookieExpiry });

			setTimeout(() => {
				setCookieIsSet(true);
			}, 1500);
		} else {
			setCookieIsSet(true);
		}
	}, [cookieIsSet]);

	function openModal(item) {
		console.log(item);

		setModalContent(item);
		setIsOpen(true);
		document.querySelector(".ReactModalPortal").style.opacity = "1";

		setTimeout(() => {
			document.querySelector(".ReactModal__Content").style.marginTop = "2rem";
		}, 50);

		if (document.querySelector("iframe")) {
			setTimeout(() => {
				document.querySelector("iframe").remove();
			}, 750);
		}
	}

	function afterOpenModal() {
		console.log("Stuff happens");
	}

	function closeModal() {
		document.querySelector(".ReactModalPortal").style.opacity = "0";
		document.querySelector(".ReactModal__Content").style.marginTop = "3rem";

		setTimeout(() => {
			setIsOpen(false);
		}, 500);
	}

	return (
		<>
			<Wrapper mobileMarginBottom={mobileMarginBottom}>
				<Container>
					<GalleryWrap>
						{our_work_en.gallery.map((item, index) => (
							<GalleryItemWrap key={index} onClick={() => openModal(item)}>
								<GalleryItem>
									{!cookieIsSet ? (
										<ProgressiveImage src={item.img} placeholder={item.imgTiny} delay={0}>
											{(src, loading) => (
												<Img
													style={{ filter: loading ? "blur(10px)" : "blur(0)" }}
													src={src}
													alt={item.title}
												/>
											)}
										</ProgressiveImage>
									) : (
										<Img src={item.img} />
									)}

									<Title>
										{/* <a href={item.link} target="_blank" rel="noopener noreferrer"> */}
										{item.title}
										{/* </a> */}
									</Title>
									<Text>{item.text}</Text>
								</GalleryItem>
							</GalleryItemWrap>
						))}
					</GalleryWrap>
					<TestimonialsSlider />
				</Container>
			</Wrapper>

			{/* <ModalCloseBtnWrap> */}
			{modalIsOpen && (
				<CloseBtn onClick={closeModal}>
					<Cross />
				</CloseBtn>
			)}
			{/* </ModalCloseBtnWrap> */}

			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Case Study Modal"
			>
				{modalContent.caseStudy && (
					<ModalContent>
						<CaseStudyTitle>{modalContent.caseStudy.title}</CaseStudyTitle>
						<CaseStudySubtitle>{modalContent.caseStudy.subtitle}</CaseStudySubtitle>

						<RowTextWrap>
							<TextBlockWrap>
								<TextBlockTitle>{modalContent.caseStudy.textBlock1.title}</TextBlockTitle>
								<TextBlock>{modalContent.caseStudy.textBlock1.text}</TextBlock>
							</TextBlockWrap>
							<TextBlockWrap>
								<TextBlockTitle>{modalContent.caseStudy.textBlock2.title}</TextBlockTitle>
								<TextBlock>{modalContent.caseStudy.textBlock2.text}</TextBlock>
							</TextBlockWrap>
						</RowTextWrap>

						<CaseStudyImageWrap right>
							<CaseStudyImage
								src={modalContent.caseStudy.heroImage1}
								alt={modalContent.caseStudy.title}
							/>
						</CaseStudyImageWrap>

						<RowTextWrap>
							<TextBlockWrap>
								<TextBlockTitle>{modalContent.caseStudy.textBlock3.title}</TextBlockTitle>
								<TextBlock>{modalContent.caseStudy.textBlock3.text}</TextBlock>
							</TextBlockWrap>
							<TextBlockWrap>
								<TextBlockTitle>{modalContent.caseStudy.textBlock4.title}</TextBlockTitle>
								<TextBlock>{modalContent.caseStudy.textBlock4.text}</TextBlock>
							</TextBlockWrap>
						</RowTextWrap>

						<CaseStudyImageWrap left>
							<CaseStudyImage
								src={modalContent.caseStudy.heroImage2}
								alt={modalContent.caseStudy.title}
							/>
						</CaseStudyImageWrap>

						<RowTextWrap>
							<TextBlockWrap>
								<TextBlockTitle>{modalContent.caseStudy.textBlock5.title}</TextBlockTitle>
								<TextBlock>{modalContent.caseStudy.textBlock5.text}</TextBlock>
							</TextBlockWrap>
							{/* <TextBlockWrap>
								<TextBlockTitle>{modalContent.caseStudy.textBlock6.title}</TextBlockTitle>
								<TextBlock>{modalContent.caseStudy.textBlock6.text}</TextBlock>
							</TextBlockWrap> */}
						</RowTextWrap>

						<CaseStudyImageWrap>
							<CaseStudyImage
								src={modalContent.caseStudy.heroImage3}
								alt={modalContent.caseStudy.title}
							/>
						</CaseStudyImageWrap>
					</ModalContent>
				)}
			</Modal>
		</>
	);
}
