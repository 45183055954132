import React, { useEffect } from "react";
import styled from "styled-components";
import BasicPage from "../components/fragments/BasicPage";

import Nav from "../components/fragments/Nav";
import PageBanner from "../components/fragments/PageBanner";

const Title = styled.p`
	font-size: 16px;
	color: ${(props) => props.theme.colours.black};
	text-transform: uppercase;
	font-weight: 600;
	padding: 3rem 0 1rem 0;
`;

const Text = styled.p`
	font-size: 16px;
	color: ${(props) => props.theme.colours.black};
	font-weight: 300;
	padding-top: 1rem;
`;

export default function PrivacyPolicy() {
	useEffect(() => {
		window.scrollTo(0, 0);
	});

	return (
		<>
			<Nav type="dark" />
			<PageBanner
				title="New Window Development Privacy Policy"
				subtitle="This Privacy Policy describes how your personal information is collected, used, and shared when you visit or leave your contact details with nwdevelopment.co.uk (the “Site”)"
			/>
			<BasicPage>
				<Title>PERSONAL INFORMATION WE COLLECT</Title>
				<Text>
					When you visit the Site, we automatically collect certain information about your device,
					including information about your web browser, IP address, time zone, and some of the
					cookies that are installed on your device. Additionally, as you browse the Site, we
					collect information about the individual web pages or services that you view, what
					websites or search terms referred you to the Site, and information about how you interact
					with the Site. We refer to this automatically-collected information as “Device
					Information.”
				</Text>

				<Text>We collect Device Information using the following technologies:</Text>

				<Text>
					– “Cookies” are data files that are placed on your device or computer and often include an
					anonymous unique identifier. For more information about cookies, and how to disable
					cookies, visit http://www.allaboutcookies.org.
				</Text>
				<Text>
					– “Log files” track actions occurring on the Site, and collect data including your IP
					address, browser type, Internet service provider, referring/exit pages, and date/time
					stamps.
				</Text>
				<Text>
					– “Web beacons,” “tags,” and “pixels” are electronic files used to record information
					about how you browse the Site.
				</Text>

				<Text>
					When we talk about “Personal Information” in this Privacy Policy, we are talking both
					about Device Information and Order Information.
				</Text>

				<Title>HOW DO WE USE YOUR PERSONAL INFORMATION?</Title>
				<Text>
					We use the Device Information that we collect to help us screen for potential risk and
					fraud (in particular, your IP address), and more generally to improve and optimise our
					Site (for example, by generating analytics about how our customers browse and interact
					with the Site, and to assess the success of our marketing and advertising campaigns).
				</Text>

				<Title>SHARING YOUR PERSONAL INFORMATION</Title>
				<Text>
					We share your Personal Information with third parties to help us use your Personal
					Information, as described above. For example, we use WordPress to power our website. We
					also use Google Analytics to help us understand how our customers use the Site–you can
					read more about how Google uses your Personal Information here:
					https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics
					here: https://tools.google.com/dlpage/gaoptout.
				</Text>

				<Text>
					Finally, we may also share your Personal Information to comply with applicable laws and
					regulations, to respond to a subpoena, search warrant or other lawful request for
					information we receive, or to otherwise protect our rights.
				</Text>

				<Title>DO NOT TRACK</Title>
				<Text>
					Please note that we do not alter our Site’s data collection and use practices when we see
					a Do Not Track signal from your browser.
				</Text>

				<Title>YOUR RIGHTS</Title>
				<Text>
					If you are a European resident, you have the right to access personal information we hold
					about you and to ask that your personal information be corrected, updated, or deleted. If
					you would like to exercise this right, please contact us through the contact information
					below.
				</Text>

				<Text>
					Additionally, if you are a European resident we note that we are processing your
					information in order to fulfill contracts we might have with you (for example if you make
					an order through the Site), or otherwise to pursue our legitimate business interests
					listed above. Additionally, please note that your information will be transferred outside
					of Europe, including to Canada and the United States.
				</Text>

				<Title>DATA RETENTION</Title>
				<Text>
					When you send us a contact form through our site, we will maintain your contact
					information for our records unless and until you ask us to delete this information.
				</Text>

				<Title>CHANGES</Title>
				<Text>
					We may update this privacy policy from time to time in order to reflect, for example,
					changes to our practices or for other operational, legal or regulatory reasons.
				</Text>

				<Title>CONTACT US</Title>
				<Text>
					For more information about our privacy practices, if you have questions, or if you would
					like to make a complaint, please contact us by e-mail at privacy@nwdevelopment.co.uk or by
					mail: 20-22 Wenlock Road, N1 7GU, London, United Kingdom.
				</Text>
			</BasicPage>
		</>
	);
}
