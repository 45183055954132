import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as AdobeIllustrator } from "../../assets/svg/adobe-ai.svg";
import { ReactComponent as AdobePhotoshop } from "../../assets/svg/adobe-ps.svg";
import { ReactComponent as AdobeXD } from "../../assets/svg/adobe-xd.svg";
import { ReactComponent as AdobeAfterEffects } from "../../assets/svg/adobe-ae.svg";
import { ReactComponent as Blender } from "../../assets/svg/blender.svg";

import { ReactComponent as ReactJs } from "../../assets/svg/react.svg";
import { ReactComponent as NodeJs } from "../../assets/svg/nodejs.svg";
import { ReactComponent as Serverless } from "../../assets/svg/serverless.svg";
import { ReactComponent as MongoDb } from "../../assets/svg/mongodb.svg";
import { ReactComponent as Wordpress } from "../../assets/svg/wordpress.svg";
import { ReactComponent as Shopify } from "../../assets/svg/shopify.svg";

import { ReactComponent as GoogleAnalytics } from "../../assets/svg/google-analytics.svg";
import { ReactComponent as GoogleAds } from "../../assets/svg/google-ads.svg";
import { ReactComponent as GoogleOptimize } from "../../assets/svg/google-optimize.svg";

const Wrapper = styled.div``;

const Title = styled.h1`
	display: flex;
	width: 50vw;
	color: ${(props) => (props.color ? props.color : props.theme.colours.black)};
	& div {
		flex: 2;
	}
`;

const Subtitle = styled.p`
	color: ${(props) => (props.color ? props.color : props.theme.colours.black)};
`;

const SubcatWrap = styled.div`
	display: grid;
	grid-template-columns: 2fr 3fr;
	grid-column-gap: 2rem;
	width: 50vw;
	color: ${(props) => (props.color ? props.color : props.theme.colours.black)};
	margin-top: 2rem;
	position: relative;
	& .right > .active {
		opacity: 1;
		transform: translateY(0);
		transition: 0.5s;
		transition-delay: 0.25s;
	}
	& .left > .active {
		background: ${(props) => (props.activeBtn ? props.activeBtn : props.theme.colours.primary)};
		color: white;
		transform: translateX(0);
	}
`;

const SubcatLeft = styled.div`
	flex: 2;
	filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2));
	min-width: 16.8rem;
`;

const SubcatRight = styled.div`
	flex: 3;
`;

const SubcatBtn = styled.div`
	padding: 0.5rem 1rem;
	background: transparent;
	color: ${(props) => (props.color ? props.color : props.theme.colours.black)};
	margin-bottom: 1rem;
	clip-path: polygon(0% 0%, 244px 0%, 270px 50%, 244px 100%, 0% 100%);
	transform: translateX(-1rem);
	transition: 0.5s;
	cursor: pointer;
`;
const SubcatTxt = styled.p`
	position: absolute;
	color: ${(props) => (props.color ? props.color : props.theme.colours.black)};
	transition: 0.5s;
	width: 27rem;
	opacity: 0;
	transform: translateY(-30px);
`;

const IconWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	height: 2rem;
	opacity: 0.5;
	margin-top: 0.75rem;
	filter: ${(props) => props.iconStyle === "light" && "brightness(10)"};
	filter: ${(props) => props.iconStyle === "grey" && "brightness(0) opacity(0.5)"};
	& svg {
		width: 2rem;
		margin-right: 0.5rem;
		align-self: center;
		justify-self: center;
	}
`;

const BlenderIcon = styled(Blender)`
	width: 1.25rem !important;
	margin-top: -0.1rem;
`;

const ServerlessIcon = styled(Serverless)`
	width: 1.1rem !important;
`;
const MongoDbIcon = styled(MongoDb)`
	width: 0.9rem !important;
`;
const ReactIcon = styled(ReactJs)`
	margin-left: 0.5rem !important;
	width: 1.75rem !important;
`;
const NodeIcon = styled(NodeJs)`
	width: 2.5rem !important;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
`;
const WordpressIcon = styled(Wordpress)`
	width: 1.75rem !important;
`;
const ShopifyIcon = styled(Shopify)`
	width: 5rem !important;
`;
const GoogleAnalyticsIcon = styled(GoogleAnalytics)`
	width: 4.5rem !important;
	margin-right: 1.5rem !important;
`;
const GoogleAdsIcon = styled(GoogleAds)`
	width: 4rem !important;
	margin-right: 1.5rem !important;
`;
const GoogleOptimizeIcon = styled(GoogleOptimize)`
	width: 5rem !important;
	margin-right: 1.5rem !important;
`;

const IconsA = () => {
	return (
		<>
			<AdobeXD />
			<AdobePhotoshop />
			<AdobeIllustrator />
			<AdobeAfterEffects />
			{/* <FigmaIcon /> */}
			<BlenderIcon />
		</>
	);
};

const IconsB = () => {
	return (
		<>
			<ShopifyIcon />
			<ReactIcon />
			<NodeIcon />
			<ServerlessIcon />
			<MongoDbIcon />
			<WordpressIcon />
		</>
	);
};

const IconsC = () => {
	return (
		<>
			<GoogleAnalyticsIcon />
			<GoogleAdsIcon />
			<GoogleOptimizeIcon />
		</>
	);
};

function regEx(str) {
	return str.replace(/[^A-Z0-9]/gi, "-");
}

export default function ServiceContainer(props) {
	const refSubcatRight = useRef();
	const refSubcatLeft = useRef();

	const [subCat1, setSubCat1] = useState("");
	const [subCat2, setSubCat2] = useState("");
	const [subCat3, setSubCat3] = useState("");
	const [subCat4, setSubCat4] = useState("");

	useEffect(() => {
		const { subcategory1, subcategory2, subcategory3, subcategory4 } = props;
		setSubCat1(regEx(subcategory1));
		setSubCat2(regEx(subcategory2));
		setSubCat3(regEx(subcategory3));
		setSubCat4(regEx(subcategory4));
	}, [subCat1, subCat2, subCat3, subCat4]);

	const handleShow = (e) => {
		e.preventDefault();
		// console.log(document.querySelector(`.${e.target.id}`));

		// Toggle active class on buttons
		refSubcatRight.current.querySelectorAll(`.active`).forEach(function (el) {
			el.classList.remove("active");
		});

		refSubcatRight.current.querySelector(`.${e.target.id}`).classList.toggle("active");

		refSubcatLeft.current.querySelectorAll(`.active`).forEach(function (el) {
			el.classList.remove("active");
		});

		refSubcatLeft.current.querySelector(`#${e.target.id}`).classList.toggle("active");

		// Send the response of the clicked button to the parent component
		props.setServiceHighlight({ id: e.target.id, service: props.title });
	};

	return (
		<Wrapper>
			<Title color={props.textCol}>
				<div>{props.title}</div>
			</Title>
			<Subtitle color={props.textCol}>{props.subtitle}</Subtitle>
			<SubcatWrap activeBtn={props.btnCol}>
				<SubcatLeft ref={refSubcatLeft} className="left">
					<SubcatBtn onClick={handleShow} id={subCat1} className="active" color={props.textCol}>
						{props.subcategory1}
					</SubcatBtn>
					<SubcatBtn onClick={handleShow} id={subCat2} color={props.textCol}>
						{props.subcategory2}
					</SubcatBtn>
					<SubcatBtn onClick={handleShow} id={subCat3} color={props.textCol}>
						{props.subcategory3}
					</SubcatBtn>
					<SubcatBtn onClick={handleShow} id={subCat4} color={props.textCol}>
						{props.subcategory4}
					</SubcatBtn>
				</SubcatLeft>
				<SubcatRight ref={refSubcatRight} className="right" color={props.textCol}>
					<SubcatTxt color={props.textCol} className={`${subCat1} active`}>
						{props.subdescription1}
					</SubcatTxt>
					<SubcatTxt color={props.textCol} className={`${subCat2}`}>
						{props.subdescription2}
					</SubcatTxt>
					<SubcatTxt color={props.textCol} className={`${subCat3}`}>
						{props.subdescription3}
					</SubcatTxt>
					<SubcatTxt color={props.textCol} className={`${subCat4}`}>
						{props.subdescription4}
					</SubcatTxt>
				</SubcatRight>
				<div></div>
				<IconWrapper iconStyle={props.iconStyle}>
					{props.container === "A" && <IconsA />}
					{props.container === "B" && <IconsB />}
					{props.container === "C" && <IconsC />}
				</IconWrapper>
			</SubcatWrap>
		</Wrapper>
	);
}
