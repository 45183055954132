// Dependencies
import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";

// Styles
import { Container, device } from "../styles/Layout";

// Media
import LaptopFrame from "../../assets/img/laptop-frame-v2.png";
import Video from "../../assets/video/web-design-v3.mp4";

// Text content
import { why_us_en } from "../../data/page_content/en/pages/why_us_en";

const Wrapper = styled.section`
	padding: 9rem 0 0 0;
	/* background-image: ${(props) => props.theme.colours.gradientB}; */
	background: ${(props) => props.theme.colours.gradientC_reverse};
	@media ${device.xl} {
		margin-bottom: -10rem;
	}
`;

const WhyUsContainer = styled(Container)`
	display: flex;
	@media ${device.xl} {
		display: block;
	}
`;

const SectionWrap = styled.div`
	flex: 1;
	margin-top: 20vh;
	@media ${device.xl} {
		margin-top: 0;
	}
`;

const TextWrap = styled.div`
	display: block;
	height: 80vh;
	max-height: 40rem;
	@media ${device.xl} {
		height: auto;
		max-height: fit-content;
		padding-bottom: 6rem;
	}
`;

const Title = styled.h1`
	color: ${(props) => props.theme.colours.offwhite};
	margin-bottom: 2rem;
`;
const Text = styled.p`
	color: ${(props) => props.theme.colours.offwhite};
`;

const LaptopWrap = styled.div`
	position: absolute;

	margin-top: 0;

	// To ensure the intitial laptop position is correctly set against the first section, the center points need to be slightly adjusted depending on the height of the sceen.

	// wrapper height 2900px (screen height 1100px)
	margin-top: ${(props) => props.wrapperHeight > 2900 && "75px"};
`;

const EmptyFlex = styled.div`
	flex: 1;
`;

const Laptop = styled.img`
	width: 600px;
`;

const LaptopContentWrap = styled.div`
	perspective: 1520px;
	perspective-origin: 43% 50%;
`;

const LaptopContent = styled.div`
	position: absolute;
	width: 595px;
	height: 246px;
	background: blue;
	z-index: 10;
	margin-top: 78px;
	margin-left: -65px;
	overflow: hidden;

	transform: scaleX(1) scaleY(1) scaleZ(1.25) rotateX(8deg) rotateY(62deg) rotateZ(1.3deg)
		translateX(0px) translateY(0px) translateZ(0) skewX(4deg) skewY(1deg);

	& video {
		position: absolute;
		width: 100%;
		margin-top: -35px;
	}
`;

const VideoContainer = styled.div``;

export default function WhyUsExplained() {
	// Detect the wrapper's height to pass it on to scrollmagic so it knows the total hight of the wrapper component depending on the screen size
	const refWrapper = useRef();
	const [wrapperHeight, setWrapperHeight] = useState(0);

	// Set coefficient for wrapper height adjustment
	const [coefficient, setCoefficient] = useState(1);

	useLayoutEffect(() => {}, []);

	useEffect(() => {
		// To ensure the iMac stops at the right position at the end of the wrapper, modify the wrapper height value with a coefficent.
		if (window.innerHeight <= 700) setCoefficient(0.93);
		if (window.innerHeight >= 700 && window.innerHeight < 800) setCoefficient(0.91);
		if (window.innerHeight >= 800 && window.innerHeight < 900) setCoefficient(0.89);
		if (window.innerHeight >= 900 && window.innerHeight < 1000) setCoefficient(0.87);
		if (window.innerHeight >= 1000 && window.innerHeight < 1100) setCoefficient(0.86);
		if (window.innerHeight >= 1100 && window.innerHeight < 1350) setCoefficient(0.84);
		if (window.innerHeight >= 1350) setCoefficient(0.83);

		// Set the wrapper height so it can be passed on to scrollmagic
		setWrapperHeight(refWrapper.current.clientHeight * coefficient);

		console.log(wrapperHeight);
	}, [wrapperHeight, coefficient]);

	return (
		<Wrapper ref={refWrapper}>
			<WhyUsContainer>
				{/* Don't render the pinned laptop on smaller devices */}
				{window.innerWidth > device.xlarge && (
					<Controller>
						<Scene duration={wrapperHeight - 600} pin offset={250}>
							<LaptopWrap wrapperHeight={wrapperHeight}>
								<LaptopContentWrap>
									<LaptopContent>
										<VideoContainer>
											<video loop={true} autoPlay="autoplay" muted>
												<source src={Video} type="video/mp4" />
											</video>
										</VideoContainer>
									</LaptopContent>
								</LaptopContentWrap>
								<Laptop src={LaptopFrame} />
							</LaptopWrap>
						</Scene>
					</Controller>
				)}
				<EmptyFlex />
				<SectionWrap>
					{why_us_en.why_us_explained.map((item, index) => (
						<TextWrap key={index}>
							<Title>{item.title}</Title>
							<Text>{item.text}</Text>
						</TextWrap>
					))}
				</SectionWrap>
			</WhyUsContainer>
		</Wrapper>
	);
}
