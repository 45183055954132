// Contact Form A / Calendly Form
export const contact_form_a_en = {
	title: "Let’s begin",
	subtitle1: "1. Introduction & discovery",
	subpara1:
		"We discuss your business objectives and define your project requirements, features and goals before starting to work on your project.",
	subtitle2: "2. Project details",
	subpara2:
		"A more thorough discussion about the project, your exact requirements and the scope to ensure we find the absolute best solution for you.",
	subtitle3: "3. Proposal and quote",
	subpara3:
		"Our experts will go through all your requirements and send you a detailed proposal with a quote.",
	footnoteText: "Looking for a quick estimate?",
	footnoteLink: "Use our Get Price Estimate calculator",
	form: {
		name: "Name",
		email: "Email",
		phone: "Phone",
		website: "Website",
		message: "Tell us more",
		button: "Schedule a call with us",
		privacyText: "By submitting this form you agree to our ",
		privacyLink: "Privacy Policy",
	},

	// Confirmation text after successful form submission.
	confirmationTitle: "Thank you for scheduling a meeting with us", // ", {name}!" is inserted after the comma.
	confirmationText:
		"One of our experts is now getting ready to discuss your project. Please check your email to confirm the scheduled date and time and speak soon!",
};
