// Header image
import ContactImg from "../../../../assets/img/banner-img-contact-v2.png";

export const contact_en = {
	seo: {
		title: "Contact Us | New Window Development",
		description:
			"We are always happy to hear from you. Fill out the form or schedule a video call with us at a time convinient for you and let's start your web development project today.",
	},
	header_img: ContactImg,
	title: "Contact us",
	subtitle_start:
		"We are always happy to hear from you. Use the details below, fill out the form or ",
	subtitle_link_text: "schedule a video call",
	subtitle_end: "with us at a time convinient for you and let's begin.",
};
