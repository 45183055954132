// Price estimate formula
export const Calculate = (pages, design, copywriting, seo, backend, ecommerce) => {
	// Set the base prices for pages
	let basePrice0 = 1500;
	let basePrice1 = 2500;
	let basePrice2 = 4500;
	let basePrice3 = 6000;

	// Calculate the pages price
	if (pages === 0) {
		pages = basePrice0;
	} else if (pages === 1) {
		pages = basePrice1;
	} else if (pages === 2) {
		pages = basePrice2;
	} else if (pages === 3) {
		pages = basePrice3;
	}

	// Calculate the price of design depending on the amount of pages
	if (design === 0) {
		design = 0;
	} else if (design === 1 && pages === basePrice0) {
		design = 1000;
	} else if (design === 1 && pages === basePrice1) {
		design = 2000;
	} else if (design === 1 && pages === basePrice2) {
		design = 4000;
	} else if (design === 1 && pages === basePrice3) {
		design = 6000;
	} else if (design === 2 && pages === basePrice0) {
		design = 2000;
	} else if (design === 2 && pages === basePrice1) {
		design = 4000;
	} else if (design === 2 && pages === basePrice2) {
		design = 6000;
	} else if (design === 2 && pages === basePrice3) {
		design = 9000;
	}

	// Calculate the price of copywriting, regardless of the pages to be designed
	if (copywriting === 0) {
		copywriting = 0;
	} else if (copywriting === 1) {
		copywriting = 450;
	} else if (copywriting === 2) {
		copywriting = 700;
	} else if (copywriting === 3) {
		copywriting = 1450;
	} else if (copywriting === 4) {
		copywriting = 2000;
	}

	// Calculate the price of SEO, regardless of the pages to be designed
	if (seo === 0) {
		seo = 0;
	} else if (seo === 1) {
		seo = 500;
	} else if (seo === 2) {
		seo = 900;
	} else if (seo === 3) {
		seo = 1300;
	} else if (seo === 4) {
		seo = 1500;
	}

	// Calculate the price of backend depending on the amount of pages
	if (backend === 0) {
		backend = 0;
	} else if (backend === 1 && pages === basePrice0) {
		backend = 700;
	} else if (backend === 1 && pages === basePrice1) {
		backend = 1200;
	} else if (backend === 1 && pages === basePrice2) {
		backend = 1800;
	} else if (backend === 1 && pages === basePrice3) {
		backend = 2500;
	} else if (backend === 2 && pages === basePrice0) {
		backend = 1500;
	} else if (backend === 2 && pages === basePrice1) {
		backend = 2500;
	} else if (backend === 2 && pages === basePrice2) {
		backend = 4000;
	} else if (backend === 2 && pages === basePrice3) {
		backend = 6000;
	} else if (backend === 3 && pages === basePrice0) {
		backend = 3000;
	} else if (backend === 3 && pages === basePrice1) {
		backend = 5000;
	} else if (backend === 3 && pages === basePrice2) {
		backend = 8000;
	} else if (backend === 3 && pages === basePrice3) {
		backend = 12000;
	}

	// Calculate the price of ecommerce, regardless of the pages to be designed
	if (ecommerce === 0) {
		ecommerce = 0;
	} else if (ecommerce === 1) {
		ecommerce = 3000;
	} else if (ecommerce === 2) {
		ecommerce = 7000;
	}

	// Calculate base estimate / total
	let baseEstimate = pages + design + copywriting + seo + backend + ecommerce;

	// Calculate the low estimate
	let lowEstimate;
	let highEstimate;

	if (baseEstimate <= 1500) {
		lowEstimate = baseEstimate - 250;
		highEstimate = baseEstimate + 500;
	}

	if (baseEstimate > 1500 && baseEstimate <= 2500) {
		lowEstimate = baseEstimate - 500;
		highEstimate = baseEstimate + 1000;
	}

	if (baseEstimate > 2500 && baseEstimate <= 4500) {
		lowEstimate = baseEstimate - 750;
		highEstimate = baseEstimate + 1500;
	}

	if (baseEstimate > 4500 && baseEstimate <= 6000) {
		lowEstimate = baseEstimate - 1000;
		highEstimate = baseEstimate + 2000;
	}

	if (baseEstimate > 6000 && baseEstimate <= 12000) {
		lowEstimate = baseEstimate - 2000;
		highEstimate = baseEstimate + 3000;
	}

	if (baseEstimate > 12000) {
		lowEstimate = baseEstimate - 4000;
		highEstimate = baseEstimate + 4000;
	}

	// Adjust the quote a little before getting the first client

	return [lowEstimate - 500, baseEstimate - 250, highEstimate];
};
