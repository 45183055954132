import styled from "styled-components";

// Device sizes

const size = {
	xxs: 450,
	xs: 500,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1345,
	xxxl: 1400,
};

export const device = {
	xxs: `(max-width: ${size.xxs}px)`,
	xs: `(max-width: ${size.xs}px)`,
	sm: `(max-width: ${size.sm}px)`,
	md: `(max-width: ${size.md}px)`,
	lg: `(max-width: ${size.lg}px)`,
	xl: `(max-width: ${size.xl}px)`,
	xxl: `(max-width: ${size.xxl}px)`,
	xxxl: `(max-width: ${size.xxxl}px)`,
	smallest: size.xs,
	small: size.sm,
	mid: size.md,
	large: size.lg,
	xlarge: size.xl,
	xxlarge: size.xxl,
};

export const Container = styled.div`
	width: 1366px;
	padding: 0 2rem;
	margin: 0 auto;

	@media ${device.xxxl} {
		width: calc(100% - 4rem);
	}
`;

export const Grid = styled.div`
	display: grid;
	${(props) =>
		props.lg ? `grid-template-columns: repeat(${props.lg}, ${props.auto ? "auto" : "1fr"})` : null};
	${(props) => (props.custom ? `grid-template-columns:${props.custom}` : null)};
	grid-column-gap: ${(props) => (props.gap ? props.gap : null)};
	grid-row-gap: ${(props) => (props.gap ? props.gap : null)};
	align-content: ${(props) => (props.center ? "center" : null)};
`;
