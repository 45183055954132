// Header image
import WhyUsImg from "../../../../assets/img/banner-img-why-us.png";

export const why_us_en = {
	seo: {
		title: "Why us? | New Window Development",
		description:
			"We are a team of experienced developers, designers and digital marketers who are passionate about creating digital solutions for our clients.",
	},
	header_img: WhyUsImg,
	title: "Why us?",
	subtitle:
		"We are a team of experienced developers, designers and digital marketers who are passionate about creating digital solutions for our clients.",
	highlight_box: {
		title: "Forward looking approach to web development",
		// left_col:
		// 	"Instead of using WordPress by default like many other web development agencies, we use ReactJS framework as our main frontend solution. The result? Much faster website, more flexibility and virtually no limitations in design and function.",
		left_col:
			"Instead of using WordPress by default like many other web development agencies, we use ReactJS framework as our primary frontend solution. The result? Much faster website, more flexibility and virtually no limitations in design and function.",
		right_col:
			"For ecommerce we use Shopify as the main store management tool of choice. It comes with great features out of the box already, and if needed, a completely unique store can be built from the ground up too with virtually no limitation to features available.",
	},
	grid_icons: {
		title: "Everything covered",
		subtitle:
			"As part of our services, we ensure that your website follows all the best practices, so you can rest assured your site will be built to the highest web standards.",
		icon_names: [
			"Responsive Design",
			"Best Layout Practices",
			"Ease of Navigation",
			"On-Site SEO",
			"Browser Friendly",
			"Performance Optimisation",
			"Clean Code",
			"Hosting, Domain, Email Setup",
		],
	},
	why_us_explained: [
		{
			title: "We are performance focused",
			text: "Our graphic designers work hand in hand with web developers to ensure your site not only looking pretty but functioning to the highest performance standards.",
		},
		{
			title: "We are experts in our field",
			text: "Our team consists of experienced developers, designers and digital marketers with many years of experience in the industry, which ensures you will always receive the best proffessional service.",
		},
		{
			title: "We make your site convert",
			text: "Beautiful design and superior functionality is only part of what makes a website successful. Our experts will ensure that your site will not only look pretty but convert visitors to leads and sales.",
		},
		{
			title: "Partnering with other agencies",
			text: "You can put a greater focus on the things your agency excels at and leave the web development side of things to us. We're happy to collaborate with other agencies as a strategic partner or offer a white label solution to suit your project.",
		},
	],
};
