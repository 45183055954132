import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "styled-components";

import Home from "./pages/Home";
import WhyUs from "./pages/WhyUs";
import GetEstimate from "./pages/GetEstimate";
import OurWork from "./pages/OurWork";
import Contact from "./pages/Contact";
import Team from "./pages/Team";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Footer from "./components/fragments/Footer";
import BurgerMenu from "./components/fragments/BurgerMenu";

// Page header images to preload on button hover
import { our_work_en } from "./data/page_content/en/pages/our_work_en";
import { why_us_en } from "./data/page_content/en/pages/why_us_en";
import { get_estimate_en } from "./data/page_content/en/pages/get_estimate_en";
import { team_en } from "./data/page_content/en/pages/team_en";
import { contact_en } from "./data/page_content/en/pages/contact_en";
import Gdpr from "./components/fragments/Gdpr";
import { Helmet } from "react-helmet";

const theme = {
	colours: {
		primary: "#5680E9",
		secondaryDkA: "#8860D0",
		secondaryDkB: "#5AB9EA",
		black: "#183149",
		veryblack: "#0E1C2B",
		offwhite: "#F8F8F8",
		grey: "#D3D3D3",
		darkgrey: "#7a86a3",
		warning: "#e91e63",
		gradientA: "linear-gradient(to right bottom, #5680e9, #6479e4, #7171de, #7d69d8, #8860d0)",
		gradientB:
			"linear-gradient(to right bottom, #5ab9ea1a, #4bb0ec1a, #40a6ee1a, #3e9bee1a, #4490ed1a, #4e89eb1a, #5981e81a, #6479e41a, #6e73e01a, #776ddb1a, #8067d61a, #8860d01a)",
		gradientB_reverse:
			"linear-gradient(to top right,#5ab9ea1a,#4bb0ec1a,#40a6ee1a,#3e9bee1a,#4490ed1a,#4e89eb1a,#5981e81a,#6479e41a,#6e73e01a,#776ddb1a,#8067d61a)",
		gradientC: "linear-gradient(180deg, rgba(86, 128, 233, 1) 0%, rgba(136, 96, 208, 1) 100%)",
		gradientC_reverse:
			"linear-gradient(180deg, rgba(136, 96, 208, 1) 0%, rgba(86, 128, 233, 1) 100%)",
	},
	fonts: {
		title: '"Bai Jamjuree", sans-serif',
		body: '"Open Sans", sans-serif',
	},
	effects: {
		transition: "all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)", // cubic-bezier for a bouncy effect
		boxShadow: "0 3px 8px 0px #00000047",
	},
};

// Preload imposed images for smooth transitions
const preloadImages = (images) => {
	images.forEach((img) => {
		let preloadLink = document.createElement("link");
		preloadLink.href = img;
		preloadLink.rel = "preload";
		preloadLink.as = "image";
		document.head.appendChild(preloadLink);
	});
};

export default function App() {
	useEffect(() => {
		// Preload page banner images, but set a timeout to ensure the process doesn't slow down other essential page elements loading at the beginning
		setTimeout(() => {
			preloadImages([
				our_work_en.header_img,
				why_us_en.header_img,
				get_estimate_en.header_img,
				team_en.header_img,
				contact_en.header_img,
			]);
		}, 4000);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Your Future Web Development Agency | New Window Development</title>
				<meta
					name="description"
					content="New Window Development is a web development agency based in London. We offer a wide range of web development services including custom web development, e-commerce development, and web design."
				/>
			</Helmet>
			<BrowserRouter>
				<Router>
					{/* In order to keep the mobile menu's transition going while switching pages, the mobile menu is placed here at the top level  */}
					<BurgerMenu />
					{/* <Gdpr /> */}

					<Switch>
						<Route exact path="/">
							<Home />
						</Route>
						<Route exact path="/get-estimate">
							<GetEstimate />
						</Route>
						<Route exact path="/why-us">
							<WhyUs />
						</Route>
						<Route exact path="/our-work">
							<OurWork />
						</Route>
						<Route exact path="/contact">
							<Contact />
						</Route>
						<Route exact path="/team">
							<Team />
						</Route>
						<Route exact path="/privacy-policy">
							<PrivacyPolicy />
						</Route>
						<Route exact path="/terms-and-conditions">
							<TermsConditions />
						</Route>
					</Switch>

					{/* In order to avoid rerendering the footer which is quite heavy affecting the app's performance, the Footer compnent is rendered at the App level and stays in the DOM without rerendering on every page. */}
					<Footer />
				</Router>
			</BrowserRouter>
		</ThemeProvider>
	);
}
